<template>
  <div :class="{ 'hero--building2': !isBuildingOne }" class="hero w-full bg-white relative">
    <div class="hero_bg"></div>
    <div class="hero_logo absolute z-10">
      <img src="./../assets/images/logo-v.svg" alt="Viktor logo" />
    </div>
    <div class="hero_title absolute z-10">
      <h1 v-if="isBuildingOne">
        Building 1
      </h1>
      <h1 v-else>Building 2</h1>
      <h2 v-if="isLevelSelected">Floor {{ selectedLevel }}</h2>
      <h2 v-if="isBuildingOne && isBuildingSelected" class="hero_logo_building">
        Building 2
      </h2>
      <h2 v-if="!isBuildingOne && isBuildingSelected" class="hero_logo_building">Building 1</h2>
    </div>
    <div class="hero_el_wrapper">
      <!-- 1697 * 1080 -->
      <svg
        id="hero_building"
        xmlns="http://www.w3.org/2000/svg"
        width="88.38vw"
        height="100%"
        viewBox="0 0 1697 1080"
      >
        <rect id="container" width="1697" height="1080" fill="none" />
        <g id="building" transform="translate(1388 583.826)">
          <path
            @click="selectBuilding()"
            d="M4268,2097.259l153.79-25.432,88.573,60.987v4.257l-48.018,5.744v8.573l13.932-1.467,14.778,8.633v19.425l-28.71,2.7v15.745l13.932-.87,14.778,6.831V2215.8l4.648,2.226v87.7h-2.532v87.09l-42.45,3.734-75.847-2.246v-4.769h-12.943v-5.675h-25.9V2205.776l-15.5-4.326.867-16.865-16.761-5.477V2154.6l54.367-7.14v-7.307Z"
            transform="translate(-4268 -2071.826)"
            fill="#fae345"
          />
        </g>
        <g id="level-1" ref="level-1" transform="translate(126.316 817.345)">
          <path
            @click="selectLevel('level-1')"
            d="M3581.8,2305.345l714.306,24.874V2407.5l-188.893,28.467L3581.8,2478.078l-269.361-6.514-306.128-35.594V2354.9Z"
            transform="translate(-3006.316 -2305.345)"
            fill="#fae345"
          />
        </g>
        <g id="level-2" ref="level-2" transform="translate(125.633 663.588)">
          <path
            @click="selectLevel('level-2')"
            d="M3582.962,2151.588,4305.14,2269.4v61.01l-722.178-25.46L3005.633,2353.4v-74.276Z"
            transform="translate(-3005.633 -2151.588)"
            fill="#fae345"
          />
        </g>
        <g id="level-3" ref="level-3" transform="translate(125.572 512.804)">
          <path
            @click="selectLevel('level-3')"
            d="M3582.962,2153.8,4305.52,2364.34,4305,2422.3l-722.042-117.341-577.39,126.727v-59.867Z"
            transform="translate(-3005.572 -2153.804)"
            fill="#fae345"
          />
        </g>
        <g id="level-4" ref="level-4" transform="translate(125.757 381.344)">
          <path
            @click="selectLevel('level-4')"
            d="M3538.261,2174.344l115.792,40.379,78.054-36.242,280.7,122.508v20.56l197.792,81.819,41.063-6.672,106.173,48.889v7.383l-52.162,5.853v57.7l-722.707-210.856L3005.757,2524V2472.4Z"
            transform="translate(-3005.757 -2174.344)"
            fill="#fae345"
          />
        </g>
        <g id="level-5" ref="level-5" transform="translate(129.345 322.363)">
          <path
            @click="selectLevel('level-5')"
            d="M3538.843,1817.918l-95.224,60.818-46.64-14.986-.905-13.067-27.99-10.174-178.292,138.509v15.972l-8.09-1.546-28.357,21.368v13.912l-14.02-3.326-24.657,18.581v10.353l-10.521-2.56-20.484,15.7-74.317,42.467v54.941l523.465-292.5,121.3,37.445,77.535-35.98,281.8,122.1v0l-63.544-27.984v-32.5l-3.18-1.309-316.852-123.8-28.646,15.847-20.989-3.489-19.083,4.242Z"
            transform="translate(-3009.345 -1810.363)"
            fill="#fae345"
          />
        </g>
        <g id="level-6" ref="level-6" transform="translate(130.473 60.798)">
          <path
            @click="selectLevel('level-6')"
            d="M3480.719,1548.8l-468.236,482.836-2.01,72.949,73.168-37.167,20.334-15.4,11.4,2.155-.725-10.117,24.746-18.711,14.007,3.295v-13.52l28.293-21.877,8.158,2.2v-17.014l178.669-137.445,27.887,9.914v12.581l47.048,15.341,95.247-61.306,23.167,9.121,18.785-3.927,21.14,3.927,27.34-16,321.092,124.917.358-39.871v-66.06l-24.067-15.257Z"
            transform="translate(-3010.473 -1548.798)"
            fill="#fae345"
          />
        </g>
      </svg>
    </div>
    <!-- <router-link to="/"
      ><div class="btn bg-primary absolute z-10 left-10 bottom-10">
        TEST Animation! Back to home
      </div></router-link
    > -->
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'Features',
  data() {
    return {
      isBuildingOne: true,
      isBuildingSelected: false,
      isLevelSelected: false,
      selectedLevel: ''
    };
  },
  methods: {
    selectBuilding: function() {
      document.querySelector('#building').classList.add('active');
      this.hideElements();
      this.isBuildingSelected = true;
      setTimeout(() => {
        this.hideElements();
        this.isBuildingOne = !this.isBuildingOne;
        if (document.querySelector('#building').classList.contains('active')) {
          document.querySelector('#building').classList.remove('active');
        }
      }, 700);
    },
    selectLevel: function(selectedId) {
      if (document.querySelector('#building').classList.contains('active')) {
        document.querySelector('#building').classList.remove('active');
      }
      this.hideElements();
      this.isLevelSelected = true;
      this.selectedLevel = selectedId.replace('level-', '');
      // hide everything
      document.querySelectorAll('#hero_building > g:not(#building) > path').forEach(element => {
        gsap.to(element, { opacity: 0, duration: 1, pointerEvents: 'none' });
      });
      // show and init the selected one only
      gsap.to(this.$refs[selectedId].querySelector('path'), {
        opacity: 1,
        duration: 1,
        pointerEvents: 'all',
        onComplete: () => {
          let route = '/floor-plans';
          route += this.isBuildingOne ? '/building-1/' : '/building-2/';
          route += selectedId;
          this.$router.push(route);
        }
      });
    },
    hideElements: function() {
      this.isLevelSelected = false;
      this.isBuildingSelected = false;
    }
  }
};
</script>

<style scoped lang="scss">
.hero {
  transition: all 0.05s ease-out;

  &_bg {
    background-image: url('./../assets/images/landingpage/hero-updated.jpg');
    @apply bg-no-repeat bg-cover w-full h-full;
    -webkit-animation: fadeOutIn 0.7s ease-out reverse;
    -animation: fadeOutIn 0.7s ease-out reverse;
  }

  &_logo {
    @apply text-right;
    top: calc(73/1080) * 100%;
    right: calc(85/1920) * 100%;
    transition: all 0.7s;
    img {
      @apply ml-auto;
      width: calc(74.64/1920) * 100vw;
      margin-bottom: calc(44/1920) * 100vw;
    }
  }
  &_title {
    top: calc(212/1080) * 100%;
    right: calc(85/1920) * 100%;
    @apply text-right;

    h1 {
      @apply leading-snug font-semibold text-primary;
      font-size: calc(30/1920) * 100vw; // text-2xl
    }
    h2 {
      @apply leading-snug font-semibold text-primary;
      font-size: calc(25/1920) * 100vw; // text-lg
      margin-top: calc(15/1920) * 100vw;
      &.hero_logo_building {
        @apply text-neutral text-center text-left;
        margin-top: calc(260/1920) * 100vw;
        width: calc(181/1920) * 100vw;
      }
    }
  }

  &_el_wrapper {
    @apply absolute w-full h-full inset-0;
    svg {
      @apply pointer-events-none mix-blend-color;
      path {
        @apply pointer-events-auto opacity-0 isolate cursor-pointer;
      }
      #building path {
        transition: opacity 1s;
      }
      #building.active > path {
        @apply opacity-100;
      }
    }
  }

  // Building 2 state
  &--building2 {
    .hero_bg {
      -webkit-animation: fadeInOut 0.7s ease-out forwards;
      -webkit-transform: scaleX(-1);
      animation: fadeInOut 0.7s ease-out forwards;
      transform: scaleX(-1);
    }
    .hero_el_wrapper {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      @apply mix-blend-color;
    }
    .hero_logo {
      right: calc(1760.4/1920) * 100%;
      @apply text-left;
      img {
        @apply ml-0;
      }
    }
    .hero_title {
      right: calc(1654/1920) * 100%;
      @apply opacity-100;
      -webkit-animation: fadeInOutOpacZero 0.7s ease-out forwards;
      animation: fadeInOutOpacZero 0.7s ease-out forwards;
      h2 {
        @apply text-left;
        &.hero_logo_building {
          @apply text-right;
        }
      }
    }
  }
}
@-webkit-keyframes fadeInOutOpacZero {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOutOpacZero {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOutIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
</style>
