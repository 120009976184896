import _ from 'lodash';

import Building1Level1 from '@/components/FloorPlanFloorPlates/Building1Level1';
import Building1Level2 from '@/components/FloorPlanFloorPlates/Building1Level2';
import Building1Level3 from '@/components/FloorPlanFloorPlates/Building1Level3';
import Building1Level4 from '@/components/FloorPlanFloorPlates/Building1Level4';
import Building1Level5 from '@/components/FloorPlanFloorPlates/Building1Level5';
import Building1Level6 from '@/components/FloorPlanFloorPlates/Building1Level6';
import Building2Level1 from '@/components/FloorPlanFloorPlates/Building2Level1';
import Building2Level2 from '@/components/FloorPlanFloorPlates/Building2Level2';
import Building2Level3 from '@/components/FloorPlanFloorPlates/Building2Level3';
import Building2Level4 from '@/components/FloorPlanFloorPlates/Building2Level4';
import Building2Level5 from '@/components/FloorPlanFloorPlates/Building2Level5';
import Building2Level6 from '@/components/FloorPlanFloorPlates/Building2Level6';
import FloorPlan from '@/components/FloorPlan';

const FloorPlanData = {
  'building-1': {
    name: 'Building 1',
    levels: {
      'level-1': {
        name: 'Level 1',
        component: Building1Level1,
        plans: {
          B1F1H01: {
            homeText: 'Home 01',
            planText: 'Plan A15',
            planFileName: 'B1_A15'
          },
          B1F1H02: {
            homeText: 'Home 02',
            planText: 'Plan D12',
            planFileName: 'B1_D12'
          },
          B1F1H03: {
            homeText: 'Home 03',
            planText: 'Plan D16',
            planFileName: 'B1_D16'
          },
          B1F1H04: {
            homeText: 'Home 04',
            planText: 'Plan B8',
            planFileName: 'B1_B8'
          },
          B1F1H05: {
            homeText: 'Home 05',
            planText: 'Plan A11',
            planFileName: 'B1_A11'
          },
          B1F1H08: {
            homeText: 'Home 08',
            planText: 'Plan A13',
            planFileName: 'B1_A13'
          },
          B1F1H09: {
            homeText: 'Home 09, 10',
            planText: 'Plan A8',
            planFileName: 'B1_A8'
          },
          B1F1H10: {
            homeText: 'Home 09, 10',
            planText: 'Plan A8',
            planFileName: 'B1_A8'
          },
          B1F1H11: {
            homeText: 'Home 11',
            planText: 'Plan A16',
            planFileName: 'B1_A16'
          },
          B1F1H12: {
            homeText: 'Home 12',
            planText: 'Plan D15',
            planFileName: 'B1_D15'
          },
          B1F1H13: {
            homeText: 'Home 13',
            planText: 'Plan B7',
            planFileName: 'B1_B7'
          },
          B1F1H14: {
            homeText: 'Home 14',
            planText: 'Plan B6',
            planFileName: 'B1_B6'
          },
          B1F1H15: {
            homeText: 'Home 15, 16',
            planText: 'Plan A10',
            planFileName: 'B1_A10'
          },
          B1F1H16: {
            homeText: 'Home 15, 16',
            planText: 'Plan A10',
            planFileName: 'B1_A10'
          },
          B1F1H17: {
            homeText: 'Home 17',
            planText: 'Plan A12',
            planFileName: 'B1_A12'
          },
          B1F1H18: {
            homeText: 'Home 18',
            planText: 'Plan D13',
            planFileName: 'B1_D13'
          },
          B1F1H19: {
            homeText: 'Home 19',
            planText: 'Plan D14',
            planFileName: 'B1_D14'
          },
          B1F1H20: {
            homeText: 'Home 20',
            planText: 'Plan B3',
            planFileName: 'B1_B3'
          }
        }
      },
      'level-2': {
        name: 'Level 2',
        component: Building1Level2,
        plans: {
          B1F2H01: {
            homeText: 'Home 01',
            planText: 'Plan A2',
            planFileName: 'B1_A2'
          },
          B1F2H02: {
            homeText: 'Home 02',
            planText: 'Plan D7',
            planFileName: 'B1_D7'
          },
          B1F2H03: {
            homeText: 'Home 03',
            planText: 'Plan D4',
            planFileName: 'B1_D4'
          },
          B1F2H04: {
            homeText: 'Home 04',
            planText: 'Plan B',
            planFileName: 'B1_B'
          },
          B1F2H05: {
            homeText: 'Home 05',
            planText: 'Plan A14',
            planFileName: 'B1_A14'
          },
          B1F2H07: {
            homeText: 'Home 07',
            planText: 'Plan C',
            planFileName: 'B1_C'
          },
          B1F2H08: {
            homeText: 'Home 08',
            planText: 'Plan A1',
            planFileName: 'B1_A1'
          },
          B1F2H09: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F2H10: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F2H11: {
            homeText: 'Home 11',
            planText: 'Plan A17',
            planFileName: 'B1_A17'
          },
          B1F2H12: {
            homeText: 'Home 12',
            planText: 'Plan D2',
            planFileName: 'B1_D2'
          },
          B1F2H13: {
            homeText: 'Home 13',
            planText: 'Plan B5',
            planFileName: 'B1_B5'
          },
          B1F2H14: {
            homeText: 'Home 14',
            planText: 'Plan B1',
            planFileName: 'B1_B1'
          },
          B1F2H15: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F2H16: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F2H17: {
            homeText: 'Home 17',
            planText: 'Plan A9',
            planFileName: 'B1_A9'
          },
          B1F2H18: {
            homeText: 'Home 18',
            planText: 'Plan D',
            planFileName: 'B1_D'
          },
          B1F2H19: {
            homeText: 'Home 19',
            planText: 'Plan D1',
            planFileName: 'B1_D1'
          },
          B1F2H20: {
            homeText: 'Home 20',
            planText: 'Plan B9',
            planFileName: 'B1_B9'
          },
          B1F2H21: {
            homeText: 'Home 21',
            planText: 'Plan A5',
            planFileName: 'B1_A5'
          },
          B1F2H22: {
            homeText: 'Home 22',
            planText: 'Plan A4',
            planFileName: 'B1_A4'
          },
          B1F2H23: {
            homeText: 'Home 23',
            planText: 'Plan A3',
            planFileName: 'B1_A3'
          },
          B1F2H24: {
            homeText: 'Home 24',
            planText: 'Plan S1',
            planFileName: 'B1_S1'
          },
          B1F2H25: {
            homeText: 'Home 25',
            planText: 'Plan S',
            planFileName: 'B1_S'
          }
        }
      },
      'level-3': {
        name: 'Level 3',
        component: Building1Level3,
        plans: {
          B1F3H01: {
            homeText: 'Home 01',
            planText: 'Plan A2',
            planFileName: 'B1_A2'
          },
          B1F3H02: {
            homeText: 'Home 02',
            planText: 'Plan D18',
            planFileName: 'B1_D18'
          },
          B1F3H03: {
            homeText: 'Home 03',
            planText: 'Plan D17',
            planFileName: 'B1_D17'
          },
          B1F3H04: {
            homeText: 'Home 04',
            planText: 'Plan B',
            planFileName: 'B1_B'
          },
          B1F3H05: {
            homeText: 'Home 05',
            planText: 'Plan A6',
            planFileName: 'B1_A6'
          },
          B1F3H06: {
            homeText: 'Home 06',
            planText: 'Plan A7',
            planFileName: 'B1_A7'
          },
          B1F3H07: {
            homeText: 'Home 07',
            planText: 'Plan C',
            planFileName: 'B1_C'
          },
          B1F3H08: {
            homeText: 'Home 08',
            planText: 'Plan A1',
            planFileName: 'B1_A1'
          },
          B1F3H09: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F3H10: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F3H11: {
            homeText: 'Home 11',
            planText: 'Plan A17',
            planFileName: 'B1_A17'
          },
          B1F3H12: {
            homeText: 'Home 12',
            planText: 'Plan D3',
            planFileName: 'B1_D3'
          },
          B1F3H13: {
            homeText: 'Home 13',
            planText: 'Plan B2',
            planFileName: 'B1_B2'
          },
          B1F3H14: {
            homeText: 'Home 14',
            planText: 'Plan B1',
            planFileName: 'B1_B1'
          },
          B1F3H15: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F3H16: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F3H17: {
            homeText: 'Home 17',
            planText: 'Plan A9',
            planFileName: 'B1_A9'
          },
          B1F3H18: {
            homeText: 'Home 18',
            planText: 'Plan D',
            planFileName: 'B1_D'
          },
          B1F3H19: {
            homeText: 'Home 19',
            planText: 'Plan D1',
            planFileName: 'B1_D1'
          },
          B1F3H20: {
            homeText: 'Home 20',
            planText: 'Plan B4',
            planFileName: 'B1_B4'
          },
          B1F3H21: {
            homeText: 'Home 21',
            planText: 'Plan A5',
            planFileName: 'B1_A5'
          },
          B1F3H22: {
            homeText: 'Home 22',
            planText: 'Plan A4',
            planFileName: 'B1_A4'
          },
          B1F3H23: {
            homeText: 'Home 23',
            planText: 'Plan A3',
            planFileName: 'B1_A3'
          },
          B1F3H24: {
            homeText: 'Home 24',
            planText: 'Plan S1',
            planFileName: 'B1_S1'
          },
          B1F3H25: {
            homeText: 'Home 25',
            planText: 'Plan S',
            planFileName: 'B1_S'
          }
        }
      },
      'level-4': {
        name: 'Level 4',
        component: Building1Level4,
        plans: {
          B1F4H01: {
            homeText: 'Home 01',
            planText: 'Plan A2',
            planFileName: 'B1_A2'
          },
          B1F4H02: {
            homeText: 'Home 02',
            planText: 'Plan D9',
            planFileName: 'B1_D9'
          },
          B1F4H03: {
            homeText: 'Home 03',
            planText: 'Plan D5',
            planFileName: 'B1_D5'
          },
          B1F4H04: {
            homeText: 'Home 04',
            planText: 'Plan B',
            planFileName: 'B1_B'
          },
          B1F4H05: {
            homeText: 'Home 05',
            planText: 'Plan A6',
            planFileName: 'B1_A6'
          },
          B1F4H06: {
            homeText: 'Home 06',
            planText: 'Plan A7',
            planFileName: 'B1_A7'
          },
          B1F4H07: {
            homeText: 'Home 07',
            planText: 'Plan C2',
            planFileName: 'B1_C2'
          },
          B1F4H08: {
            homeText: 'Home 08',
            planText: 'Plan A1',
            planFileName: 'B1_A1'
          },
          B1F4H09: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F4H10: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F4H11: {
            homeText: 'Home 11',
            planText: 'Plan A18',
            planFileName: 'B1_A18'
          },
          B1F4H12: {
            homeText: 'Home 12',
            planText: 'Plan D3',
            planFileName: 'B1_D3'
          },
          B1F4H13: {
            homeText: 'Home 13',
            planText: 'Plan B2',
            planFileName: 'B1_B2'
          },
          B1F4H14: {
            homeText: 'Home 14',
            planText: 'Plan B1',
            planFileName: 'B1_B1'
          },
          B1F4H15: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F4H16: {
            homeText: 'Home 09, 10, 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A'
          },
          B1F4H17: {
            homeText: 'Home 17',
            planText: 'Plan A9',
            planFileName: 'B1_A9'
          },
          B1F4H18: {
            homeText: 'Home 18',
            planText: 'Plan D',
            planFileName: 'B1_D'
          },
          B1F4H19: {
            homeText: 'Home 19',
            planText: 'Plan D1',
            planFileName: 'B1_D1'
          },
          B1F4H20: {
            homeText: 'Home 20',
            planText: 'Plan B4',
            planFileName: 'B1_B4'
          },
          B1F4H21: {
            homeText: 'Home 21',
            planText: 'Plan A5',
            planFileName: 'B1_A5'
          },
          B1F4H22: {
            homeText: 'Home 22',
            planText: 'Plan A4',
            planFileName: 'B1_A4'
          },
          B1F4H23: {
            homeText: 'Home 23',
            planText: 'Plan A3',
            planFileName: 'B1_A3'
          },
          B1F4H24: {
            homeText: 'Home 24',
            planText: 'Plan S1',
            planFileName: 'B1_S1'
          },
          B1F4H25: {
            homeText: 'Home 25',
            planText: 'Plan S',
            planFileName: 'B1_S'
          }
        }
      },
      'level-5': {
        name: 'Level 5',
        component: Building1Level5,
        plans: {
          B1F5H01: {
            homeText: 'Home 01',
            planText: 'Plan A2',
            planFileName: 'B1_A2'
          },
          B1F5H02: {
            homeText: 'Home 02',
            planText: 'Plan D8',
            planFileName: 'B1_D8'
          },
          B1F5H03: {
            homeText: 'Home 03',
            planText: 'Plan D6',
            planFileName: 'B1_D6'
          },
          B1F5H04: {
            homeText: 'Home 04',
            planText: 'Plan B',
            planFileName: 'B1_B'
          },
          B1F5H05: {
            homeText: 'Home 05',
            planText: 'Plan A6',
            planFileName: 'B1_A6'
          },
          B1F5H06: {
            homeText: 'Home 06',
            planText: 'Plan A7',
            planFileName: 'B1_A7'
          },
          B1F5H07: {
            homeText: 'Home 07',
            planText: 'Plan C',
            planFileName: 'B1_C'
          },
          B1F5H08: {
            homeText: 'Home 08',
            planText: 'Plan A1',
            planFileName: 'B1_A1'
          },
          B1F5H09: {
            homeText: 'Home 9',
            planText: 'Plan A20',
            planFileName: 'B1_A20'
          },
          B1F5H10: {
            homeText: 'Home 10',
            planText: 'Plan A21',
            planFileName: 'B1_A21'
          },
          B1F5H11: {
            homeText: 'Home 11',
            planText: 'Plan A18',
            planFileName: 'B1_A18'
          },
          B1F5H12: {
            homeText: 'Home 12',
            planText: 'Plan D10',
            planFileName: 'B1_D10'
          },
          B1F5H13: {
            homeText: 'Home 13',
            planText: 'Plan B2',
            planFileName: 'B1_B2'
          },
          B1F5H14: {
            homeText: 'Home 14',
            planText: 'Plan B1',
            planFileName: 'B1_B1'
          },
          B1F5H15: {
            homeText: 'Home 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A_L5'
          },
          B1F5H16: {
            homeText: 'Home 15, 16',
            planText: 'Plan A',
            planFileName: 'B1_A_L5'
          },
          B1F5H21: {
            homeText: 'Home 21',
            planText: 'Plan A5',
            planFileName: 'B1_A5'
          },
          B1F5H22: {
            homeText: 'Home 22',
            planText: 'Plan A4',
            planFileName: 'B1_A4'
          },
          B1F5H23: {
            homeText: 'Home 23',
            planText: 'Plan A3',
            planFileName: 'B1_A3'
          },
          B1F5H24: {
            homeText: 'Home 24',
            planText: 'Plan S1',
            planFileName: 'B1_S1'
          },
          B1F5H25: {
            homeText: 'Home 25',
            planText: 'Plan S',
            planFileName: 'B1_S'
          }
        }
      },
      'level-6': {
        name: 'Level 6',
        component: Building1Level6,
        plans: {
          B1F6H01: {
            homeText: 'Home 01',
            planText: 'Plan A2',
            planFileName: 'B1_A2'
          },
          B1F6H02: {
            homeText: 'Home 02',
            planText: 'Plan D8',
            planFileName: 'B1_D8'
          },
          B1F6H03: {
            homeText: 'Home 03',
            planText: 'Plan D6',
            planFileName: 'B1_D6'
          },
          B1F6H04: {
            homeText: 'Home 04',
            planText: 'Plan B',
            planFileName: 'B1_B'
          },
          B1F6H05: {
            homeText: 'Home 05',
            planText: 'Plan A6',
            planFileName: 'B1_A6'
          },
          B1F6H06: {
            homeText: 'Home 06',
            planText: 'Plan A7',
            planFileName: 'B1_A7'
          },
          B1F6H07: {
            homeText: 'Home 07',
            planText: 'Plan C',
            planFileName: 'B1_C'
          },
          B1F6H08: {
            homeText: 'Home 08',
            planText: 'Plan A1',
            planFileName: 'B1_A1'
          },
          B1F6H09: {
            homeText: 'Home 09, 10, 15',
            planText: 'Plan A',
            planFileName: 'B1F6_A' //Remove 16 in svg
          },
          B1F6H10: {
            homeText: 'Home 09, 10, 15',
            planText: 'Plan A',
            planFileName: 'B1F6_A' //Remove 16 in svg
          },
          B1F6H11: {
            homeText: 'Home 11',
            planText: 'Plan A17',
            planFileName: 'B1_A17'
          },
          B1F6H12: {
            homeText: 'Home 12',
            planText: 'Plan D11',
            planFileName: 'B1_D11'
          },
          B1F6H13: {
            homeText: 'Home 13',
            planText: 'Plan B5',
            planFileName: 'B1_B5'
          },
          B1F6H14: {
            homeText: 'Home 14',
            planText: 'Plan B1',
            planFileName: 'B1_B1'
          },
          B1F6H15: {
            homeText: 'Home 09, 10, 15',
            planText: 'Plan A',
            planFileName: 'B1F6_A' //Remove 16 in svg
          },
          B1F6H16: {
            homeText: 'Home 16',
            planText: 'Plan A19',
            planFileName: 'B1_A19'
          },
          B1F6H21: {
            homeText: 'Home 21',
            planText: 'Plan A5',
            planFileName: 'B1_A5'
          },
          B1F6H22: {
            homeText: 'Home 22',
            planText: 'Plan A4',
            planFileName: 'B1_A4'
          },
          B1F6H23: {
            homeText: 'Home 23',
            planText: 'Plan A3',
            planFileName: 'B1_A3'
          },
          B1F6H24: {
            homeText: 'Home 24',
            planText: 'Plan S1',
            planFileName: 'B1_S1'
          },
          B1F6H25: {
            homeText: 'Home 25',
            planText: 'Plan S',
            planFileName: 'B1_S'
          }
        }
      }
    }
  },
  'building-2': {
    name: 'Building 2',
    levels: {
      'level-1': {
        name: 'Level 1',
        component: Building2Level1,
        plans: {
          B2F1H01: {
            homeText: 'Home 01',
            planText: 'Plan E10',
            planFileName: 'B2_E10'
          },
          B2F1H03: {
            homeText: 'Home 03',
            planText: 'Plan H22',
            planFileName: 'B2_H22'
          },
          B2F1H04: {
            homeText: 'Home 04',
            planText: 'Plan H20',
            planFileName: 'B2_H20'
          },
          B2F1H05: {
            homeText: 'Home 05',
            planText: 'Plan F1',
            planFileName: 'B2_F1'
          },
          B2F1H06: {
            homeText: 'Home 06',
            planText: 'Plan E12',
            planFileName: 'B2_E12'
          },
          B2F1H08: {
            homeText: 'Home 08',
            planText: 'Plan E8',
            planFileName: 'B2_E8'
          },
          B2F1H09: {
            homeText: 'Home 09',
            planText: 'Plan E7',
            planFileName: 'B2_E7'
          },
          B2F1H10: {
            homeText: 'Home 10',
            planText: 'Plan E16',
            planFileName: 'B2_E16'
          },
          B2F1H11: {
            homeText: 'Home 11',
            planText: 'Plan E17',
            planFileName: 'B2_E17'
          },
          B2F1H12: {
            homeText: 'Home 12',
            planText: 'Plan E14',
            planFileName: 'B2_E14'
          },
          B2F1H13: {
            homeText: 'Home 13',
            planText: 'Plan H12',
            planFileName: 'B2_H12'
          },
          B2F1H14: {
            homeText: 'Home 14',
            planText: 'Plan G1',
            planFileName: 'B2_G1'
          },
          B2F1H15: {
            homeText: 'Home 15',
            planText: 'Plan E18',
            planFileName: 'B2_E18'
          },
          B2F1H16: {
            homeText: 'Home 16, 17',
            planText: 'Plan E6',
            planFileName: 'B2_E6'
          },
          B2F1H17: {
            homeText: 'Home 16, 17',
            planText: 'Plan E6',
            planFileName: 'B2_E6'
          },
          B2F1H18: {
            homeText: 'Home 18',
            planText: 'Plan H13',
            planFileName: 'B2_H13'
          },
          B2F1H19: {
            homeText: 'Home 19',
            planText: 'Plan H14',
            planFileName: 'B2_H14'
          },
          B2F1H20: {
            homeText: 'Home 20',
            planText: 'Plan E11',
            planFileName: 'B2_E11'
          }
        }
      },
      'level-2': {
        name: 'Level 2',
        component: Building2Level2,
        plans: {
          B2F2H01: {
            homeText: 'Home 01',
            planText: 'Plan S2',
            planFileName: 'B2_S2'
          },
          B2F2H02: {
            homeText: 'Home 02',
            planText: 'Plan S3',
            planFileName: 'B2_S3'
          },
          B2F2H03: {
            homeText: 'Home 03',
            planText: 'Plan H8',
            planFileName: 'B2_H8'
          },
          B2F2H04: {
            homeText: 'Home 04',
            planText: 'Plan H4',
            planFileName: 'B2_H4'
          },
          B2F2H05: {
            homeText: 'Home 05',
            planText: 'Plan F2',
            planFileName: 'B2_F2'
          },
          B2F2H06: {
            homeText: 'Home 06',
            planText: 'Plan E13',
            planFileName: 'B2_E13'
          },
          B2F2H08: {
            homeText: 'Home 08',
            planText: 'Plan E9',
            planFileName: 'B2_E9'
          },
          B2F2H09: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F2H10: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F2H11: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F2H12: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F2H13: {
            homeText: 'Home 13',
            planText: 'Plan H1',
            planFileName: 'B2_H1'
          },
          B2F2H14: {
            homeText: 'Home 14',
            planText: 'Plan G',
            planFileName: 'B2_G'
          },
          B2F2H15: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F2H16: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F2H17: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F2H18: {
            homeText: 'Home 18',
            planText: 'Plan H2',
            planFileName: 'B2_H2'
          },
          B2F2H19: {
            homeText: 'Home 19',
            planText: 'Plan H15',
            planFileName: 'B2_H15'
          },
          B2F2H20: {
            homeText: 'Home 20',
            planText: 'Plan H3',
            planFileName: 'B2_H3'
          },
          B2F2H21: {
            homeText: 'Home 21',
            planText: 'Plan H',
            planFileName: 'B2_H'
          },
          B2F2H22: {
            homeText: 'Home 22',
            planText: 'Plan E1',
            planFileName: 'B2_E1'
          },
          B2F2H23: {
            homeText: 'Home 23',
            planText: 'Plan E2',
            planFileName: 'B2_E2'
          }
        }
      },
      'level-3': {
        name: 'Level 3',
        component: Building2Level3,
        plans: {
          B2F3H01: {
            homeText: 'Home 01',
            planText: 'Plan S2',
            planFileName: 'B2_S2'
          },
          B2F3H02: {
            homeText: 'Home 02',
            planText: 'Plan S3',
            planFileName: 'B2_S3'
          },
          B2F3H03: {
            homeText: 'Home 03',
            planText: 'Plan H10',
            planFileName: 'B2_H10'
          },
          B2F3H04: {
            homeText: 'Home 04',
            planText: 'Plan H5',
            planFileName: 'B2_H5'
          },
          B2F3H05: {
            homeText: 'Home 05',
            planText: 'Plan F',
            planFileName: 'B2_F'
          },
          B2F3H06: {
            homeText: 'Home 06',
            planText: 'Plan E5',
            planFileName: 'B2_E5'
          },
          B2F3H07: {
            homeText: 'Home 07',
            planText: 'Plan E3',
            planFileName: 'B2_E3'
          },
          B2F3H08: {
            homeText: 'Home 08',
            planText: 'Plan E4',
            planFileName: 'B2_E4'
          },
          B2F3H09: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F3H10: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F3H11: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F3H12: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F3H13: {
            homeText: 'Home 13',
            planText: 'Plan H1',
            planFileName: 'B2_H1'
          },
          B2F3H14: {
            homeText: 'Home 14',
            planText: 'Plan G',
            planFileName: 'B2_G'
          },
          B2F3H15: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F3H16: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F3H17: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F3H18: {
            homeText: 'Home 18',
            planText: 'Plan H2',
            planFileName: 'B2_H2'
          },
          B2F3H19: {
            homeText: 'Home 19',
            planText: 'Plan H7',
            planFileName: 'B2_H7'
          },
          B2F3H20: {
            homeText: 'Home 20',
            planText: 'Plan H3',
            planFileName: 'B2_H3'
          },
          B2F3H21: {
            homeText: 'Home 21',
            planText: 'Plan H',
            planFileName: 'B2_H'
          },
          B2F3H22: {
            homeText: 'Home 22',
            planText: 'Plan E1',
            planFileName: 'B2_E1'
          },
          B2F3H23: {
            homeText: 'Home 23',
            planText: 'Plan E2',
            planFileName: 'B2_E2'
          }
        }
      },
      'level-4': {
        name: 'Level 4',
        component: Building2Level4,
        plans: {
          B2F4H01: {
            homeText: 'Home 01',
            planText: 'Plan S2',
            planFileName: 'B2_S2'
          },
          B2F4H02: {
            homeText: 'Home 02',
            planText: 'Plan S3',
            planFileName: 'B2_S3'
          },
          B2F4H03: {
            homeText: 'Home 03',
            planText: 'Plan H18',
            planFileName: 'B2_H18'
          },
          B2F4H04: {
            homeText: 'Home 04',
            planText: 'Plan H21',
            planFileName: 'B2_H21'
          },
          B2F4H05: {
            homeText: 'Home 05',
            planText: 'Plan F',
            planFileName: 'B2_F'
          },
          B2F4H06: {
            homeText: 'Home 06',
            planText: 'Plan E5',
            planFileName: 'B2_E5'
          },
          B2F4H07: {
            homeText: 'Home 07',
            planText: 'Plan E3',
            planFileName: 'B2_E3'
          },
          B2F4H08: {
            homeText: 'Home 08',
            planText: 'Plan E4',
            planFileName: 'B2_E4'
          },
          B2F4H09: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F4H10: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F4H11: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F4H12: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F4H13: {
            homeText: 'Home 13',
            planText: 'Plan H1',
            planFileName: 'B2_H1'
          },
          B2F4H14: {
            homeText: 'Home 14',
            planText: 'Plan G',
            planFileName: 'B2_G'
          },
          B2F4H15: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F4H16: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F4H17: {
            homeText: 'Home 15, 16, 17',
            planText: 'Plan E19',
            planFileName: 'B2_E19'
          },
          B2F4H18: {
            homeText: 'Home 18',
            planText: 'Plan H2',
            planFileName: 'B2_H2'
          },
          B2F4H19: {
            homeText: 'Home 19',
            planText: 'Plan H7',
            planFileName: 'B2_H7'
          },
          B2F4H20: {
            homeText: 'Home 20',
            planText: 'Plan H3',
            planFileName: 'B2_H3'
          },
          B2F4H21: {
            homeText: 'Home 21',
            planText: 'Plan H',
            planFileName: 'B2_H'
          },
          B2F4H22: {
            homeText: 'Home 22',
            planText: 'Plan E1',
            planFileName: 'B2_E1'
          },
          B2F4H23: {
            homeText: 'Home 23',
            planText: 'Plan E2',
            planFileName: 'B2_E2'
          }
        }
      },
      'level-5': {
        name: 'Level 5',
        component: Building2Level5,
        plans: {
          B2F5H01: {
            homeText: 'Home 01',
            planText: 'Plan S2',
            planFileName: 'B2_S2'
          },
          B2F5H02: {
            homeText: 'Home 02',
            planText: 'Plan S3',
            planFileName: 'B2_S3'
          },
          B2F5H03: {
            homeText: 'Home 03',
            planText: 'Plan H9',
            planFileName: 'B2_H9'
          },
          B2F5H04: {
            homeText: 'Home 04',
            planText: 'Plan H6',
            planFileName: 'B2_H6'
          },
          B2F5H05: {
            homeText: 'Home 05',
            planText: 'Plan F',
            planFileName: 'B2_F'
          },
          B2F5H06: {
            homeText: 'Home 06',
            planText: 'Plan E5',
            planFileName: 'B2_E5'
          },
          B2F5H07: {
            homeText: 'Home 07',
            planText: 'Plan E3',
            planFileName: 'B2_E3'
          },
          B2F5H08: {
            homeText: 'Home 08',
            planText: 'Plan E4',
            planFileName: 'B2_E4'
          },
          B2F5H09: {
            homeText: 'Home 09, 12',
            planText: 'Plan E',
            planFileName: 'B2_E_H0912'
          },
          B2F5H10: {
            homeText: 'Home 10, 11',
            planText: 'Plan E15',
            planFileName: 'B2_E15'
          },
          B2F5H11: {
            homeText: 'Home 10, 11',
            planText: 'Plan E15',
            planFileName: 'B2_E15'
          },
          B2F5H12: {
            homeText: 'Home 09, 12',
            planText: 'Plan E',
            planFileName: 'B2_E_H0912'
          },
          B2F5H13: {
            homeText: 'Home 13',
            planText: 'Plan H17',
            planFileName: 'B2_H17'
          },
          B2F5H20: {
            homeText: 'Home 20',
            planText: 'Plan H11',
            planFileName: 'B2_H11'
          },
          B2F5H21: {
            homeText: 'Home 21',
            planText: 'Plan H',
            planFileName: 'B2_H'
          },
          B2F5H22: {
            homeText: 'Home 22',
            planText: 'Plan E1',
            planFileName: 'B2_E1'
          },
          B2F5H23: {
            homeText: 'Home 23',
            planText: 'Plan E2',
            planFileName: 'B2_E2'
          }
        }
      },
      'level-6': {
        name: 'Level 6',
        component: Building2Level6,
        plans: {
          B2F6H01: {
            homeText: 'Home 01',
            planText: 'Plan S2',
            planFileName: 'B2_S2'
          },
          B2F6H02: {
            homeText: 'Home 02',
            planText: 'Plan S3',
            planFileName: 'B2_S3'
          },
          B2F6H03: {
            homeText: 'Home 03',
            planText: 'Plan H9',
            planFileName: 'B2_H9'
          },
          B2F6H04: {
            homeText: 'Home 04',
            planText: 'Plan H6',
            planFileName: 'B2_H6'
          },
          B2F6H05: {
            homeText: 'Home 05',
            planText: 'Plan F',
            planFileName: 'B2_F'
          },
          B2F6H06: {
            homeText: 'Home 06',
            planText: 'Plan E5',
            planFileName: 'B2_E5'
          },
          B2F6H07: {
            homeText: 'Home 07',
            planText: 'Plan E3',
            planFileName: 'B2_E3'
          },
          B2F6H08: {
            homeText: 'Home 08',
            planText: 'Plan E4',
            planFileName: 'B2_E4'
          },
          B2F6H09: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F6H10: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F6H11: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F6H12: {
            homeText: 'Home 09, 10, 11, 12',
            planText: 'Plan E',
            planFileName: 'B2_E'
          },
          B2F6H13: {
            homeText: 'Home 13',
            planText: 'Plan H19',
            planFileName: 'B2_H19'
          },
          B2F6H20: {
            homeText: 'Home 20',
            planText: 'Plan H11',
            planFileName: 'B2_H11'
          },
          B2F6H21: {
            homeText: 'Home 21',
            planText: 'Plan H',
            planFileName: 'B2_H'
          },
          B2F6H22: {
            homeText: 'Home 22',
            planText: 'Plan E1',
            planFileName: 'B2_E1'
          },
          B2F6H23: {
            homeText: 'Home 23',
            planText: 'Plan E2',
            planFileName: 'B2_E2'
          }
        }
      }
    }
  }
};

const generateFloorPlanRoutes = baseRoute => {
  const routes = [];

  _.forEach(FloorPlanData, (building, buildingKey) => {
    _.forEach(building.levels, (level, levelKey) => {
      const plans = _.clone(level.plans);
      _.forEach(plans, (plan, planKey) => {
        _.set(plans, planKey, _.omit(plan, ['component']));
      });
      routes.push({
        path: `${buildingKey}/${levelKey}`,
        component: level.component,
        props: {
          plans,
          buildingKey,
          levelKey
        }
      });

      _.forEach(plans, (plan, planKey) => {
        routes.push({
          path: `${buildingKey}/${levelKey}/${planKey}`,
          component: FloorPlan,
          props: {
            buildingKey,
            levelKey,
            planKey,
            planImage: require(`../assets/images/floor-plan/${buildingKey}/${plan.planFileName}.svg`),
            homeText: plan.homeText,
            planText: plan.planText,
            levelText: level.name,
            plans
          }
        });
      });
    });
  });

  // generate default route
  const firstBuildingKey = _.first(_.keys(FloorPlanData));
  const firstBuilding = _.get(FloorPlanData, firstBuildingKey);
  const firstLevelKey = _.first(_.keys(firstBuilding.levels));
  routes.push({ path: '', redirect: `${baseRoute}/${firstBuildingKey}/${firstLevelKey}` });

  // generate default route per building
  _.forEach(FloorPlanData, (building, buildingKey) => {
    const firstLevelKey = _.first(_.keys(building.levels));
    routes.push({ path: buildingKey, redirect: `${baseRoute}/${buildingKey}/${firstLevelKey}` });
  });

  return routes;
};

export { FloorPlanData, generateFloorPlanRoutes };
