import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import 'swiped-events';
import plausible from './plugins/plausible';

const app = createApp(App);
app.use(router)
if (process.env.VUE_APP_PLAUSABLE_ENABLED == '1') {
  app.use(plausible, {
    domain: process.env.VUE_APP_PLAUSIBLE_DOMAIN,
    hashMode: true,
    trackLocalhost: false,
  });
}
app.mount('#app');

const RELOAD_ENABLED = process.env.NODE_ENV === 'production';
if (RELOAD_ENABLED) {
  // handle reload after being idle
  const RELOAD_TIMEOUT_SECONDS_AFTER_ACTIVITY = 60;
  const RELOAD_TIMEOUT_SECONDS_ON_LANDING_PAGE = 60;
  let inactivityTime = function() {
    let time;
    window.onload = resetTimer;
    document.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer; // touchpad clicks
    document.onkeypress = resetTimer;
    document.addEventListener('scroll', resetTimer, true);
    function reload() {
      // console.log('Reload.');
      document.location = '/';
    }
    function resetTimer() {
      clearTimeout(time);

      let timeoutSeconds = RELOAD_TIMEOUT_SECONDS_AFTER_ACTIVITY;
      if (window.location.pathname === '/') {
        timeoutSeconds = RELOAD_TIMEOUT_SECONDS_ON_LANDING_PAGE;
      }

      time = setTimeout(reload, timeoutSeconds * 1000);
    }
  };
  inactivityTime();
}
