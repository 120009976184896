<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-1/level-2/B1L2-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B1F2H01"
            class="cls-2"
            points="474.6 256.89 474.6 201 601.03 201 601.03 257.38 610.59 257.38 610.59 259.73 611.95 259.73 611.95 310.79 474.6 310.79 474.6 256.89"
          />
          <polygon
            id="B1F2H02"
            class="cls-2"
            points="474.6 155.22 474.6 138.56 463.28 138.56 463.28 33.05 519.83 33.05 519.83 38.91 550.33 38.91 550.33 19 557.38 19 557.38 17.45 601.23 17.45 601.23 19.15 608.24 19.15 608.24 39.09 609.81 39.09 609.81 83.11 598.43 83.11 598.43 164.1 598.43 197.39 552.11 197.58 551.93 155.22 474.6 155.22 474.6 155.22"
          />
          <polygon
            id="B1F2H03"
            class="cls-2"
            points="448.29 156.13 302.18 156.13 302.18 19 309.2 19 309.2 17.42 366.88 17.42 366.88 19 373.98 19 373.98 33.05 459.74 33.05 459.74 135.36 448.29 135.36 448.29 156.13"
          />
          <polygon
            id="B1F2H04"
            class="cls-2"
            points="277.21 156.34 302.18 156.34 302.18 159.74 448.29 159.74 448.29 180.15 435.68 180.15 435.68 223.58 302.18 223.58 302.18 224.59 288.08 224.59 288.08 225.9 277.18 225.9 277.21 156.34"
          />
          <polygon
            id="B1F2H05"
            class="cls-2"
            points="448.29 249.58 448.29 227.04 302.18 227.04 302.18 279.26 300.32 279.26 300.32 308.27 302.03 308.27 302.03 308.95 345.12 308.95 448.29 308.95 448.29 249.58"
          />
          <polygon
            id="B1F2H07"
            class="cls-2"
            points="448.29 437.89 448.29 408.93 444.26 408.93 444.26 397.71 302.18 397.71 302.18 493.36 448.31 493.36 448.29 437.89"
          />
          <polygon
            id="B1F2H08"
            class="cls-2"
            points="448.31 495.87 302.18 495.87 302.18 547.99 300.67 547.99 300.67 577.92 448.31 577.92 448.31 495.87"
          />
          <polygon
            id="B1F2H09"
            class="cls-2"
            points="448.31 580.23 302.18 580.23 302.18 632.35 300.8 632.35 300.8 662.31 448.31 662.31 448.31 580.23"
          />
          <polygon
            id="B1F2H10"
            class="cls-2"
            points="448.31 664.54 302.18 664.54 302.18 716.66 300.61 716.66 300.61 746.55 448.31 746.55 448.31 664.54"
          />
          <polygon
            id="B1F2H11"
            class="cls-2"
            points="448.31 748.87 302.18 748.87 302.18 801 300.77 801 300.77 830.87 448.71 830.87 448.31 748.87"
          />
          <polygon
            id="B1F2H12"
            class="cls-2"
            points="466.96 800.53 448.56 800.53 448.56 833.26 293.6 833.26 293.6 918.55 292.02 918.55 292.02 948.21 344.34 948.21 344.34 946.64 466.96 946.64 466.96 800.53"
          />
          <rect id="B1F2H13" class="cls-2" x="470.57" y="800.53" width="63.98" height="146.13" />
          <rect id="B1F2H14" class="cls-2" x="538.01" y="800.63" width="64.07" height="146.03" />
          <polygon
            id="B1F2H15"
            class="cls-2"
            points="605.85 800.63 686.18 800.63 686.18 946.64 634.08 946.64 634.08 948.3 604.67 948.3 604.42 903.7 605.03 903.7 605.85 800.63"
          />
          <polygon
            id="B1F2H16"
            class="cls-2"
            points="689.91 800.15 770.75 800.15 770.75 946.64 718.44 946.64 718.44 948.25 688.7 948.25 688.7 903.7 689.91 903.7 689.91 800.15"
          />
          <polygon
            id="B1F2H17"
            class="cls-2"
            points="774.13 800.36 854.85 800.36 854.85 946.64 802.75 946.64 802.75 948.17 773.1 948.17 773.1 903.7 774.13 903.7 774.13 800.36"
          />
          <polygon
            id="B1F2H18"
            class="cls-2"
            points="833.61 781.32 875.87 781.32 875.87 789.12 990.14 789.12 990.14 882.01 1003.4 882.01 1003.4 946.64 1001.86 946.64 1001.86 948.98 972.95 948.98 972.95 946.64 857.41 946.64 857.41 800.15 833.61 800.15 833.61 781.32"
          />
          <polygon
            id="B1F2H19"
            class="cls-2"
            points="815.77 773.28 815.77 648.37 931.23 648.37 931.23 646.58 991.69 646.58 991.69 674.74 990.11 674.74 990.11 785.51 879.87 785.51 879.87 777.56 847.17 777.56 847.17 773.42 815.77 773.28"
          />
          <polygon
            id="B1F2H20"
            class="cls-2"
            points="745.59 773.28 745.59 682.13 702.61 682.13 702.61 648.16 791.11 648.16 791.11 646.87 813.69 646.87 813.69 773.29 745.59 773.28"
          />
          <polygon
            id="B1F2H21"
            class="cls-2"
            points="639.19 773.28 639.19 757.47 588.92 757.47 588.92 770.1 527.54 770.1 527.54 728.42 607.96 728.42 607.96 680.52 657.23 680.52 657.23 678.64 700.21 678.64 700.21 773.28 639.19 773.28"
          />
          <polygon
            id="B1F2H22"
            class="cls-2"
            points="474.55 716.45 474.55 634.55 583.83 634.55 583.83 633.12 613.49 633.12 613.49 680.35 604.4 680.35 604.4 724.81 527.54 724.81 527.54 716.27 474.55 716.45"
          />
          <polygon
            id="B1F2H23"
            class="cls-2"
            points="474.6 587.25 474.6 571.04 490.41 571.04 490.41 526.67 601.03 526.67 601.03 588.45 613.49 588.45 613.49 632.35 583.7 632.35 583.7 630.94 474.6 630.94 474.6 587.25"
          />
          <rect id="B1F2H24" class="cls-2" x="474.59" y="461.1" width="123.83" height="61.69" />
          <polygon
            id="B1F2H25"
            class="cls-2"
            points="474.6 457.54 598.43 457.54 598.43 397.69 481.83 397.69 481.83 420.55 474.6 420.55 474.6 457.54"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-1-level-2-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-1-level-2-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building1Level2MiniMap from './Building1Level2MiniMap.vue';

export default {
  name: 'Building1Level2',
  components: {
    FloorPlanFloorPlate,
    Building1Level2MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
