<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-2/level-3/B2L3-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B2F3H01"
            class="cls-2"
            points="676.2 747.88 548.04 747.88 547.9 814.27 676.16 814.82 676.2 747.88"
          />
          <rect id="B2F3H02" class="cls-2" x="548.05" y="817.57" width="128.16" height="63.11" />
          <polygon
            id="B2F3H03"
            class="cls-2"
            points="676.21 926.71 595.84 926.71 595.84 884.12 548.02 884.12 548.02 918.92 548.04 998.88 547.9 1044.58 547.9 1050.7 592.28 1050.7 687.56 1050.8 687.56 964.92 678.17 964.92 678.17 943.95 676.21 943.95 676.21 926.71"
          />
          <polygon
            id="B2F3H04"
            class="cls-2"
            points="703.02 926.38 850.82 926.38 850.82 1031.73 851.04 1062.78 848.61 1062.78 785.3 1062.78 785.29 1050.92 691.47 1050.74 691.54 944.02 703.05 944.2 703.02 926.38"
          />
          <polygon
            id="B2F3H05"
            class="cls-2"
            points="702.83 854.3 850.99 854.3 851.04 865.45 876.3 865.45 876.3 915.94 850.99 915.94 850.99 922.65 718.41 922.65 718.41 889.2 702.83 889.2 702.83 854.3"
          />
          <polygon
            id="B2F3H06"
            class="cls-2"
            points="702.81 753.68 822.1 753.68 822.1 752.61 852.41 752.61 852.5 795.75 850.99 795.73 850.99 850.79 702.81 850.79 702.81 753.68"
          />
          <polygon
            id="B2F3H07"
            class="cls-2"
            points="703.02 695.47 710.35 695.47 710.35 671.73 807.63 671.73 807.63 669.68 852.94 669.68 852.94 697.01 850.9 697.01 850.9 750.21 703.02 750.21 703.02 695.47"
          />
          <polygon
            id="B2F3H08"
            class="cls-2"
            points="702.79 650.69 710.26 650.69 710.26 665.55 850.99 665.55 850.99 614.69 852.66 614.69 852.81 584.41 807.05 584.41 807.07 585.26 702.79 585.26 702.79 650.69"
          />
          <polygon
            id="B2F3H09"
            class="cls-2"
            points="702.78 581.49 850.99 581.49 850.99 528.86 852.58 528.8 852.58 498.89 807.06 498.83 807.06 499.75 702.8 499.75 702.78 581.49"
          />
          <polygon
            id="B2F3H10"
            class="cls-2"
            points="702.78 496.37 850.86 496.37 850.86 442.76 852.93 442.76 852.4 413.05 807.34 413.05 807.57 413.85 702.78 413.85 702.78 496.37"
          />
          <polygon
            id="B2F3H11"
            class="cls-2"
            points="703.04 410.79 850.99 410.79 850.78 357.91 852.4 357.91 852.66 327.9 807.3 327.9 807.3 328.95 703.04 328.95 703.04 410.79"
          />
          <polygon
            id="B2F3H12"
            class="cls-2"
            points="702.87 325.04 850.86 324.83 850.86 272.08 852.75 272.05 852.93 242.05 807.57 242.05 807.57 243.25 706.13 243.25 706.13 287.73 702.87 287.73 702.87 325.04"
          />
          <polygon
            id="B2F3H13"
            class="cls-2"
            points="702.33 272.74 702.33 239.88 859.58 239.47 859.58 153.13 861.28 153.53 861.28 123.03 808 123.03 808 124.62 683.86 124.62 683.86 272.74 702.33 272.74"
          />
          <polygon
            id="B2F3H14"
            class="cls-2"
            points="679.94 272.55 679.94 155.19 680.01 152.59 680.73 152.59 680.73 127.23 670.58 127.23 670.58 110.39 636.19 110.39 636.19 152.59 629.6 152.59 629.6 124.62 562.13 124.62 562.13 272.55 679.94 272.55"
          />
          <polygon
            id="B2F3H15"
            class="cls-2"
            points="558.6 272.76 559 168.31 560.04 168.31 560.04 123.16 530.01 123.16 530.01 124.83 476.78 124.84 476.78 272.73 558.6 272.76"
          />
          <polygon
            id="B2F3H16"
            class="cls-2"
            points="472.94 272.41 473.34 167.96 474.38 167.96 474.38 122.81 444.17 122.81 444.17 124.49 391.37 124.49 391.37 272.09 472.94 272.41"
          />
          <polygon
            id="B2F3H17"
            class="cls-2"
            points="387.59 272.1 387.84 168.1 388.88 168.1 388.88 124.62 358.7 124.58 305.85 124.58 305.85 272.1 387.59 272.1"
          />
          <polygon
            id="B2F3H18"
            class="cls-2"
            points="302.84 272.55 302.84 124.62 157.23 124.62 157.23 190.76 195.49 190.76 195.49 197.03 169.44 197.03 169.44 284.35 281.41 284.35 281.41 272.76 302.84 272.55"
          />
          <polygon
            id="B2F3H19"
            class="cls-2"
            points="284.96 287.88 169.13 287.88 169.06 402.16 167.51 402.14 167.57 429.05 228.76 429.05 228.73 427.46 345.15 427.46 345.15 379.13 303.04 379.13 303.04 299.66 284.83 299.66 284.96 287.88"
          />
          <polygon
            id="B2F3H20"
            class="cls-2"
            points="348.36 299.97 348.36 409.01 347.64 409.07 347.64 427.86 346.05 427.86 346.05 447.64 392.06 447.64 392.26 436.37 390.47 436.37 390.47 427.07 447.44 426.98 447.44 409.07 489.04 408.65 489.05 320.94 444.83 320.94 444.83 299.77 408.54 299.59 348.36 299.97"
          />
          <polygon
            id="B2F3H21"
            class="cls-2"
            points="492.72 299.38 492.72 393.66 491.68 393.66 491.68 409.07 490.09 409.07 490.09 424.89 527.06 424.89 527.06 442.34 564.89 442.34 564.89 440.43 672.36 440.43 672.36 392.83 660.42 392.83 660.42 356.75 622.77 356.75 622.77 315.6 610.13 315.6 610.13 299.54 492.72 299.38"
          />
          <polygon
            id="B2F3H22"
            class="cls-2"
            points="675.61 444.67 564.89 444.67 564.89 442.34 527.06 442.34 527.06 488.05 547.6 488.05 547.6 540.16 675.61 540.16 675.61 444.67"
          />
          <polygon
            id="B2F3H23"
            class="cls-2"
            points="675.61 543.79 567.53 543.79 564.89 543.79 564.89 542.8 538.12 542.8 538.12 541 527.06 541 527.06 596.04 538.19 596.04 538.19 594.82 547.6 594.82 547.6 664.76 668.4 664.76 668.4 645.94 672.36 645.94 675.61 645.94 675.61 543.79"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-2-level-3-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-2-level-3-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building2Level3MiniMap from './Building2Level3MiniMap.vue';

export default {
  name: 'Building2Level3',
  components: {
    FloorPlanFloorPlate,
    Building2Level3MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
