<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-1/level-1/B1L1-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B1F1H01"
            class="cls-2"
            points="523.73 225.57 523.73 313.18 630.91 313.18 630.91 271.97 649.56 271.97 649.56 225.57 523.73 225.57"
          />
          <polygon
            id="B1F1H02"
            class="cls-2"
            points="512.47 138.4 524.03 138.4 524.03 158.17 600.69 158.17 600.69 153.83 602.49 153.83 602.49 200.14 649.56 200.14 649.56 165.84 649.56 86.36 624.75 86.36 624.75 42.44 606.12 42.44 606.12 22.59 599.13 22.59 599.13 42.41 568.65 42.41 568.65 36.72 512.47 36.72"
          />
          <polygon
            id="B1F1H03"
            class="cls-2"
            points="494.33 157.77 494.33 159.08 352.13 159.08 352.13 22.59 358.95 22.59 359.26 52.8 416.59 52.8 416.64 36.58 423.64 36.56 468.73 36.56 475.72 36.61 506.03 36.61 508.88 36.58 508.88 138.4 482.53 138.4 482.53 140.38 494.33 140.38 494.33 157.77"
          />
          <polygon
            id="B1F1H04"
            class="cls-2"
            points="497.55 164.02 494.33 164.02 494.33 162.67 352.1 162.67 352.1 226.2 485.26 226.2 485.26 182.93 494.36 182.93 494.36 177.28 497.55 177.28 497.55 164.02"
          />
          <polygon
            id="B1F1H05"
            class="cls-2"
            points="352.16 229.64 352.16 281.64 379.32 281.64 379.32 310.11 497.55 310.11 497.55 229.54 352.16 229.64"
          />
          <polygon
            id="B1F1H08"
            class="cls-2"
            points="494.37 493.32 352.11 493.32 352.11 549.06 379.32 549.06 379.32 577.51 497.55 577.51 497.55 493.3 494.37 493.32"
          />
          <polygon
            id="B1F1H09"
            class="cls-2"
            points="352.11 581.17 497.52 581.17 497.52 662 379.32 662 379.32 633 352.13 633 352.11 581.17"
          />
          <polygon
            id="B1F1H10"
            class="cls-2"
            points="497.52 665.1 352.11 665.1 352.11 716.93 379.24 716.93 379.24 745.44 497.57 745.44 497.52 665.1"
          />
          <polygon
            id="B1F1H11"
            class="cls-2"
            points="497.45 748.95 352.13 748.95 352.13 800.86 379.54 800.86 379.54 829.55 497.79 829.55 497.79 800.4 497.55 800.4 497.45 748.95"
          />
          <polygon
            id="B1F1H12"
            class="cls-2"
            points="516.13 800.35 497.79 800.35 497.79 832.97 343.5 832.97 343.5 917.83 394.78 917.83 394.78 945.81 516.13 945.81 516.13 800.35"
          />
          <polygon
            id="B1F1H13"
            class="cls-2"
            points="519.72 800.4 519.72 945.81 583.45 945.81 583.45 800.5 519.72 800.4"
          />
          <polygon
            id="B1F1H14"
            class="cls-2"
            points="586.73 800.4 586.73 945.81 650.46 945.81 650.46 800.5 586.73 800.4"
          />
          <polygon
            id="B1F1H15"
            class="cls-2"
            points="654.48 800.4 734.45 800.4 734.45 945.81 682.57 945.81 682.57 918.52 653.97 918.52 654.48 800.4"
          />
          <polygon
            id="B1F1H16"
            class="cls-2"
            points="738.32 800.4 818.29 800.4 818.29 945.81 766.41 945.81 766.41 918.52 737.81 918.52 738.32 800.4"
          />
          <polygon
            id="B1F1H17"
            class="cls-2"
            points="822.26 800.46 902.23 800.46 902.23 945.88 850.35 945.88 850.35 918.58 821.75 918.58 822.26 800.46"
          />
          <polygon
            id="B1F1H18"
            class="cls-2"
            points="880.85 781.28 880.85 800 905.76 800 905.76 945.81 1019.7 945.81 1019.7 881.26 1036.79 881.26 1036.79 789.04 923.02 789.04 923.02 781.58 880.85 781.28"
          />
          <polygon
            id="B1F1H19"
            class="cls-2"
            points="863.8 773.36 863.8 648.77 978.18 648.77 978.18 673.44 1036.84 673.44 1036.84 785.45 926.7 785.45 926.7 777.97 894.53 777.97 894.53 773.28 863.8 773.36"
          />
          <polygon
            id="B1F1H20"
            class="cls-2"
            points="793.41 773.02 793.41 682.56 750.65 682.56 750.65 648.77 838.74 648.77 838.74 683.64 860.21 683.64 860.21 773.02 793.41 773.02"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-1-level-1-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-1-level-1-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building1Level1MiniMap from './Building1Level1MiniMap.vue';

export default {
  name: 'Building1Level1',
  components: {
    FloorPlanFloorPlate,
    Building1Level1MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      // Prevent JS error when double clicking
      if (this.$refs.floorPlanFloorPlate != null) {
        this.$refs.floorPlanFloorPlate.selectPlan($event);
      }
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
