<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-2/level-5/B2L5-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B2F5H01"
            class="cls-2"
            points="675.48 747.88 547.31 747.88 547.17 814.27 675.43 814.82 675.48 747.88"
          />
          <rect id="B2F5H02" class="cls-2" x="547.32" y="817.57" width="128.16" height="63.11" />
          <polygon
            id="B2F5H03"
            class="cls-2"
            points="675.48 926.71 595.11 926.71 595.11 884.12 547.3 884.12 547.3 918.92 547.31 998.88 547.18 1044.58 547.18 1050.7 591.55 1050.7 686.83 1050.8 686.83 964.92 677.45 964.92 677.45 943.95 675.48 943.95 675.48 926.71"
          />
          <polygon
            id="B2F5H04"
            class="cls-2"
            points="702.06 925.85 849.86 925.85 849.86 1031.2 850.07 1062.25 847.65 1062.25 784.34 1062.25 784.33 1050.39 690.51 1050.21 690.58 943.49 702.08 943.67 702.06 925.85"
          />
          <polygon
            id="B2F5H05"
            class="cls-2"
            points="702.19 853.84 850.36 853.84 850.4 864.99 875.66 864.99 875.66 915.48 850.36 915.48 850.36 922.19 717.78 922.19 717.78 888.74 702.19 888.74 702.19 853.84"
          />
          <polygon
            id="B2F5H06"
            class="cls-2"
            points="702 753.33 821.3 753.33 821.3 752.26 851.61 752.26 851.69 795.4 850.18 795.38 850.18 850.44 702 850.44 702 753.33"
          />
          <polygon
            id="B2F5H07"
            class="cls-2"
            points="702.06 695.31 709.4 695.31 709.4 671.56 806.67 671.56 806.67 669.52 851.99 669.52 851.99 696.84 849.95 696.84 849.95 750.04 702.06 750.04 702.06 695.31"
          />
          <polygon
            id="B2F5H08"
            class="cls-2"
            points="702.15 650.25 709.63 650.25 709.63 665.11 850.35 665.11 850.35 614.24 852.02 614.24 852.17 583.96 806.41 583.96 806.43 584.81 702.15 584.81 702.15 650.25"
          />
          <polygon
            id="B2F5H09"
            class="cls-2"
            points="702.26 581.34 850.47 581.34 850.47 528.71 852.06 528.65 852.42 498.37 806.91 498.31 806.54 499.6 702.28 499.6 702.26 581.34"
          />
          <polygon
            id="B2F5H10"
            class="cls-2"
            points="702.27 496.07 850.35 496.07 850.35 444.61 880.23 444.46 880.23 411.21 850.95 411.21 851 412.74 850.31 412.74 806.83 412.75 806.77 413.55 702.27 413.55 702.27 496.07"
          />
          <polygon
            id="B2F5H11"
            class="cls-2"
            points="702.39 410.29 850.33 410.29 850.12 359.16 880.23 358.97 880.23 325.72 865.2 325.72 865.18 327.34 864.41 327.41 806.65 327.41 806.65 328.45 702.39 328.45 702.39 410.29"
          />
          <polygon
            id="B2F5H12"
            class="cls-2"
            points="702.17 324.83 850.16 324.62 850.16 271.88 852.04 271.85 852.22 241.85 806.86 241.85 806.86 243.04 705.42 243.04 705.42 287.52 702.17 287.52 702.17 324.83"
          />
          <polygon
            id="B2F5H13"
            class="cls-2"
            points="701.74 272.49 701.74 239.64 858.99 239.22 858.83 152.75 804.04 152.9 804.04 166.87 668.73 166.87 668.73 151.04 616.55 151.04 617.16 272.49 701.74 272.49"
          />
          <polygon
            id="B2F5H20"
            class="cls-2"
            points="347.89 299.94 347.73 409.07 345.4 408.98 345.57 427.83 345.57 447.61 391.59 447.61 391.79 436.35 390 436.35 390 427.05 446.97 426.95 446.97 409.05 488.57 408.62 488.57 320.91 444.36 320.91 444.36 299.74 408.06 299.56 347.89 299.94"
          />
          <polygon
            id="B2F5H21"
            class="cls-2"
            points="492.32 299.38 492.32 393.66 491.28 393.66 491.28 409.07 489.69 409.07 489.69 424.89 526.67 424.89 526.67 442.34 564.5 442.34 564.5 440.43 671.97 440.43 671.97 392.83 660.02 392.83 660.02 356.75 622.37 356.75 622.37 315.6 609.73 315.6 609.73 299.54 492.32 299.38"
          />
          <polygon
            id="B2F5H22"
            class="cls-2"
            points="675.61 444.67 564.89 444.67 564.89 442.34 527.06 442.34 527.06 488.05 547.6 488.05 547.6 540.16 675.61 540.16 675.61 444.67"
          />
          <polygon
            id="B2F5H23"
            class="cls-2"
            points="675.49 544.2 567.41 544.2 564.78 544.2 564.78 543.21 538 543.21 538 541.41 526.95 541.41 526.95 596.46 538.07 596.46 538.07 595.23 547.49 595.23 547.49 665.17 668.28 665.17 668.28 646.35 672.24 646.35 675.49 646.35 675.49 544.2"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-2-level-5-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-2-level-5-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building2Level5MiniMap from './Building2Level5MiniMap.vue';

export default {
  name: 'Building2Level5',
  components: {
    FloorPlanFloorPlate,
    Building2Level5MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
