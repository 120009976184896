<template>
  <div class="relative flex-1 bg-neutral">
    <image-slider :images="images"></image-slider>
  </div>
</template>

<script>
import ImageSlider from '@/components/ImageSlider.vue';

export default {
  name: 'Building',
  components: {
    ImageSlider
  },
  data() {
    return {
      images: [
        'images/building/VIK-001-Aerial-2.jpg',
        'images/building/VIK-002-courtyard_final.jpg',
        'images/building/VIK-003-hero.jpg',
        'images/building/VIK-004-Unit-L2_ensuite.jpg',
        'images/building/VIK-005-unit-C5.jpg',
        'images/building/VIK-006-unit-C5_pano.jpg',
        'images/building/VIK-007-Unit-P_dark.jpg',
        'images/building/VIK-008-Unit-P_light.jpg',
        'images/building/VIK-009-IndoorAmenity_6k.jpg'
      ]
    };
  }
};
</script>
