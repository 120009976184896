<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-1/level-3/B1L3-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B1F3H01"
            class="cls-2"
            points="474.6 256.89 474.6 200.5 601.03 200.5 601.03 257.38 610.59 257.38 610.59 259.73 611.95 259.73 611.95 310.79 474.6 310.79 474.6 256.89"
          />
          <polygon
            id="B1F3H02"
            class="cls-2"
            points="474.6 155.22 474.6 138.04 463.28 138.04 463.28 32.46 519.83 32.46 598.56 32.46 598.43 83.11 598.43 164.1 598.43 197.39 552.11 197.58 551.93 155.22 474.6 155.22 474.6 155.22"
          />
          <polygon
            id="B1F3H03"
            class="cls-2"
            points="448.29 156.13 302.18 156.13 302.18 20.79 367 20.79 367 32.47 459.74 32.46 459.74 135.36 448.29 135.36 448.29 156.13"
          />
          <polygon
            id="B1F3H04"
            class="cls-2"
            points="277.21 165.91 302.18 165.91 302.18 159.74 448.29 159.74 448.29 180.15 435.68 180.15 435.68 223.58 302.18 223.58 302.18 215.53 277.18 215.45 277.21 165.91"
          />
          <polygon
            id="B1F3H05"
            class="cls-2"
            points="448.29 249.58 448.29 227.04 302.18 227.04 302.18 279.26 300.32 279.26 300.32 308.46 448.29 307.98 448.29 249.58"
          />
          <polygon
            id="B1F3H06"
            class="cls-2"
            points="448.36 333.52 448.36 311.05 302.18 311.05 302.18 363.22 302.18 388.28 347.68 388.28 347.68 390.98 444.3 390.98 444.3 367.35 448.36 367.35 448.36 333.52"
          />
          <polygon
            id="B1F3H07"
            class="cls-2"
            points="448.29 437.89 448.29 408.93 444.26 408.93 444.26 397.71 302.18 397.71 302.18 489.07 330.02 489.07 332.64 489.07 332.64 491.98 448.31 491.98 448.29 437.89"
          />
          <polygon
            id="B1F3H08"
            class="cls-2"
            points="448.31 495.54 302.18 495.54 302.18 547.99 300.67 547.99 300.67 577.39 448.31 577.39 448.31 495.54"
          />
          <polygon
            id="B1F3H09"
            class="cls-2"
            points="448.31 579.92 302.18 579.92 302.18 632.35 302.14 661.73 448.31 661.73 448.31 579.92"
          />
          <polygon
            id="B1F3H10"
            class="cls-2"
            points="448.31 664.54 302.18 664.54 302.18 716.66 302.14 746.55 448.31 746.55 448.31 664.54"
          />
          <polygon
            id="B1F3H11"
            class="cls-2"
            points="448.31 748.34 302.18 748.34 302.18 801 300.77 801 300.77 830.87 448.71 830.87 448.31 748.34"
          />
          <polygon
            id="B1F3H12"
            class="cls-2"
            points="466.96 800.53 448.56 800.53 448.56 833.26 293.6 833.26 293.6 918.55 292.02 918.55 292.02 948.21 344.34 948.21 344.34 946.64 466.96 946.64 466.96 800.53"
          />
          <rect id="B1F3H13" class="cls-2" x="470.57" y="800.53" width="63.98" height="146.13" />
          <rect id="B1F3H14" class="cls-2" x="538.01" y="800.63" width="64.07" height="146.03" />
          <polygon
            id="B1F3H15"
            class="cls-2"
            points="605.85 800.63 686.18 800.63 686.18 946.64 634.08 946.64 634.08 948.3 604.67 948.3 604.42 903.7 605.03 903.7 605.85 800.63"
          />
          <polygon
            id="B1F3H16"
            class="cls-2"
            points="689.91 800.15 770.75 800.15 770.75 946.64 718.44 946.64 718.44 948.25 688.7 948.25 688.7 903.7 689.91 903.7 689.91 800.15"
          />
          <polygon
            id="B1F3H17"
            class="cls-2"
            points="774.13 800.36 854.85 800.36 854.85 946.64 802.75 946.64 802.75 948.17 773.1 948.17 773.1 903.7 774.13 903.7 774.13 800.36"
          />
          <polygon
            id="B1F3H18"
            class="cls-2"
            points="833.91 781.32 876.17 781.32 876.17 789.12 990.45 789.12 990.45 881.64 1004.07 881.64 1004.07 946.64 1002.16 946.64 1002.16 948.14 973.25 948.14 973.25 946.64 857.72 946.64 857.72 800.15 833.91 800.15 833.91 781.32"
          />
          <polygon
            id="B1F3H19"
            class="cls-2"
            points="815.77 773.28 815.77 648.37 931.55 648.37 931.55 646.58 991.69 646.58 992.04 672.77 990.44 673.09 990.44 785.84 879.87 785.51 879.87 777.56 847.17 777.56 847.17 773.42 815.77 773.28"
          />
          <polygon
            id="B1F3H20"
            class="cls-2"
            points="745.9 772.86 745.9 692.51 702.8 692.51 702.8 647.92 791.35 647.92 791.35 646.63 813.95 646.63 813.95 773.13 745.9 772.86"
          />
          <polygon
            id="B1F3H21"
            class="cls-2"
            points="639.19 773.28 639.19 757.47 588.92 757.47 588.92 770.1 527.54 770.1 527.54 728.42 607.96 728.42 607.96 679.99 657.23 679.99 657.23 678.64 700.21 678.64 700.21 773.28 639.19 773.28"
          />
          <polygon
            id="B1F3H22"
            class="cls-2"
            points="474.55 716.45 474.55 634.55 583.83 634.55 583.83 633.12 613.49 633.12 613.49 679.99 604.4 679.99 604.4 724.81 527.54 724.81 527.54 716.27 474.55 716.45"
          />
          <polygon
            id="B1F3H23"
            class="cls-2"
            points="474.6 587.25 474.6 571.04 490.41 571.04 490.41 526.67 601.03 526.67 601.03 588.45 613.49 588.45 613.49 632.35 583.7 632.35 583.7 630.94 474.6 630.94 474.6 587.25"
          />
          <rect id="B1F3H24" class="cls-2" x="474.59" y="460.76" width="123.83" height="62.04" />
          <polygon
            id="B1F3H25"
            class="cls-2"
            points="474.6 457.54 598.43 457.54 598.43 397.31 481.83 397.31 481.83 420.55 474.6 420.55 474.6 457.54"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-1-level-3-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-1-level-3-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building1Level3MiniMap from './Building1Level3MiniMap.vue';

export default {
  name: 'Building1Level3',
  components: {
    FloorPlanFloorPlate,
    Building1Level3MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
