<template>
  <div v-if="$route.fullPath == '/'" class="home bg-primary w-full h-full relative">
    <img
      class="home_logo absolute z-10"
      src="./../assets/images/logo-viktor-gray.svg"
      alt="Viktor logo in gray colour"
    />
    <div class="home_button absolute z-10">
      <router-link to="/hero">
        <div class="btn">Tap to begin</div>
      </router-link>
    </div>
    <img
      class="home_figure absolute bottom-0"
      src="./../assets/images/landingpage/person-cat-and-elements.png"
      alt="Viktor - person and cat image"
    />
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.home {
  @apply fixed w-full h-full z-20;
  width: 1920px;
  height: 1080px;
  &_logo {
    width: 34.11vw; //655px
    margin-left: 7.8125vw; //150px
    top: 50%;
    transform: translateY(-50%);
  }

  &_figure {
    left: 50vw;
    width: 50vw; //960px
  }

  &_button {
    left: 13.28vw; //255px
    bottom: 11.97vw; //230px
  }
}
</style>
