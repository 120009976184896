<template>
  <div class="relative flex-1 bg-neutral">
    <image-slider :images="images"></image-slider>
  </div>
</template>

<script>
import ImageSlider from '@/components/ImageSlider.vue';

export default {
  name: 'Gallery',
  components: {
    ImageSlider
  },
  data() {
    return {
      images: [
        'images/gallery/2133189.jpg',
        'images/gallery/2133201.jpg',
        'images/gallery/2133220.jpg',
        'images/gallery/2133292.jpg',
        'images/gallery/2133305.jpg',
        'images/gallery/2133351.jpg',
        'images/gallery/2133358.jpg',
        'images/gallery/2133397.jpg',
        'images/gallery/2133406.jpg',
        'images/gallery/2133447.jpg',
        'images/gallery/2133531.jpg',
        'images/gallery/2133558.jpg'
      ]
    };
  }
};
</script>
