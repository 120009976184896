<template>
  <div class="relative">
    <mini-map
      ref="miniMap"
      :current-selected-plan="currentSelectedPlan"
      @planSelected="planSelected"
      :plans="plans"
    >
      <template v-slot:keyplate>
        <img id="keyplate" src="@/assets/images/floor-plate/building-1/level-1/B1L1-keyplate.svg" />
      </template>
      <template v-slot:highlights>
        <svg id="highlights" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 376.42">
          <g class="cls-1">
            <g id="Keyplate_Highlights" data-name="Keyplate Highlight">
              <polygon
                id="B1F1H01"
                class="cls-2"
                points="76.61 79.24 127.39 79.24 127.39 98.71 119.75 98.71 119.75 116.33 76.61 116.33 76.61 79.24"
              />
              <polygon
                id="B1F1H02"
                class="cls-2"
                points="76.61 52.81 107.49 52.81 107.49 70.17 127.39 70.17 127.39 22.93 117.35 22.93 117.35 4.93 94.43 4.93 94.43 2.34 70.55 2.34 70.55 37.08 75.65 37.08 75.65 44.69 76.6 44.69 76.61 52.81"
              />
              <polyline
                id="B1F1H03"
                class="cls-2"
                points="32.03 9.03 32.03 2.34 70.55 2.34 70.55 44.69 64.51 44.69 64.51 53.28 5.6 53.28 5.6 9.03 32.03 9.03"
              />
              <polygon
                id="B1F1H04"
                class="cls-2"
                points="5.6 80.83 59.41 80.83 59.41 62.84 64.51 62.84 64.51 53.28 5.6 53.28 5.6 80.83"
              />
              <polygon
                id="B1F1H05"
                class="cls-2"
                points="5.6 102.8 16.75 102.8 16.75 114.9 64.51 114.9 64.51 80.83 5.6 80.83 5.6 102.8"
              />
              <path
                id="B1F1H08"
                class="cls-2"
                d="M5.6,188.45H64.51v36.3H16.75V212.19H5.64S5.87,188.19,5.6,188.45Z"
              />
              <polygon
                id="B1F1H09"
                class="cls-2"
                points="5.64 246.7 16.84 246.7 16.84 259.13 64.51 259.13 64.51 224.75 5.64 224.75 5.64 246.7"
              />
              <polygon
                id="B1F1H10"
                class="cls-2"
                points="64.51 293.52 16.75 293.52 16.75 281.22 5.6 281.22 5.6 259.13 64.51 259.13 64.51 293.52"
              />
              <polygon
                id="B1F1H11"
                class="cls-2"
                points="64.51 327.9 16.75 327.9 16.75 315.49 5.6 315.49 5.6 293.2 16.75 293.2 64.51 293.2 64.51 327.9"
              />
              <polygon
                id="B1F1H12"
                class="cls-2"
                points="73.58 315.96 73.58 374.87 23.11 374.87 23.11 363.4 2.18 363.4 2.18 327.58 64.51 327.58 64.51 315.96 73.58 315.96"
              />
              <rect id="B1F1H13" class="cls-2" x="73.58" y="315.96" width="27.38" height="58.9" />
              <rect id="B1F1H14" class="cls-2" x="101.2" y="315.96" width="27.38" height="58.9" />
              <path
                id="B1F1H15"
                class="cls-2"
                d="M162.89,316v58.91h-22V363.72H128.58V316S162.89,315.51,162.89,316Z"
              />
              <polygon
                id="B1F1H16"
                class="cls-2"
                points="197.28 315.96 197.28 374.87 175.31 374.87 175.31 363.72 163.21 363.72 163.21 315.96 197.28 315.96"
              />
              <polygon
                id="B1F1H17"
                class="cls-2"
                points="231.66 315.96 231.66 374.87 209.69 374.87 209.69 363.72 197.59 363.72 197.59 315.96 231.66 315.96"
              />
              <polygon
                id="B1F1H18"
                class="cls-2"
                points="222.91 306.73 240.26 306.73 240.26 309.92 286.11 309.92 286.11 348.44 279.1 348.44 279.1 374.87 231.66 374.87 231.66 315.96 222.91 315.96 222.91 306.73"
              />
              <polygon
                id="B1F1H19"
                class="cls-2"
                points="214.47 303.55 214.47 253.24 261.93 253.24 261.93 263.27 286.11 263.27 286.11 309.92 240.26 309.92 240.26 306.73 227.37 306.73 227.37 303.55 214.47 303.55"
              />
              <polygon
                id="B1F1H20"
                class="cls-2"
                points="167.98 253.5 204.89 253.5 204.89 267.41 214.47 267.41 214.47 303.55 185.97 303.55 185.97 267.49 167.82 267.49 167.98 253.5"
              />
            </g>
          </g>
        </svg>
      </template>
    </mini-map>
  </div>
</template>

<script>
import MiniMap from '../MiniMap.vue';

export default {
  name: 'Building1Level1MiniMap',
  props: ['currentSelectedPlan', 'plans'],
  emits: ['planSelected'],
  components: {
    MiniMap
  },
  methods: {
    planSelected: function(event) {
      this.$emit('planSelected', event);
    },
    highlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>
