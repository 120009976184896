<template>
  <div class="relative">
    <mini-map
      ref="miniMap"
      :current-selected-plan="currentSelectedPlan"
      @planSelected="planSelected"
      :plans="plans"
    >
      <template v-slot:keyplate>
        <img id="keyplate" src="@/assets/images/floor-plate/building-2/level-1/B2L1-keyplate.svg" />
      </template>
      <template v-slot:highlights>
        <svg id="highlights" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 376.42">
          <g class="cls-1">
            <g id="Keyplate_Highlights" data-name="Keyplate Highlight">
              <polygon
                id="B2F1H01"
                class="cls-2"
                points="169.78 253.69 220.84 253.69 220.84 297.89 181.65 297.89 181.65 276.25 169.78 276.25 169.78 253.69"
              />
              <polygon
                id="B2F1H03"
                class="cls-2"
                points="169.76 306.33 180.98 306.33 180.98 307.03 189.93 307.03 189.93 324.21 220.84 324.21 220.84 332.35 221.8 332.35 221.8 339.53 226.74 339.53 226.74 374.79 202.97 374.79 202.97 372.48 179.99 372.48 179.99 354.43 169.76 354.43 169.76 306.33"
              />
              <polygon
                id="B2F1H04"
                class="cls-2"
                points="232.8 323.73 291.84 323.73 291.84 368.09 265.35 368.09 265.35 374.79 226.74 374.79 226.74 332.35 232.8 332.35 232.8 323.73"
              />
              <polygon
                id="B2F1H05"
                class="cls-2"
                points="232.8 294.69 291.84 294.69 291.84 323.73 238.07 323.73 238.07 309.05 232.8 309.05 232.8 294.69"
              />
              <polygon
                id="B2F1H06"
                class="cls-2"
                points="252.59 254.17 282.58 254.17 282.58 271.88 291.85 271.88 291.85 294.69 232.8 294.69 232.8 265.49 252.59 265.49 252.59 254.17"
              />
              <polygon
                id="B2F1H08"
                class="cls-2"
                points="232.8 186.35 280.67 186.35 280.67 198.66 291.84 198.66 291.84 221.14 274.66 221.14 274.24 220.71 234.56 220.71 234.56 212.74 232.8 212.74 232.8 186.35"
              />
              <polygon
                id="B2F1H09"
                class="cls-2"
                points="232.8 151.89 280.63 151.89 280.63 164.34 291.84 164.34 291.84 186.67 280.67 186.67 232.8 186.67 232.8 151.89"
              />
              <polygon
                id="B2F1H10"
                class="cls-2"
                points="232.8 117.42 280.67 117.42 280.67 129.87 291.85 129.87 291.85 152.21 280.72 152.21 232.8 152.21 232.8 117.42"
              />
              <polygon
                id="B2F1H11"
                class="cls-2"
                points="232.8 82.96 280.67 82.96 280.67 95.41 291.84 95.41 291.84 117.58 232.8 117.58 232.8 82.96"
              />
              <polygon
                id="B2F1H12"
                class="cls-2"
                points="232.8 48.5 280.67 48.5 280.67 60.77 291.84 60.77 291.84 83.28 232.8 83.28 232.8 48.5"
              />
              <polygon
                id="B2F1H13"
                class="cls-2"
                points="223.87 60.46 223.87 1.43 274.22 1.43 274.22 12.91 295.06 12.91 295.06 48.81 232.8 48.81 232.8 60.46 223.87 60.46"
              />
              <polygon
                id="B2F1H14"
                class="cls-2"
                points="174.88 60.46 174.88 1.43 202.75 1.43 202.75 12.6 223.55 12.6 223.55 60.46 174.88 60.46"
              />
              <polygon
                id="B2F1H15"
                class="cls-2"
                points="140.1 1.43 162.44 1.43 162.44 12.6 174.56 12.6 174.56 60.46 140.42 60.46 140.1 1.43"
              />
              <polygon
                id="B2F1H16"
                class="cls-2"
                points="105.64 1.5 105.64 60.46 140.42 60.46 140.42 12.7 127.91 12.7 127.91 1.5 105.64 1.5"
              />
              <polygon
                id="B2F1H17"
                class="cls-2"
                points="71.65 1.43 93.61 1.43 93.61 12.6 105.96 12.6 105.96 60.46 71.49 60.46 71.65 1.43"
              />
              <polygon
                id="B2F1H18"
                class="cls-2"
                points="63.67 66.53 17.08 66.53 17.08 27.91 24.1 27.91 24.1 1.43 71.65 1.43 71.65 60.46 63.51 60.46 63.67 66.53"
              />
              <polygon
                id="B2F1H19"
                class="cls-2"
                points="63.67 72.59 71.49 72.59 71.49 105.29 88.72 105.29 88.72 123.01 41.17 123.01 41.17 113.28 17.08 113.28 17.08 66.53 63.67 66.53 63.67 72.59"
              />
              <polygon
                id="B2F1H20"
                class="cls-2"
                points="97.76 72.69 123.66 72.69 123.66 79.93 137.55 79.93 137.55 123.49 109.38 123.49 109.38 115.97 98.02 115.97 97.76 72.69"
              />
            </g>
          </g>
        </svg>
      </template>
    </mini-map>
  </div>
</template>

<script>
import MiniMap from '../MiniMap.vue';

export default {
  name: 'Building2Level1MiniMap',
  props: ['currentSelectedPlan', 'plans'],
  emits: ['planSelected'],
  components: {
    MiniMap
  },
  methods: {
    planSelected: function(event) {
      this.$emit('planSelected', event);
    },
    highlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>
