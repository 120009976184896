<template>
  <div class="relative">
    <mini-map
      ref="miniMap"
      :current-selected-plan="currentSelectedPlan"
      @planSelected="planSelected"
      :plans="plans"
    >
      <template v-slot:keyplate>
        <img id="keyplate" src="@/assets/images/floor-plate/building-2/level-4/B2L4-keyplate.svg" />
      </template>
      <template v-slot:highlights>
        <svg id="highlights" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.87 390.74">
          <g class="cls-1">
            <g id="Keyplate_Highlights" data-name="Keyplate Highlight">
              <rect id="B2F4H01" class="cls-2" x="164" y="260.25" width="51.06" height="26.34" />
              <rect id="B2F4H02" class="cls-2" x="164" y="286.59" width="51.06" height="26.34" />
              <polygon
                id="B2F4H03"
                class="cls-2"
                points="163.97 312.89 175.19 312.89 175.19 313.6 184.15 313.6 184.15 330.78 215.06 330.78 215.06 338.92 216.01 338.92 216.01 346.1 220.96 346.1 220.96 381.36 157.58 381.03 157.58 360.37 163.97 360.99 163.97 312.89"
              />
              <polygon
                id="B2F4H04"
                class="cls-2"
                points="227.44 330.22 286.48 330.22 286.48 374.57 286.41 387.65 238.74 387.5 238.74 381.12 221.38 381.28 221.38 338.83 227.44 338.83 227.44 330.22"
              />
              <polygon
                id="B2F4H05"
                class="cls-2"
                points="227.44 301.18 286.48 301.18 286.48 306.45 296.62 306.45 296.62 326.71 286.48 326.71 286.48 330.22 232.71 330.22 232.71 315.54 227.44 315.54 227.44 301.18"
              />
              <polygon
                id="B2F4H06"
                class="cls-2"
                points="247.23 260.65 274.83 260.26 286.41 260.08 286.49 278.36 286.49 301.18 227.44 301.18 227.44 271.98 227.82 260.08 247.23 260.65"
              />
              <polygon
                id="B2F4H07"
                class="cls-2"
                points="229.13 226.67 268.77 227.1 286.41 227.1 286.21 238.2 286.21 260.6 227.4 260.17 227.4 237.6 229 238.04 229.13 226.67"
              />
              <polygon
                id="B2F4H08"
                class="cls-2"
                points="227.38 192.31 275.24 192.31 286.41 192.63 286.41 204.62 286.41 227.1 269.23 227.1 268.81 226.67 229.13 226.67 229.13 218.7 227.38 218.7 227.38 192.31"
              />
              <polygon
                id="B2F4H09"
                class="cls-2"
                points="227.38 157.85 275.2 157.85 286.42 158.17 286.41 170.3 286.41 192.63 275.24 192.63 227.38 192.63 227.38 157.85"
              />
              <polygon
                id="B2F4H10"
                class="cls-2"
                points="227.38 123.39 275.24 123.39 286.41 123.54 286.42 135.83 286.42 158.17 275.29 158.17 227.38 158.17 227.38 123.39"
              />
              <polygon
                id="B2F4H11"
                class="cls-2"
                points="227.38 88.92 275.24 88.92 286.41 89.24 286.41 101.37 286.41 123.54 227.38 123.54 227.38 88.92"
              />
              <polygon
                id="B2F4H12"
                class="cls-2"
                points="227.38 54.45 275.24 54.45 286.34 54.83 286.41 66.73 286.41 89.24 227.38 89.24 227.38 54.45"
              />
              <polygon
                id="B2F4H13"
                class="cls-2"
                points="218.44 66.42 218.44 7.38 268.79 7.38 289.85 7.5 289.63 18.87 289.63 54.77 227.38 54.77 227.38 66.42 218.44 66.42"
              />
              <polygon
                id="B2F4H14"
                class="cls-2"
                points="169.45 66.42 169.45 7.38 197.32 7.38 197.32 1.71 217.9 1.71 218.12 18.55 218.12 66.42 169.45 66.42"
              />
              <polygon
                id="B2F4H15"
                class="cls-2"
                points="134.67 7.38 157.01 7.38 169.13 7.38 169.13 66.42 134.99 66.42 134.67 7.38"
              />
              <polygon
                id="B2F4H16"
                class="cls-2"
                points="100.21 7.46 100.21 66.42 134.99 66.42 134.99 7.38 122.48 7.46 100.21 7.46"
              />
              <polygon
                id="B2F4H17"
                class="cls-2"
                points="66.22 7.38 88.19 7.38 100.53 7.46 100.53 66.42 66.06 66.42 66.22 7.38"
              />
              <polygon
                id="B2F4H18"
                class="cls-2"
                points="58.24 72.49 11.65 72.49 11.65 33.87 6.69 33.93 6.69 7.45 18.67 7.38 66.22 7.38 66.22 66.42 58.08 66.42 58.24 72.49"
              />
              <polygon
                id="B2F4H19"
                class="cls-2"
                points="58.24 78.55 66.06 78.55 66.06 109.18 83.3 109.18 83.3 128.97 35.75 128.97 11.47 129.49 11.65 119.24 11.65 72.49 58.24 72.49 58.24 78.55"
              />
              <polygon
                id="B2F4H20"
                class="cls-2"
                points="82.8 78.66 123.46 78.66 123.46 85.93 141.33 85.94 141.65 122.15 123.78 122.15 123.78 129.49 100.81 129.49 100.73 137.12 83.58 137.14 83.07 121.93 82.8 78.66"
              />
              <polygon
                id="B2F4H21"
                class="cls-2"
                points="210.25 100.61 195.16 100.61 195.16 83.86 189.99 83.86 189.99 78.6 141.33 78.6 141.33 127.7 156.64 127.7 156.64 135.55 215.19 135.55 215.19 115.29 210.25 115.29 210.25 100.61"
              />
              <polygon
                id="B2F4H22"
                class="cls-2"
                points="156.63 153.15 164.3 153.15 164.3 176.07 215.19 176.07 215.19 135.55 156.64 135.55 156.63 153.15"
              />
              <polygon
                id="B2F4H23"
                class="cls-2"
                points="212.8 226.16 164.3 226.16 164.3 197.13 156.63 197.13 156.63 175.99 171.16 175.99 215.19 175.99 215.19 218.5 212.81 218.5 212.8 226.16"
              />
            </g>
          </g>
        </svg>
      </template>
    </mini-map>
  </div>
</template>

<script>
import MiniMap from '../MiniMap.vue';

export default {
  name: 'Building2Level4MiniMap',
  props: ['currentSelectedPlan', 'plans'],
  emits: ['planSelected'],
  components: {
    MiniMap
  },
  methods: {
    planSelected: function(event) {
      this.$emit('planSelected', event);
    },
    highlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>
