<template>
  <button
    v-if="!isFullScreen"
    v-on:click="toggleFullScreen()"
    class="btn btn--sm
  top-5 left-5 absolute z-50"
  >
    Full Screen
  </button>
</template>

<script>
export default {
  name: 'FullScreenButton',
  data() {
    return {
      isFullScreen: !!document.fullscreenElement
    };
  },
  methods: {
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      document.documentElement.requestFullscreen();
    }
  },
  mounted() {
    document.onfullscreenchange = () => {
      this.isFullScreen = !!document.fullscreenElement;
    };
  }
};
</script>
