<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-2/level-1/B2L1-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B2F1H01"
            class="cls-2"
            points="676.75 737.29 549.02 737.29 549.02 793.07 577.3 793.07 577.3 844.79 676.7 844.79 676.75 737.29"
          />
          <polygon
            id="B2F1H03"
            class="cls-2"
            points="676.7 911.48 596.78 911.48 596.78 868.9 548.97 868.9 548.97 903.7 551.64 903.7 551.64 984.18 574.12 984.18 574.12 1028.94 640.64 1028.94 640.64 1034.72 688.13 1034.72 688.13 928.3 676.7 928.3 676.7 911.48"
          />
          <polygon
            id="B2F1H04"
            class="cls-2"
            points="703.27 910.32 850.87 910.32 850.87 1018.3 785.34 1018.3 785.34 1034.86 691.7 1034.86 691.77 928.13 703.29 928.13 703.27 910.32"
          />
          <polygon
            id="B2F1H05"
            class="cls-2"
            points="703.27 838.99 850.82 838.99 850.82 906.91 719.24 906.91 719.24 873.46 703.27 873.46 703.27 838.99"
          />
          <polygon
            id="B2F1H06"
            class="cls-2"
            points="703.27 766.27 755.41 766.27 755.41 738.71 827.95 738.71 827.95 780.55 850.87 780.55 850.87 835.09 703.27 835.09 703.27 766.27"
          />
          <polygon
            id="B2F1H08"
            class="cls-2"
            points="703.25 635.84 710.72 635.84 710.72 650.7 850.82 650.7 850.82 599.83 823.12 599.83 823.12 570.4 703.24 570.4 703.25 635.84"
          />
          <polygon
            id="B2F1H09"
            class="cls-2"
            points="703.25 567.52 850.82 567.52 850.82 517.01 823.24 517.01 823.24 485.78 703.27 485.78 703.25 567.52"
          />
          <polygon
            id="B2F1H10"
            class="cls-2"
            points="703.27 482.35 850.82 482.35 850.82 429.5 823.12 429.5 823.12 400.58 703.27 400.58 703.27 482.35"
          />
          <polygon
            id="B2F1H11"
            class="cls-2"
            points="703.27 397.18 850.82 397.18 850.82 344.3 823.24 344.3 823.24 315.44 703.27 315.44 703.27 397.18"
          />
          <polygon
            id="B2F1H12"
            class="cls-2"
            points="703.27 312.01 850.82 312.01 850.82 259.13 823.2 259.13 823.2 230.27 706.51 230.27 706.51 272.33 703.27 272.33 703.27 312.01"
          />
          <polygon
            id="B2F1H13"
            class="cls-2"
            points="703.27 259.63 703.27 226.58 859.48 226.58 859.48 140.44 807.44 140.44 807.44 112.09 684.41 112.09 684.41 259.63 703.27 259.63"
          />
          <polygon
            id="B2F1H14"
            class="cls-2"
            points="680.77 259.52 680.77 139.95 629.95 139.95 629.95 112.08 563.64 112.08 563.64 259.52 680.77 259.52"
          />
          <polygon
            id="B2F1H15"
            class="cls-2"
            points="559.54 259.65 559.54 139.53 530.98 139.53 530.98 112.08 478.19 112.08 478.19 259.68 559.54 259.65"
          />
          <polygon
            id="B2F1H16"
            class="cls-2"
            points="474.38 259.59 474.38 139.48 445.83 139.48 445.83 112.08 393.04 112.08 393.04 259.68 474.38 259.59"
          />
          <polygon
            id="B2F1H17"
            class="cls-2"
            points="389.61 259.59 389.61 139.53 360.72 139.53 360.72 112.08 307.87 112.08 307.87 259.59 389.61 259.59"
          />
          <polygon
            id="B2F1H18"
            class="cls-2"
            points="304.91 259.38 304.91 112.08 188.87 112.08 188.87 177.59 198.31 177.59 198.31 184.3 171.51 184.3 171.51 271.18 283.48 271.18 283.48 259.59 304.91 259.38"
          />
          <polygon
            id="B2F1H19"
            class="cls-2"
            points="286.75 274.82 171.51 274.82 171.51 388.55 230.98 388.55 230.98 413.77 346.93 413.77 346.93 369.97 304.82 369.97 304.82 286.6 286.61 286.6 286.75 274.82"
          />
          <polygon
            id="B2F1H20"
            class="cls-2"
            points="372.76 286.77 372.76 395.81 399.44 395.81 399.44 411.14 392.28 411.14 392.28 413.77 467.75 413.77 467.75 307.89 432.94 307.89 432.94 286.39 372.76 286.77"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-2-level-1-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-2-level-1-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building2Level1MiniMap from './Building2Level1MiniMap.vue';

export default {
  name: 'Building2Level1',
  components: {
    FloorPlanFloorPlate,
    Building2Level1MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
