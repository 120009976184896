<template>
  <div :class="{ 'is-plan-view': currentSelectedPlan }">
    <slot name="highlights"></slot>
    <slot name="keyplate"></slot>
  </div>
</template>

<script>
import gsap from 'gsap';
export default {
  name: 'FloorPlan',
  props: ['currentSelectedPlan', 'plans'],
  emits: ['planSelected'],
  mounted() {
    this.getHighlightsSvg().addEventListener('click', this.selectPlan);
    if (this.currentSelectedPlan) {
      const currentlySelectedClassName = 'currently-selected-home';
      // clean up selected class
      _.forEach(
        [...this.getHighlightsSvg().querySelectorAll('#Keyplate_Highlights > *')],
        element => {
          element.classList.remove(currentlySelectedClassName);
          gsap.set(element, { opacity: 0 });
        }
      );

      // figure out homes that need selected class
      const currentPlan = _.first(
        _.filter(this.plans, (value, key) => key == this.currentSelectedPlan)
      );
      let allHomesWithHomeText = _.map(this.plans, (value, key) => {
        value.key = key;
        return value;
      });
      allHomesWithHomeText = _.filter(allHomesWithHomeText, (value, key) => {
        return value.homeText == currentPlan.homeText;
      });
      const homes = _.map(allHomesWithHomeText, value => {
        return this.getHighlightsSvg().getElementById(value.key);
      });

      // add selected class to elements
      _.forEach(homes, element => {
        if (element) {
          element.classList.add(currentlySelectedClassName);
        }
      });
    } else {
      this.resetHightedPlans();
    }
  },
  methods: {
    getHighlightsSvg: () => _.first(document.querySelectorAll('#highlights')),
    selectPlan(event) {
      const selectedPlanName = event.target.id;

      if (!selectedPlanName || selectedPlanName == 'highlights') {
        return;
      }

      this.$emit('planSelected', event);
    },
    getAllHomeElements: function() {
      return [...this.getHighlightsSvg().querySelectorAll('#Keyplate_Highlights > *')];
    },
    resetHightedPlans: function(allPlans = this.getAllHomeElements()) {
      gsap.set(allPlans, { opacity: 0 });
    },
    highlightPlans(planKeys) {
      const allPlans = this.getAllHomeElements();
      const planElements = _.map(planKeys, planKey =>
        this.getHighlightsSvg().getElementById(planKey)
      );
      const unHighlightPlans = allPlans.filter(element => planElements.indexOf(element));
      this.resetHightedPlans(unHighlightPlans);
      gsap.to(planElements, {
        opacity: 1,
        duration: 1
      });
    }
  }
};
</script>

<style lang="scss">
#keyplate {
  @apply absolute w-full h-full top-0;
}
#highlights {
  @apply relative z-10 mix-blend-multiply;
  .cls-1 {
    isolation: isolate;
  }
  .cls-2 {
    @apply cursor-pointer;
    fill: #f9e346;
    opacity: 0;
  }
}
.is-plan-view {
  width: 70%;
  margin-bottom: 4.6875vw;
  #highlights {
    .currently-selected-home {
      fill: #c1c6c7;
      opacity: 1 !important;
    }
  }
  #keyplate {
    @apply w-auto;
  }
}
</style>
