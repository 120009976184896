<template>
  <div class="relative flex-1">
    <div class="w-full h-full relative z-10">
      <transition name="neighbourhood-fade">
        <img
          @click="hideLabel"
          usemap="#map"
          v-if="activeString == 'map'"
          src="@/assets/images/neighbourhood/map.svg"
          alt="Neighbourhood Map"
          class="absolute object-cover w-full h-full"
        />
      </transition>
      <transition name="neighbourhood-fade">
        <img
          v-if="activeString == 'aerial'"
          src="@/assets/images/building/VIK-001-Aerial-2.jpg"
          alt="Viktor Aerial View"
          class="absolute object-cover w-full h-full"
        />
      </transition>
      <div v-if="activeString == 'map'" class="w-full h-full">
        <svg
          v-for="(icon, index) in mappingIcons"
          :key="index"
          :style="icon.styleObject"
          @click="iconClick(icon)"
          class="absolute cursor-pointer"
          width="1vw"
          height="1vw"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2809 19.353C15.4149 19.353 19.5769 15.191 19.5769 10.057C19.5769 4.92295 15.4149 0.760986 10.2809 0.760986C5.14682 0.760986 0.984863 4.92295 0.984863 10.057C0.984863 15.191 5.14682 19.353 10.2809 19.353Z"
            fill="none"
          />
        </svg>
        <span
          v-if="activeString == 'map'"
          class="absolute text-white label transition-colors"
          :class="activeIcon.labelClass"
          >{{ activeIcon.name }}</span
        >
      </div>
    </div>
    <div class="absolute z-10 right-10 bottom-10 flex flex-col space-y-5">
      <button
        @click="activeString = 'map'"
        :class="{ 'bg-primary': activeString == 'map' }"
        class="btn btn--sm"
      >
        Neighbourhood map
      </button>
      <button
        @click="activeString = 'aerial'"
        :class="{ 'bg-primary': activeString == 'aerial' }"
        class="btn btn--sm btn--secondary"
      >
        Modern Redefined
      </button>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'Neighbourhood',
  data() {
    return {
      activeString: 'aerial',
      activeIcon: '',
      mappingIcons: [
        {
          name: 'CHUCK BAILEY REC CENTRE',
          styleObject: {
            left: '17.7vw',
            top: '13.125vw'
          },
          type: 'recreation'
        },

        {
          name: 'SURREY CIVIC HALL AND PLAZA',
          styleObject: {
            left: '17.9vw',
            top: '22.69vw'
          },
          type: 'recreation'
        },
        {
          name: 'SURREY CITY CENTRE LIBRARY',
          styleObject: {
            left: '17.9vw',
            top: '23.74vw'
          },
          type: 'recreation'
        },
        {
          name: 'FITNESS WORLD',
          styleObject: {
            left: '28.4vw',
            top: '23.93vw'
          },
          type: 'recreation'
        },
        {
          name: 'CLUB16 TREVOR LINDEN FITNESS',
          styleObject: {
            left: '17.87vw',
            top: '31.67vw'
          },
          type: 'recreation'
        },
        {
          name: 'GUILDFORD ATHLETIC CLUB',
          styleObject: {
            left: '53.54vw',
            top: '26.05vw'
          },
          type: 'recreation'
        },
        {
          name: 'FORSYTH ROAD ELEMENTARY SCHOOL',
          styleObject: {
            left: '31.63vw',
            top: '12.82vw'
          },
          type: 'education'
        },
        {
          name: 'OUR LADY OF GOOD COUNSEL SCHOOL',
          styleObject: {
            left: '33.17vw',
            top: '18.99vw'
          },
          type: 'education'
        },
        {
          name: 'GUILDFORD PARK SECONDARY SCHOOL',
          styleObject: {
            left: '50.8vw',
            top: '13.19vw'
          },
          type: 'education'
        },
        {
          name: 'LENA SHAW ELEMENTARY SCHOOL',
          styleObject: {
            left: '41.14vw',
            top: '33.67vw'
          },
          type: 'education'
        },
        {
          name: 'KWANTLEN POLYTECHNIC UNIVERSITY',
          styleObject: {
            left: '19.3vw',
            top: '22.70vw'
          },
          type: 'education'
        },
        {
          name: 'SIMON FRASER UNIVERSITY',
          styleObject: {
            left: '17.31vw',
            top: '28.97vw'
          },
          type: 'education'
        },
        {
          name: 'UBC CLINICAL CAMPUS / SURREY MEMORIAL HOSPITAL',
          styleObject: {
            left: '22.84vw',
            top: '47.05vw'
          },
          type: 'education'
        },
        {
          name: 'QUEEN ELIZABETH SECONDARY SCHOOL',
          styleObject: {
            left: '20.48vw',
            top: '50.92vw'
          },
          type: 'education'
        },
        {
          name: 'SIMON CUNNINGHAM ELEMENTARY SCHOOL',
          styleObject: {
            left: '34.525vw',
            top: '53.88vw'
          },
          type: 'education'
        },
        {
          name: 'KWANTLEN PARK SECONDARY SCHOOL',
          styleObject: {
            left: '9.13vw',
            top: '20.47vw'
          },
          type: 'education'
        },
        {
          name: 'KB WOODWARD ELEMENTARY SCHOOL',
          styleObject: {
            left: '7.60vw',
            top: '16.35vw'
          },
          type: 'education'
        },
        {
          name: 'PRADO CAFE',
          styleObject: {
            left: '19.29vw',
            top: '23.74vw'
          },
          type: 'restaurants'
        },
        {
          name: 'STARBUCKS',
          styleObject: {
            left: '22.98vw',
            top: '23.34vw'
          },
          type: 'restaurants'
        },
        {
          name: 'NAHM THAI BISTRO',
          styleObject: {
            left: '23.68vw',
            top: '28.93vw'
          },
          type: 'restaurants'
        },
        {
          name: 'BROWNS SOCIALHOUSE',
          styleObject: {
            left: '22.84vw',
            top: '43.20vw'
          },
          type: 'restaurants'
        },
        {
          name: 'CAPTAIN’S OVEN PIZZA',
          styleObject: {
            left: '25.23vw',
            top: '43.60vw'
          },
          type: 'restaurants'
        },
        {
          name: 'QUESADA BURRITOS AND TACOS',
          styleObject: {
            left: '24.67vw',
            top: '45.12vw'
          },
          type: 'restaurants'
        },
        {
          name: 'LONDON DRUGS',
          styleObject: {
            left: '24vw',
            top: '23.68vw'
          },
          type: 'shopping'
        },
        {
          name: 'SAVE-ON-FOODS',
          styleObject: {
            left: '22.93vw',
            top: '25.11vw'
          },
          type: 'shopping'
        },
        {
          name: 'CANADIAN TIRE',
          styleObject: {
            left: '24.53vw',
            top: '25.59vw'
          },
          type: 'shopping'
        },
        {
          name: 'SHOPPERS DRUG MART',
          styleObject: {
            left: '18.95vw',
            top: '29.07vw'
          },
          type: 'shopping'
        },
        {
          name: 'CENTRAL CITY',
          styleObject: {
            left: '18.95vw',
            top: '30.19vw'
          },
          type: 'shopping'
        },
        {
          name: 'T&T',
          styleObject: {
            left: '18.95vw',
            top: '31.31vw'
          },
          type: 'shopping'
        },
        {
          name: 'BEST BUY',
          styleObject: {
            left: '18.95vw',
            top: '32.43vw'
          },
          type: 'shopping'
        },
        {
          name: 'GREEN TIMBERS URBAN FOREST',
          styleObject: {
            left: '50vw',
            top: '38.99vw'
          },
          type: 'parks'
        },
        {
          name: 'HJORTH ROAD PARK',
          styleObject: {
            left: '56.53vw',
            top: '24.59vw'
          },
          type: 'parks'
        },
        {
          name: 'HAWTHORNE ROTARY PARK',
          styleObject: {
            left: '42.83vw',
            top: '21vw'
          },
          type: 'parks'
        },
        {
          name: 'FORSYTH PARK',
          styleObject: {
            left: '32.59vw',
            top: '14.85vw'
          },
          type: 'parks'
        },
        {
          name: 'HOLLAND PARK',
          styleObject: {
            left: '17.88vw',
            top: '34.13vw'
          },
          type: 'parks'
        },
        {
          name: 'AHP MATTHEW PARK',
          styleObject: {
            left: '11.4vw',
            top: '41vw'
          },
          type: 'parks'
        },
        {
          name: 'ROYAL KWANTLEN PARK / OUTDOOR POOL',
          styleObject: {
            left: '4.71vw',
            top: '20.37vw'
          },
          type: 'parks'
        }
      ]
    };
  },
  methods: {
    iconClick(icon) {
      this.activeIcon = icon;
      const label = document.querySelector('.label'),
        values = icon.styleObject,
        x = parseFloat(values.left),
        y = parseFloat(values.top);
      gsap.set(label, { autoAlpha: 0 });

      if (x < 50) {
        this.activeIcon.labelClass = `label--${icon.type}`;
        label.style.left = `${x + 0.5}vw`;
        label.style.top = `${y}vw`;
        label.style.right = 'auto';
      } else {
        this.activeIcon.labelClass = `label--secondary label--${icon.type}`;
        label.style.left = 'auto';
        label.style.top = `${y}vw`;
        label.style.right = `${88 - x}vw`; //1697px/1920px = 88vw
      }

      gsap.fromTo(label, { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, duration: 0.5 });
    },
    hideLabel() {
      gsap.to('.label', { autoAlpha: 0, y: 20, duration: 0.5 });
    }
  }
};
</script>

<style scoped lang="scss">
.label {
  margin-top: -1.67vw;
  font-size: 0.625vw;
  line-height: 0.625vw;
  padding: 0.57vw 0.94vw 0.47vw;
  border-radius: 0.78125vw 0.78125vw 0.78125vw 0;
  &.label--secondary {
    border-radius: 0.78125vw 0.78125vw 0 0.78125vw;
  }

  &.label--recreation {
    background-color: #34afc8;
    @apply bg-black;
  }

  &.label--education {
    background-color: #34afc8;
  }
  &.label--restaurants {
    background-color: #f9e245;
    @apply text-black;
  }
  &.label--shopping {
    background-color: #c2b764;
  }
  &.label--parks {
    background-color: #606f73;
  }
}

// transition
.neighbourhood-fade-enter {
  opacity: 0;
}
.neighbourhood-fade-enter-active {
  transition: opacity 1.5s ease-out;
  z-index: 10;
}
.neighbourhood-fade-enter-to {
  opacity: 1;
}
.neighbourhood-fade-leave {
  opacity: 1;
}
.neighbourhood-fade-leave-active {
  transition: opacity 1.5s ease-out;
  z-index: 1;
}
.neighbourhood-fade-leave-to {
  opacity: 0;
}
</style>
