import { createRouter, createWebHashHistory } from 'vue-router';

import Hero from '../views/Hero.vue';
import LandingPage from '../views/LandingPage.vue';
import FloorPlans from '../views/FloorPlans.vue';
import Building from '../views/Building.vue';
import Features from '../views/Features.vue';
import Neighbourhood from '../views/Neighbourhood.vue';
import Gallery from '../views/Gallery.vue';

import { generateFloorPlanRoutes } from './FloorPlanData';

const routes = [
  {
    path: '/',
    name: 'Landing Page',
    component: LandingPage
  },
  {
    path: '/hero',
    name: 'Hero',
    component: Hero
  },
  {
    path: '/floor-plans',
    name: 'Floorplans',
    component: FloorPlans,
    children: generateFloorPlanRoutes('/floor-plans')
  },
  {
    path: '/building',
    name: 'Building',
    component: Building
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/neighbourhood',
    name: 'Neighbourhood',
    component: Neighbourhood
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
