<template>
  <div class="relative">
    <mini-map
      ref="miniMap"
      :current-selected-plan="currentSelectedPlan"
      @planSelected="planSelected"
      :plans="plans"
    >
      <template v-slot:keyplate>
        <img id="keyplate" src="@/assets/images/floor-plate/building-2/level-6/B2L6-keyplate.svg" />
      </template>
      <template v-slot:highlights>
        <svg id="highlights" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 237.51 378.97">
          <g class="cls-1">
            <g id="Keyplate_Highlights" data-name="Keyplate Highlight">
              <rect id="B2F6H01" class="cls-2" x="103.25" y="248.2" width="51.06" height="26.34" />
              <rect id="B2F6H02" class="cls-2" x="103.25" y="274.54" width="51.06" height="26.34" />
              <polygon
                id="B2F6H03"
                class="cls-2"
                points="103.23 300.84 114.45 300.84 114.45 301.54 123.4 301.54 123.4 318.73 154.31 318.73 154.31 326.86 155.27 326.86 155.27 334.04 160.21 334.04 160.21 369.3 103.32 368.98 103.23 348.94 103.23 300.84"
              />
              <polygon
                id="B2F6H04"
                class="cls-2"
                points="166.7 318.16 225.74 318.16 225.74 362.52 225.66 375.6 199.27 375.79 199.15 369.29 178 369.06 160.64 369.22 160.64 326.78 166.7 326.78 166.7 318.16"
              />
              <polygon
                id="B2F6H05"
                class="cls-2"
                points="166.7 289.12 225.74 289.12 225.74 294.4 235.87 294.4 235.87 314.66 225.74 314.66 225.74 318.16 171.97 318.16 171.97 303.49 166.7 303.49 166.7 289.12"
              />
              <polygon
                id="B2F6H06"
                class="cls-2"
                points="186.49 248.6 214.09 248.21 225.67 248.03 225.75 266.31 225.75 289.12 166.7 289.12 166.7 259.92 167.07 248.03 186.49 248.6"
              />
              <polygon
                id="B2F6H07"
                class="cls-2"
                points="168.38 214.62 208.03 215.04 225.67 215.04 225.47 226.15 225.47 248.55 166.65 248.11 166.65 225.55 168.26 225.99 168.38 214.62"
              />
              <polygon
                id="B2F6H08"
                class="cls-2"
                points="166.63 180.26 214.5 180.26 225.67 180.58 225.67 192.57 225.67 215.04 208.49 215.04 208.07 214.62 168.38 214.62 168.38 206.64 166.63 206.64 166.63 180.26"
              />
              <polygon
                id="B2F6H09"
                class="cls-2"
                points="166.63 145.19 214.46 145.19 225.68 145.51 225.67 157.64 225.67 179.97 214.5 179.97 166.63 179.97 166.63 145.19"
              />
              <polygon
                id="B2F6H10"
                class="cls-2"
                points="166.63 110.73 214.5 110.73 225.67 110.88 225.68 123.17 225.68 145.51 214.54 145.51 166.63 145.51 166.63 110.73"
              />
              <polygon
                id="B2F6H11"
                class="cls-2"
                points="166.63 76.26 214.5 76.26 225.67 76.58 225.67 88.71 225.67 110.88 166.63 110.88 166.63 76.26"
              />
              <polygon
                id="B2F6H12"
                class="cls-2"
                points="166.63 41.8 214.5 41.8 225.6 42.17 225.67 54.07 225.67 76.58 166.63 76.58 166.63 41.8"
              />
              <polygon
                id="B2F6H13"
                class="cls-2"
                points="130.53 53.76 130.53 5.36 152.46 5.36 152.58 11.73 225.78 11.77 225.78 42.12 166.63 42.12 166.63 53.76 130.53 53.76"
              />
              <polygon
                id="B2F6H20"
                class="cls-2"
                points="22.06 66 62.72 66 62.72 73.27 80.59 73.28 80.9 109.49 63.04 109.49 63.04 116.83 40.07 116.83 39.99 124.46 22.84 124.48 22.33 109.27 22.06 66"
              />
              <polygon
                id="B2F6H21"
                class="cls-2"
                points="149.5 87.96 134.41 87.96 134.41 71.2 129.24 71.2 129.24 65.94 80.58 65.94 80.58 115.05 95.9 115.05 95.9 122.89 154.44 122.89 154.44 102.63 149.5 102.63 149.5 87.96"
              />
              <polygon
                id="B2F6H22"
                class="cls-2"
                points="95.88 140.49 103.56 140.49 103.56 163.41 154.45 163.41 154.45 122.89 95.9 122.89 95.88 140.49"
              />
              <polygon
                id="B2F6H23"
                class="cls-2"
                points="152.06 213.91 103.56 213.91 103.56 184.87 95.88 184.87 95.88 163.73 110.42 163.73 154.45 163.73 154.45 206.25 152.07 206.25 152.06 213.91"
              />
            </g>
          </g>
        </svg>
      </template>
    </mini-map>
  </div>
</template>

<script>
import MiniMap from '../MiniMap.vue';

export default {
  name: 'Building2Level6MiniMap',
  props: ['currentSelectedPlan', 'plans'],
  emits: ['planSelected'],
  components: {
    MiniMap
  },
  methods: {
    planSelected: function(event) {
      this.$emit('planSelected', event);
    },
    highlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>
