<template>
  <div class="relative">
    <mini-map
      ref="miniMap"
      :current-selected-plan="currentSelectedPlan"
      @planSelected="planSelected"
      :plans="plans"
    >
      <template v-slot:keyplate>
        <img id="keyplate" src="@/assets/images/floor-plate/building-1/level-3/B1L3-keyplate.svg" />
      </template>
      <template v-slot:highlights>
        <svg id="highlights" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 376.42">
          <g class="cls-1">
            <g id="Keyplate_Highlights" data-name="Keyplate Highlights">
              <polygon
                id="B1F3H01"
                class="cls-2"
                points="121.85 119.63 83.25 119.63 83.25 74.12 133.3 74.12 133.3 98.45 138.48 98.45 138.48 119.63 121.85 119.63"
              />
              <polygon
                id="B1F3H02"
                class="cls-2"
                points="83.25 57.02 83.25 49.02 82.31 49.02 82.31 41.8 77.29 41.8 77.29 7.29 100.8 7.29 113.12 7.2 132.82 7.38 133.22 27.53 133.22 74.12 113.69 74.12 113.69 57.49 83.25 57.02"
              />
              <polygon
                id="B1F3H03"
                class="cls-2"
                points="71.33 57.49 13.28 57.49 13.28 1.01 39.32 1.01 39.32 7.33 77.29 7.33 77.29 49.02 71.33 49.02 71.33 57.49"
              />
              <polygon
                id="B1F3H04"
                class="cls-2"
                points="71.33 66.91 66.31 66.91 66.31 84.64 13.28 84.64 13.28 80.95 3.23 80.95 3.23 60.95 13.27 60.95 13.27 57.49 71.33 57.49 71.33 66.91"
              />
              <rect id="B1F3H05" class="cls-2" x="13.28" y="84.64" width="58.05" height="33.89" />
              <polygon
                id="B1F3H06"
                class="cls-2"
                points="70.87 118.57 70.87 141.47 69.3 141.47 69.3 152.61 30.09 152.61 30.09 150.25 13.04 150.25 13.04 118.26 30.09 118.26 70.87 118.57"
              />
              <polygon
                id="B1F3H07"
                class="cls-2"
                points="13.28 150.22 30.54 150.22 30.54 152.58 69.76 152.58 69.76 159.01 71.33 159.01 71.33 192.59 24.42 192.59 24.42 190.71 13.28 190.71 13.28 150.22"
              />
              <polygon
                id="B1F3H08"
                class="cls-2"
                points="13.28 226.16 30.54 226.16 71.33 226.16 71.33 192.59 24.42 192.59 24.42 190.71 13.28 190.71 13.28 226.16"
              />
              <rect id="B1F3H09" class="cls-2" x="13.28" y="226.16" width="58.05" height="34.21" />
              <polygon
                id="B1F3H10"
                class="cls-2"
                points="13.28 293.94 13.28 260.37 71.33 260.37 71.33 294.26 13.28 293.94"
              />
              <rect id="B1F3H11" class="cls-2" x="13.32" y="293.94" width="58.01" height="34.2" />
              <polygon
                id="B1F3H12"
                class="cls-2"
                points="80.9 374.43 9.87 374.43 9.87 327.93 71.33 327.93 71.33 316.38 80.27 316.38 80.27 366.12 80.9 366.12 80.9 374.43"
              />
              <polygon
                id="B1F3H13"
                class="cls-2"
                points="107.25 374.43 107.25 316.38 80.27 316.38 80.27 366.12 80.9 366.12 80.9 374.43 107.25 374.43"
              />
              <rect id="B1F3H14" class="cls-2" x="107.25" y="316.38" width="27.15" height="58.05" />
              <rect id="B1F3H15" class="cls-2" x="133.92" y="316.38" width="34.37" height="58.05" />
              <rect id="B1F3H16" class="cls-2" x="168.29" y="316.38" width="33.89" height="58.05" />
              <polygon
                id="B1F3H18"
                class="cls-2"
                points="227.44 307.28 244.06 307.28 244.06 310.42 289.18 310.42 289.18 348.38 293.99 348.38 293.99 374.43 236.07 374.43 236.07 316.51 227.29 316.51 227.44 307.28"
              />
              <rect id="B1F3H17" class="cls-2" x="202.18" y="316.38" width="33.89" height="58.05" />
              <polygon
                id="B1F3H19"
                class="cls-2"
                points="289.18 254.54 289.18 310.42 244.02 310.42 244.02 307.28 231.31 307.28 231.31 304.14 218.61 304.14 218.61 254.54 289.18 254.54"
              />
              <polygon
                id="B1F3H20"
                class="cls-2"
                points="218.68 304.59 190.43 304.6 190.43 273.74 172.59 273.28 172.59 254.54 218.37 254.99 218.68 304.59"
              />
              <polygon
                id="B1F3H21"
                class="cls-2"
                points="128.75 304.14 103.18 304.14 103.18 286.1 135.31 286.1 135.31 267.43 172.59 267.43 172.59 304.14 148.21 304.14 148.21 298.96 128.71 298.96 128.75 304.14"
              />
              <polygon
                id="B1F3H22"
                class="cls-2"
                points="83.25 282.96 83.25 248.28 138.32 248.28 138.32 267.43 135.31 267.43 135.31 286.1 103.18 286.1 103.18 282.87 83.25 282.96"
              />
              <polygon
                id="B1F3H23"
                class="cls-2"
                points="83.25 222.87 88.28 222.87 88.28 205 124.83 205 124.83 204.2 133.3 204.2 133.3 230.4 138.32 230.4 138.32 248.28 83.26 248.28 83.25 222.87"
              />
              <polygon
                id="B1F3H24"
                class="cls-2"
                points="83.25 204.98 124.83 204.98 124.83 204.2 133.3 204.2 133.3 178.62 83.26 178.62 83.25 204.98"
              />
              <polygon
                id="B1F3H25"
                class="cls-2"
                points="84.82 163.71 84.82 152.58 124.83 152.58 124.83 153.83 133.3 153.83 133.3 178.62 83.25 178.62 83.25 163.78 84.82 163.71"
              />
            </g>
          </g>
        </svg>
      </template>
    </mini-map>
  </div>
</template>

<script>
import MiniMap from '../MiniMap.vue';

export default {
  name: 'Building1Level3MiniMap',
  props: ['currentSelectedPlan', 'plans'],
  emits: ['planSelected'],
  components: {
    MiniMap
  },
  methods: {
    planSelected: function(event) {
      this.$emit('planSelected', event);
    },
    highlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>
