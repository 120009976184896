<template>
  <div id="slider_wrapper" class="relative w-full h-full overflow-hidden">
    <div
      v-for="(image, index) in images"
      :key="index"
      class="slide absolute w-full h-full flex justify-center"
    >
      <img class="object-contain" :src="require(`../assets/${image}`)" :alt="`Image ${index}`" />
    </div>
  </div>
  <button v-on:click="animateSlides(1)" class="slide-button left-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="133.072"
      height="133.072"
      viewBox="0 0 133.072 133.072"
    >
      <g id="Group_84" data-name="Group 84" transform="translate(-22.512 -473.464)">
        <path
          id="Rectangle_392"
          data-name="Rectangle 392"
          d="M0,0H47.048A47.048,47.048,0,0,1,94.1,47.048v0A47.048,47.048,0,0,1,47.048,94.1h0A47.048,47.048,0,0,1,0,47.048V0A0,0,0,0,1,0,0Z"
          transform="translate(22.512 540) rotate(-45)"
          fill="#fae345"
        />
        <path
          id="Polygon_1"
          data-name="Polygon 1"
          d="M27,0,54,47H0Z"
          transform="translate(56.5 567) rotate(-90)"
          fill="#606e72"
        />
      </g>
    </svg>
  </button>
  <button v-on:click="animateSlides(-1)" class="slide-button right-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="133.072"
      height="133.072"
      viewBox="0 0 133.072 133.072"
    >
      <g id="Group_85" data-name="Group 85" transform="translate(-1545.416 -473.464)">
        <path
          id="Rectangle_393"
          data-name="Rectangle 393"
          d="M0,0H47.048A47.048,47.048,0,0,1,94.1,47.048v0A47.048,47.048,0,0,1,47.048,94.1h0A47.048,47.048,0,0,1,0,47.048V0A0,0,0,0,1,0,0Z"
          transform="translate(1678.488 540) rotate(135)"
          fill="#fae345"
        />
        <path
          id="Polygon_2"
          data-name="Polygon 2"
          d="M27,0,54,47H0Z"
          transform="translate(1644.5 513) rotate(90)"
          fill="#606e72"
        />
      </g>
    </svg>
  </button>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'ImageSlider',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      slides: [],
      slideDelay: 10,
      slideDuration: 1,
      slideWidth: 0,
      wrapWidth: 0,
      indexWrap: '',
      wrap: '',
      timer: '',
      slideAnimation: '',
      animation: '',
      proxy: ''
    };
  },
  mounted() {
    this.slides = document.querySelectorAll('.slide');
    for (let i = 0; i < this.slides.length; i += 1) {
      gsap.set(this.slides[i], {
        xPercent: i * 100
      });
    }
    this.wrap = gsap.utils.wrap(-100, (this.slides.length - 1) * 100);
    this.indexWrap = gsap.utils.wrap(0, this.slides.length);
    this.timer = gsap.delayedCall(this.slideDelay, this.autoPlay);
    this.animation = gsap.to(this.slides, {
      xPercent: `+=${this.slides.length * 100}`,
      autoAlpha: 1,
      duration: 1,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: this.wrap
      }
    });
    this.proxy = document.createElement('div');
    this.slideAnimation = gsap.to({}, {});

    this.resize();

    // swiped events
    document.getElementById('slider_wrapper').addEventListener('swiped-left', e => {
      this.animateSlides(-1);
    });
    document.getElementById('slider_wrapper').addEventListener('swiped-right', e => {
      this.animateSlides(1);
    });
  },
  methods: {
    animateSlides(direction) {
      this.timer.restart(true);
      this.slideAnimation.kill();
      const x = this.snapX(gsap.getProperty(this.proxy, 'x') + direction * this.slideWidth);

      const index = this.indexWrap(-x / this.slideWidth);
      const previousIndex = index > 0 ? index - 1 : this.slides.length - 1;
      const nextIndex = index < this.slides.length - 1 ? index + 1 : 0;
      const currentSlide = this.slides[index].querySelector('img');
      const previousSlide = this.slides[previousIndex].querySelector('img');
      const nextSlide = this.slides[nextIndex].querySelector('img');

      this.slideAnimation = gsap.to(this.proxy, {
        x,
        ease: 'power2.inOut',
        duration: this.slideDuration,
        onStart: () => {
          gsap.to([previousSlide, nextSlide], { opacity: 0, delay: 0.25, duration: 1 });
          gsap.set(currentSlide, { opacity: 0 });
        },
        onUpdate: () => {
          this.updateProgress();
          gsap.to(currentSlide, { opacity: 1, delay: 0.25, duration: 1 });
        }
      });
    },
    autoPlay() {
      this.animateSlides(-1);
    },
    updateProgress() {
      this.animation.progress(
        gsap.utils.wrap(0, 1, gsap.getProperty(this.proxy, 'x') / this.wrapWidth)
      );
    },
    resize() {
      const norm = gsap.getProperty(this.proxy, 'x') / this.wrapWidth || 0;

      this.slideWidth = this.slides[0].offsetWidth;
      this.wrapWidth = this.slideWidth * this.slides.length;
      this.snapX = gsap.utils.snap(this.slideWidth);

      gsap.set(this.proxy, {
        x: norm * this.wrapWidth
      });

      this.animateSlides(0);
      this.slideAnimation.progress(1);
    }
  }
};
</script>
<style scope lang="scss">
.slide-button {
  @apply absolute top-1/2 transform -translate-y-1/2;
  width: 6.979166vw; //134px
  heigh: 6.979166vw; //134px
  svg {
    @apply w-full h-full;
  }
}
</style>
