<template>
  <div class="relative w-full">
    <div class="floor-plan-nav absolute z-20">
      <floor-plan-menu></floor-plan-menu>
    </div>
    <router-view class="w-full h-full relative z-10" v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import FloorPlanMenu from '../components/FloorPlanMenu.vue';

export default {
  name: 'FloorPlanFloorPlate',
  components: {
    FloorPlanMenu
  },
  props: ['plans']
};
</script>

<style scoped lang="scss">
.floor-plan-nav {
  width: 24.32vw;
  left: 5.07vw;
  top: 5.6vw;
}
</style>
