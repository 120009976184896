<template>
  <transition name="slide">
    <div v-if="$route.fullPath != '/'" id="nav" class="flex flex-col justify-between items-center">
      <router-link to="/floor-plans">
        <div class="menu menu_floor-plans"></div>
        <span>Floorplans</span>
      </router-link>
      <router-link to="/building">
        <div class="menu menu_building"></div>
        <span>Building</span>
      </router-link>
      <router-link to="/features">
        <div class="menu menu_features"></div>
        <span>Features</span>
      </router-link>
      <router-link to="/neighbourhood">
        <div class="menu menu_neighbourhood"></div>
        <span>Neighbourhood</span>
      </router-link>
      <router-link to="/gallery">
        <div class="menu menu_gallery"></div>
        <span>Gallery</span>
      </router-link>
    </div>
  </transition>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <full-screen-button></full-screen-button>
</template>

<script>
import FullScreenButton from '@/components/FullScreenButton.vue';
export default {
  components: {
    FullScreenButton
  }
};
</script>

<style lang="scss">
// Mixins
@mixin nav-menu-bg($menu) {
  background-image: url('./assets/icons/icon-menu-#{$menu}.svg');
}

@mixin nav-menu-bg-active($menu) {
  background-image: url('./assets/icons/icon-menu-#{$menu}-white.svg');
}

// Transition effect - slide
.slide-enter-active,
.slide-leave-active,
.fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(11.6145833333vw);
  opacity: 0;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.btn {
  @apply bg-white text-center leading-none font-semibold uppercase cursor-pointer transition flex justify-center items-center;
  font-size: 1.3vw; //text-xl
  width: 28.64vw; //550px
  height: 4.16vw; //80px
  padding: 1.5104vw 1.5625vw 1.1979vw; //29px 30px 23px
  border-radius: 0 2.08vw 2.08vw 2.08vw; // 40px

  &.btn--sm {
    @apply font-medium;
    font-size: 0.78vw; //text-md
    width: 13.22vw; //254px
    height: 1.927vw; //37px
    padding: 0.677vw 0.9375vw 0.46875vw; //13px 18px 9px
  }

  &.btn--secondary {
    border-radius: 2.08vw 2.08vw 0 2.08vw;
  }

  &.btn--tertiary {
    border-radius: 2.08vw 2.08vw 2.08vw 0;
  }
}

#app {
  @apply font-sans font-normal antialiased
        text-neutral text-base leading-normal
        flex flex-row-reverse
        relative;
  width: 100vw;
  height: 56.25vw;
  overflow: hidden;
  * {
    -webkit-tap-highlight-color: transparent;
  }
}

#nav {
  @apply flex-shrink-0 h-full bg-black;
  width: 11.6145833333vw; //223px / 1920px
  padding: calc(48 / 1920) * 100vw calc(64 / 1920) * 100vw; // px-12 py-16

  a {
    @apply flex flex-col items-center;
    span {
      @apply font-normal text-white leading-snug;
      font-size: 0.78vw; //text-md
      margin-top: 0.72vw; //mt-3.5
    }
    .menu {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 4.89583333333vw; //94.1px / 1920px
      height: 4.89583333333vw; //94.1px / 1920px
      border-radius: 0 50% 50% 50%;
      &.menu_floor-plans {
        @include nav-menu-bg(floor-plans);
      }
      &.menu_building {
        @include nav-menu-bg(building);
      }
      &.menu_features {
        @include nav-menu-bg(features);
      }
      &.menu_neighbourhood {
        @include nav-menu-bg(neighbourhood);
      }
      &.menu_gallery {
        @include nav-menu-bg(gallery);
      }
    }

    &.router-link-active {
      span {
        @apply font-semibold;
      }

      .menu_floor-plans {
        @include nav-menu-bg-active(floor-plans);
      }
      .menu_building {
        @include nav-menu-bg-active(building);
      }
      .menu_features {
        @include nav-menu-bg-active(features);
      }
      .menu_neighbourhood {
        @include nav-menu-bg-active(neighbourhood);
      }
      .menu_gallery {
        @include nav-menu-bg-active(gallery);
      }
    }
  }
}

svg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;

  &::selection {
    background: none;
  }
}
</style>
