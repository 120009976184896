<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-1/level-4/B1L4-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B1F4H01"
            class="cls-2"
            points="474.95 204.11 474.95 313.95 612.25 313.95 612.25 262.73 601.21 262.73 601.21 203.95 474.95 204.11"
          />
          <polygon
            id="B1F4H02"
            class="cls-2"
            points="474.95 158.42 474.95 141.8 474.95 121.15 463.65 120.85 463.65 36.19 617.7 36.49 617.7 38.83 619.26 38.83 619.26 88.02 601.14 88.02 601.14 200.51 553.94 200.51 553.94 158.21 474.95 158.42"
          />
          <polygon
            id="B1F4H03"
            class="cls-2"
            points="448.7 159.33 302.87 159.33 302.87 22.39 305.2 22.39 305.2 20.89 419 20.89 419 35.72 419 36.49 460.06 36.49 460.06 138.59 448.87 138.59 448.7 159.33"
          />
          <polygon
            id="B1F4H04"
            class="cls-2"
            points="448.7 162.94 302.84 162.94 302.84 169.61 278.43 169.61 278.43 219.1 302.87 219.1 302.87 226.65 433.23 226.97 433.23 183.62 448.7 183.3 448.7 162.94"
          />
          <polygon
            id="B1F4H05"
            class="cls-2"
            points="448.7 230.2 302.87 230.2 302.87 283.02 301.3 283.02 301.3 311.85 448.7 311.85 448.7 230.2"
          />
          <polygon
            id="B1F4H06"
            class="cls-2"
            points="448.7 314.38 302.87 314.38 302.87 366.42 302.87 367.19 301.28 367.19 301.28 391.42 348.3 391.42 348.3 394.11 441.54 394.11 441.54 367.8 448.72 367.8 448.7 314.38"
          />
          <polygon
            id="B1F4H07"
            class="cls-2"
            points="448.7 436.85 445.49 436.85 445.49 414.87 441.47 414.87 441.47 400.44 302.87 400.44 302.87 466.87 301.3 466.87 301.3 491.91 333.29 491.91 333.29 494.86 448.72 494.86 448.7 436.85"
          />
          <polygon
            id="B1F4H08"
            class="cls-2"
            points="448.72 498.41 302.87 498.41 302.87 551.23 301.3 551.23 301.3 580.08 448.72 580.08 448.72 498.41"
          />
          <rect id="B1F4H09" class="cls-2" x="302.89" y="582.4" width="145.83" height="81.92" />
          <polygon
            id="B1F4H10"
            class="cls-2"
            points="448.72 666.76 302.89 666.76 302.89 748.41 448.74 748.41 448.72 666.76"
          />
          <polygon
            id="B1F4H11"
            class="cls-2"
            points="448.74 750.93 302.89 750.93 302.89 803.75 301.32 803.75 301.32 832.53 445.52 832.53 445.52 791.52 448.72 791.52 448.74 750.93"
          />
          <polygon
            id="B1F4H12"
            class="cls-2"
            points="448.72 802.49 448.72 835.15 294.31 835.15 294.31 920.24 292.74 920.24 292.74 949.88 345.13 949.88 348.06 949.88 348.06 948.32 467.33 948.32 467.33 802.59 448.72 802.49"
          />
          <rect id="B1F4H13" class="cls-2" x="470.93" y="802.49" width="64.29" height="145.83" />
          <rect id="B1F4H14" class="cls-2" x="537.87" y="802.49" width="64.33" height="145.83" />
          <polygon
            id="B1F4H15"
            class="cls-2"
            points="605.56 802.49 605.56 905.46 604.41 905.46 604.41 949.88 634.13 949.88 634.13 948.32 686.37 948.32 686.37 802.49 605.56 802.49"
          />
          <polygon
            id="B1F4H16"
            class="cls-2"
            points="689.76 802.46 689.76 905.46 688.73 905.46 688.73 949.88 718.33 949.88 718.33 948.32 770.56 948.32 770.56 802.46 689.76 802.46"
          />
          <polygon
            id="B1F4H17"
            class="cls-2"
            points="773.91 802.46 773.91 905.46 772.88 905.46 772.88 949.88 802.42 949.88 802.42 948.32 854.71 948.32 854.71 806.09 834.28 806.09 834.28 805.69 833.25 805.69 833.25 802.49 773.91 802.46"
          />
          <polygon
            id="B1F4H18"
            class="cls-2"
            points="833.25 783.52 833.25 802.09 858.08 802.09 858.08 948.31 1001.16 948.31 1001.16 883.6 989.49 883.6 989.49 791.1 875.09 791.1 875.09 783.31 833.25 783.52"
          />
          <polygon
            id="B1F4H19"
            class="cls-2"
            points="816 775.21 816 650.41 930.64 650.41 930.64 648.61 991.05 648.61 991.05 675.13 989.51 675.13 989.51 787.5 879.08 787.5 879.08 779.56 846.81 779.56 846.81 775.29 816 775.21"
          />
          <polygon
            id="B1F4H20"
            class="cls-2"
            points="745.43 775.41 745.43 694.88 702.52 694.88 702.52 650.41 790.86 650.41 790.86 648.9 813.29 648.9 813.29 775.41 745.43 775.41"
          />
          <path
            id="B1F4H21"
            class="cls-2"
            d="M639.56,775.46v-16H589.05v12.6H527.8V730.52h80.26v-48h49.17v-1.65h43v94.58S639.56,774.72,639.56,775.46Z"
          />
          <polygon
            id="B1F4H22"
            class="cls-2"
            points="474.95 636.83 583.98 636.83 583.98 635.4 613.58 635.4 613.58 682.48 604.51 682.48 604.51 726.92 527.79 726.92 527.79 718.59 474.91 718.59 474.95 636.83"
          />
          <polygon
            id="B1F4H23"
            class="cls-2"
            points="474.95 573.44 490.74 573.44 490.74 529.16 601.14 529.16 601.14 590.48 613.58 590.48 613.58 635.4 583.98 635.4 583.98 633.58 474.91 633.58 474.95 573.44"
          />
          <rect id="B1F4H24" class="cls-2" x="474.95" y="463.71" width="123.59" height="61.84" />
          <polygon
            id="B1F4H25"
            class="cls-2"
            points="474.95 423.43 482.18 423.43 482.18 400.42 598.55 400.42 598.55 460.16 474.95 460.16 474.95 423.43"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-1-level-4-mini-map ref="miniMap" @planSelected="selectPlan($event)">
        </building-1-level-4-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building1Level4MiniMap from './Building1Level4MiniMap.vue';

export default {
  name: 'Building1Level4',
  components: {
    FloorPlanFloorPlate,
    Building1Level4MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
