<template>
  <div class="features w-full bg-white">
    <!-- Section - Intro -->
    <div class="features_intro flex items-start">
      <img src="./../assets/images/logo-v.svg" alt="Viktor logo" />
      <div>
        <h1 class="uppercase text-primary">The possibilities are endless.</h1>
        <p class="leading-snug text-white">
          Functional doesn’t have to mean boring. Viktor is a European-inspired, modernist 6-storey
          structure filled with natural light. Individual homes are bright, intelligently designed
          and well-constructed. Large balconies and a spacious, landscaped courtyard make it easy to
          live—and enjoy life—on your terms. Close to cafes, shops, parks and rapid transit, Viktor
          is the new frame of reference for modern, balanced living.
        </p>
      </div>
    </div>
    <!-- Section - Accordions -->
    <div
      v-if="featureListArray.length"
      :class="{ active: isAccordionActive }"
      class="features_accordions"
    >
      <div
        v-for="(item, index) in featureListArray"
        :key="item.title"
        :class="{ active_item: item.active }"
        class="features_accordions_single"
      >
        <h2 v-on:click="showDetail(index)">{{ item.title }}</h2>
        <p v-html="item.paragraph"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Features',
  data() {
    return {
      isAccordionActive: false,
      featureListArray: [
        {
          active: false,
          title: 'Metro vancouver’s fastest growing city',
          paragraph:
            'Live in the heart of Surrey City Centre and enjoy the convenience of all it has to offer while being surrounded by breathtaking mountains and stunning city views. <br/><br/>The future 104 St SkyTrain Station is within walking distance of your new home. The SkyTrain expansion plan will connect residents all over the Lower Mainland giving a true sense of community. <br/><br/>The nearby Forsyth Park offers perfect symmetry between city and suburban life.'
        },
        {
          active: false,
          title: 'Conscious design',
          paragraph:
            'Ultramodern architecture highlights the West Coast infused design by DF Architecture. 254 thoughtfully designed homes built by quality driven and trusted developer ML Emporio Properties. Private outdoor balconies extend the living space and invite the surrounding natural beauty in. Ground level homes come equipped with a water bib. Large windows allow for substantial light to pour into homes highlighting their stunning design.'
        },
        {
          active: false,
          title: 'Tasteful interiors',
          paragraph:
            'Choose between two modern colour palettes: Blanc (Light) & Noir (Dark). Recessed pot lights throughout the living spaces add warmth and overall enhance their natural ambiance. Luxurious wide-plank laminate flooring throughout main living areas and bedrooms combine durability with elegance. High ceilings maximize light throughout all homes.'
        },
        {
          active: false,
          title: 'Fall in love with cooking',
          paragraph:
            'Studding quartz countertops with matching full-height backsplash unique to each colour pallets. Elegant two-toned cabinetry offes ample storage and soft-close drawers. Intuitive under-cabinet LED lighting provides added flare. Stainless steel under-mount sink with polished chrome faucet with pull-out spray and integrated swivel. USB charging port conveniently located in kitchen islands.'
        },
        {
          active: false,
          title: 'Tranquil bathrooms',
          paragraph:
            'Beautiful solid quartz countertops with matching backsplash throughout all bathrooms. Stay organized with mirrored medicine cabinet with LED vanity light and convenient wall-mounted shelf. Choose between two colour palettes of elegant flat panel cabinetry with soft close hardware. Destress and relax in the large soaker bathtub. Seamless under-mount sink with polished chrome faucets and accessories throughout. Frameless glass shower door in bathroom with a shower.'
        },
        {
          active: false,
          title: 'Blended amenities',
          paragraph:
            'Amenity spaces in both buildings seamlessly blend indoor and outdoor recreation while providing homeowners a chance to entertain, relax and enjoy all their community has to offer. Entertaining has never been more convenient with Viktor’s fully equipped communal kitchen and lounge. Indoor amenity spaces open up onto the beautiful landscaped outdoor private courtyard with outdoor furnishing and play area perfect for gathering with family and friends.'
        },
        {
          active: false,
          title: 'Feel taken care of',
          paragraph:
            'Safe and secure parcel delivery lockers. Secure residential underground parking. Parking stalls roughed in for EV charging. Hardwired smoke detectors in all homes and common areas. Easily accessible and secure bike storage room. Fire sprinklers in all homes and common areas.'
        }
      ]
    };
  },
  methods: {
    showDetail: function(selectedIndex) {
      this.featureListArray.forEach((el, index) => {
        if (selectedIndex == index) {
          el.active = !el.active;
          this.isAccordionActive = true;
        } else el.active = false;
      });
    }
  },
  updated() {
    if (this.featureListArray.every(el => el.active === false)) {
      this.isAccordionActive = false;
    }
  }
};
</script>

<style scope lang="scss">
.features {
  &_intro {
    @apply bg-neutral;
    padding: 4.84vw 6.25vw; //93px 120px

    img {
      width: 4.57vw; //87.82px
      margin-right: 6.04vw; //116px
    }
    h1 {
      font-size: 1.04vw; //18px
      margin-bottom: 1.04vw; //18px
    }
    p {
      font-size: 0.78125vw; //text-md
      max-width: 46.25vw; //888px
    }
  }
  &_accordions {
    padding: 4.16vw 6.25vw; //80px 120px
    &.active {
      .features_accordions_single {
        margin-bottom: 0.2vw; //4px
        p {
          transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
        }

        // height: auto ain't pretty for CSS animaiton, should set fixed height
        &:nth-of-type(1).active_item > p {
          height: 8.33vw; //160px
        }
        &:nth-of-type(2).active_item > p {
          height: 5vw; //96px
        }
        &:nth-of-type(3).active_item > p {
          height: 5.2vw; //100px
        }
        &:nth-of-type(4).active_item > p {
          height: 5.2vw; //100px
        }
        &:nth-of-type(5).active_item > p {
          height: 6.25vw; //120px
        }
        &:nth-of-type(6).active_item > p {
          height: 6.25vw;
        }
        &:nth-of-type(7).active_item > p {
          height: 4.16vw; //80px
        }
      }
    }

    &_single {
      margin-bottom: 14.5px;
      transition: margin-bottom 0.3s ease-in-out;

      h2 {
        @apply leading-snug font-semibold uppercase border-primary cursor-pointer bg-white relative z-10;
        border-bottom-width: 0.05vw;
        font-size: 1.3vw; //text-xl
        padding-bottom: 0.7vw; //13.5px
        margin-bottom: 1.32vw; //25.5px
      }
      p {
        @apply leading-normal;
        transition: opacity 0.3s ease-in-out, height 0.1s ease-in-out 0.2s;
        font-size: 0.78125vw; //text-md
        height: 0;
        opacity: 0;
        width: 46.25vw; //888px
      }
      &:nth-of-type(2) p {
        width: 53.125vw; //1020px
      }

      &.active_item {
        h2 {
          @apply font-bold;
          border-bottom-width: 0.15625vw;
        }

        p {
          opacity: 1;
        }
      }
    }
  }
}

// Features only transition
// Transition effect - slide
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 1s ease-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(-3.125vw); //-60px
  opacity: 0;
}
</style>
