<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-2/level-2/B2L2-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B2F2H01"
            class="cls-2"
            points="676.3 746.91 548.57 746.91 548.57 802.69 548.43 813.3 676.25 813.85 676.3 746.91"
          />
          <rect id="B2F2H02" class="cls-2" x="548.43" y="817.24" width="128.01" height="63.11" />
          <polygon
            id="B2F2H03"
            class="cls-2"
            points="676.09 925.95 596.17 925.95 596.17 883.36 548.36 883.36 548.36 918.16 551.03 918.16 551.03 998.65 539.24 998.77 539.54 1043.82 548.24 1043.82 548.24 1049.94 592.62 1049.94 592.72 1043.62 640.03 1043.77 640.03 1049.72 687.89 1049.72 687.89 942.77 676.09 942.77 676.09 925.95"
          />
          <polygon
            id="B2F2H04"
            class="cls-2"
            points="703.07 925.18 850.87 925.18 850.87 1030.53 846.26 1030.96 846.26 1050.38 785.35 1050.74 785.34 1049.72 691.52 1049.54 691.59 942.82 703.1 943 703.07 925.18"
          />
          <polygon
            id="B2F2H05"
            class="cls-2"
            points="703.27 853.38 850.82 853.38 850.82 864.51 876.31 864.51 876.31 914.6 850.82 914.6 850.82 921.31 719.24 921.31 719.24 888.27 703.27 888.27 703.27 853.38"
          />
          <polygon
            id="B2F2H06"
            class="cls-2"
            points="703.27 752.84 822.56 752.84 822.56 751.77 852.38 751.77 852.46 794.92 850.87 794.89 850.87 849.95 703.27 849.95 703.27 752.84"
          />
          <polygon
            id="B2F2H08"
            class="cls-2"
            points="703.25 649.9 710.72 649.9 710.72 664.76 850.82 664.76 850.82 613.89 852.62 613.89 852.77 583.61 807.5 583.61 807.53 584.46 703.24 584.46 703.25 649.9"
          />
          <polygon
            id="B2F2H09"
            class="cls-2"
            points="703.25 580.87 850.82 580.87 850.82 528.23 852.62 528.18 852.62 498.26 807.53 498.2 807.53 499.13 703.27 499.13 703.25 580.87"
          />
          <polygon
            id="B2F2H10"
            class="cls-2"
            points="703.27 495.61 850.82 495.61 850.82 442.76 852.89 442.76 852.59 412.82 807.53 412.82 807.53 413.85 703.27 413.85 703.27 495.61"
          />
          <polygon
            id="B2F2H11"
            class="cls-2"
            points="703.27 410.32 850.82 410.32 850.82 359.17 851 357.44 852.62 357.44 852.89 327.44 807.53 327.44 807.53 328.48 703.27 328.48 703.27 410.32"
          />
          <polygon
            id="B2F2H12"
            class="cls-2"
            points="703.27 325.04 850.82 324.83 850.82 272.08 852.71 272.05 852.89 242.05 807.53 242.05 807.53 242.87 706.51 242.87 706.51 287.2 703.27 287.2 703.27 325.04"
          />
          <polygon
            id="B2F2H13"
            class="cls-2"
            points="702.64 272.27 702.64 239.42 859.48 239.42 859.48 153.08 861.27 153.06 861.27 123.03 808.31 123.03 808.31 124.74 684.17 124.74 684.17 272.27 702.64 272.27"
          />
          <polygon
            id="B2F2H14"
            class="cls-2"
            points="680.77 272.55 680.77 155.19 680.84 152.59 681.56 152.59 681.56 127.23 671.41 127.23 671.41 110.39 637.02 110.39 637.02 152.59 630.42 152.59 630.42 124.62 562.96 124.62 562.96 272.55 680.77 272.55"
          />
          <polygon
            id="B2F2H15"
            class="cls-2"
            points="559.01 272.55 559.41 168.1 560.46 168.1 560.46 122.95 530.43 122.95 530.43 124.62 477.19 124.63 477.19 272.52 559.01 272.55"
          />
          <polygon
            id="B2F2H16"
            class="cls-2"
            points="473.62 272.55 474.03 168.1 475.07 168.1 475.07 122.95 444.85 122.95 444.85 124.63 392.06 124.63 392.06 272.23 473.62 272.55"
          />
          <polygon
            id="B2F2H17"
            class="cls-2"
            points="388.39 272.1 388.64 168.1 389.69 168.1 389.69 124.62 359.51 124.58 306.65 124.58 306.65 272.1 388.39 272.1"
          />
          <polygon
            id="B2F2H18"
            class="cls-2"
            points="303.67 272.55 303.67 124.62 158.05 124.62 158.05 190.76 196.31 190.76 196.31 197.03 170.26 197.03 170.26 284.35 282.23 284.35 282.23 272.76 303.67 272.55"
          />
          <polygon
            id="B2F2H19"
            class="cls-2"
            points="285.79 287.88 170.55 287.88 170.55 401.6 168.4 401.77 168.4 428.66 229.59 428.66 229.55 426.83 345.98 426.83 345.98 383.03 303.86 383.03 303.86 299.66 285.66 299.66 285.79 287.88"
          />
          <polygon
            id="B2F2H20"
            class="cls-2"
            points="349.19 299.97 349.19 409.01 348.46 409.07 348.46 427.86 346.87 427.86 346.87 447.64 392.89 447.64 393.09 436.37 391.3 436.37 391.3 427.07 448.27 426.98 448.27 409.07 489.87 408.65 489.87 320.94 445.66 320.94 445.66 299.77 409.36 299.59 349.19 299.97"
          />
          <polygon
            id="B2F2H21"
            class="cls-2"
            points="493.55 299.38 493.55 393.66 492.5 393.66 492.5 409.07 490.91 409.07 490.91 424.89 527.89 424.89 527.89 442.34 565.72 442.34 565.72 440.43 673.19 440.43 673.19 392.83 661.25 392.83 661.25 356.75 623.59 356.75 623.59 315.6 610.95 315.6 610.95 299.54 493.55 299.38"
          />
          <polygon
            id="B2F2H22"
            class="cls-2"
            points="676.44 444.67 565.72 444.67 565.72 442.34 527.89 442.34 527.89 488.05 548.43 488.05 548.43 540.16 676.44 540.16 676.44 444.67"
          />
          <polygon
            id="B2F2H23"
            class="cls-2"
            points="676.44 543.79 568.35 543.79 565.72 543.79 565.72 542.8 538.94 542.8 538.94 541 527.89 541 527.89 596.04 539.01 596.04 539.01 594.82 548.43 594.82 548.43 664.76 669.23 664.76 669.23 645.94 673.19 645.94 676.44 645.94 676.44 543.79"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-2-level-2-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-2-level-2-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building2Level2MiniMap from './Building2Level2MiniMap.vue';

export default {
  name: 'Building2Level2',
  components: {
    FloorPlanFloorPlate,
    Building2Level2MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
