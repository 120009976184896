<template>
  <div class="relative">
    <mini-map
      ref="miniMap"
      :current-selected-plan="currentSelectedPlan"
      @planSelected="planSelected"
      :plans="plans"
    >
      <template v-slot:keyplate>
        <img id="keyplate" src="@/assets/images/floor-plate/building-2/level-2/B2L2-keyplate.svg" />
      </template>
      <template v-slot:highlights>
        <svg id="highlights" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.87 390.74">
          <g class="cls-1">
            <g id="Keyplate_Highlights" data-name="Keyplate Highlight">
              <rect id="B2F2H01" class="cls-2" x="162.91" y="259.02" width="51.06" height="26.34" />
              <rect id="B2F2H02" class="cls-2" x="162.91" y="285.35" width="51.06" height="26.34" />
              <polygon
                id="B2F2H03"
                class="cls-2"
                points="162.89 311.66 174.11 311.66 174.11 312.36 183.06 312.36 183.06 329.54 213.97 329.54 213.97 337.68 214.93 337.68 214.93 344.86 219.87 344.86 219.87 380.12 196.1 380.12 196.1 377.81 183.44 377.72 183.44 386.51 162.87 386.49 162.89 359.75 162.89 311.66"
              />
              <polygon
                id="B2F2H04"
                class="cls-2"
                points="225.94 329.06 284.97 329.06 284.97 373.42 284.9 386.49 258.42 386.49 258.49 380.12 219.87 380.12 219.87 337.68 225.94 337.68 225.94 329.06"
              />
              <polygon
                id="B2F2H05"
                class="cls-2"
                points="225.94 300.02 284.97 300.02 284.97 305.3 295.11 305.3 295.11 325.56 284.97 325.56 284.97 329.06 231.2 329.06 231.2 314.38 225.94 314.38 225.94 300.02"
              />
              <polygon
                id="B2F2H06"
                class="cls-2"
                points="245.72 259.49 273.42 259.83 284.9 259.83 284.98 277.2 284.98 300.02 225.94 300.02 225.94 270.82 225.88 259.51 245.72 259.49"
              />
              <polygon
                id="B2F2H08"
                class="cls-2"
                points="225.94 191.68 273.81 191.68 284.97 192 284.97 203.99 284.97 226.47 267.8 226.47 267.37 226.04 227.69 226.04 227.69 218.07 225.94 218.07 225.94 191.68"
              />
              <polygon
                id="B2F2H09"
                class="cls-2"
                points="225.94 157.22 273.76 157.22 284.98 157.54 284.97 169.66 284.97 192 273.81 192 225.94 192 225.94 157.22"
              />
              <polygon
                id="B2F2H10"
                class="cls-2"
                points="225.94 122.75 273.81 122.75 284.97 122.91 284.98 135.2 284.98 157.54 273.85 157.54 225.94 157.54 225.94 122.75"
              />
              <polygon
                id="B2F2H11"
                class="cls-2"
                points="225.94 88.29 273.81 88.29 284.97 88.61 284.97 100.74 284.97 122.91 225.94 122.91 225.94 88.29"
              />
              <polygon
                id="B2F2H12"
                class="cls-2"
                points="225.94 53.82 273.81 53.82 284.9 54.2 284.97 66.09 284.97 88.61 225.94 88.61 225.94 53.82"
              />
              <polygon
                id="B2F2H13"
                class="cls-2"
                points="217 65.79 217 6.75 267.35 6.75 288.41 6.87 288.19 18.24 288.19 54.14 225.94 54.14 225.94 65.79 217 65.79"
              />
              <polygon
                id="B2F2H14"
                class="cls-2"
                points="168.01 65.79 168.01 6.75 195.88 6.75 195.88 1.07 216.46 1.07 216.68 17.92 216.68 65.79 168.01 65.79"
              />
              <polygon
                id="B2F2H15"
                class="cls-2"
                points="133.24 6.75 155.57 6.75 167.69 6.75 167.69 65.79 133.56 65.79 133.24 6.75"
              />
              <polygon
                id="B2F2H16"
                class="cls-2"
                points="98.77 6.82 98.77 65.79 133.56 65.79 133.56 6.75 121.04 6.82 98.77 6.82"
              />
              <polygon
                id="B2F2H17"
                class="cls-2"
                points="64.78 6.75 86.75 6.75 99.09 6.82 99.09 65.79 64.62 65.79 64.78 6.75"
              />
              <polygon
                id="B2F2H18"
                class="cls-2"
                points="56.8 71.86 10.21 71.86 10.21 33.24 5.25 33.3 5.25 6.82 17.23 6.75 64.78 6.75 64.78 65.79 56.64 65.79 56.8 71.86"
              />
              <polygon
                id="B2F2H19"
                class="cls-2"
                points="56.8 77.92 64.62 77.92 64.62 110.62 81.86 110.62 81.86 128.34 34.31 128.34 10.03 128.86 10.21 118.61 10.21 71.86 56.8 71.86 56.8 77.92"
              />
              <polygon
                id="B2F2H20"
                class="cls-2"
                points="81.36 78.02 122.03 78.02 122.03 85.3 139.89 85.31 140.21 121.52 122.34 121.52 122.34 128.86 99.37 128.86 99.29 136.49 82.14 136.51 81.63 121.3 81.36 78.02"
              />
              <polygon
                id="B2F2H21"
                class="cls-2"
                points="208.81 99.98 193.72 99.98 193.72 83.23 188.55 83.23 188.55 77.97 139.89 77.97 139.89 127.07 155.2 127.07 155.2 134.91 213.75 134.91 213.75 114.66 208.81 114.66 208.81 99.98"
              />
              <polygon
                id="B2F2H22"
                class="cls-2"
                points="155.19 152.51 162.86 152.51 162.86 175.44 213.75 175.44 213.75 134.91 155.2 134.91 155.19 152.51"
              />
              <polygon
                id="B2F2H23"
                class="cls-2"
                points="211.36 225.53 162.87 225.53 162.87 196.5 155.19 196.5 155.19 175.35 169.72 175.35 213.75 175.35 213.75 217.87 211.38 217.87 211.36 225.53"
              />
            </g>
          </g>
        </svg>
      </template>
    </mini-map>
  </div>
</template>

<script>
import MiniMap from '../MiniMap.vue';

export default {
  name: 'Building2Level1MiniMap',
  props: ['currentSelectedPlan', 'plans'],
  emits: ['planSelected'],
  components: {
    MiniMap
  },
  methods: {
    planSelected: function(event) {
      this.$emit('planSelected', event);
    },
    highlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>
