<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-1/level-6/B1L6-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B1F6H01"
            class="cls-2"
            points="474.22 256.89 474.22 201.69 600.6 201.69 600.6 260.17 611.72 260.17 611.95 311.33 474.22 311.33 474.22 256.89"
          />
          <polygon
            id="B1F6H02"
            class="cls-2"
            points="474.6 138.04 474.77 118.58 463.02 118.84 463.02 34.12 519.83 33.86 600.81 33.86 600.81 84.12 600.94 164.1 600.94 198.08 552.11 198.27 551.93 155.94 474.6 155.94 474.6 138.04"
          />
          <polygon
            id="B1F6H03"
            class="cls-2"
            points="447.96 156.86 302.18 156.86 302.18 22.18 367 22.18 367 33.86 459.11 33.86 459.11 136.09 447.96 136.09 447.96 156.86"
          />
          <polygon
            id="B1F6H04"
            class="cls-2"
            points="277.21 167.26 302.18 167.26 302.18 159.74 448.29 159.74 448.29 180.15 435.68 180.15 435.68 223.58 302.18 223.58 302.18 216.87 277.18 216.79 277.21 167.26"
          />
          <polygon
            id="B1F6H05"
            class="cls-2"
            points="448.29 249.58 448.29 227.04 302.18 227.04 302.18 279.26 300.32 279.26 300.32 309.67 448.29 309.18 448.29 249.58"
          />
          <polygon
            id="B1F6H06"
            class="cls-2"
            points="448.36 333.52 448.36 311.9 302.18 311.9 302.18 363.22 302.18 389.23 347.68 389.23 347.68 391.92 444.3 391.92 444.3 368.18 448.36 368.18 448.36 333.52"
          />
          <polygon
            id="B1F6H07"
            class="cls-2"
            points="448.29 437.89 448.29 408.93 444.26 408.93 444.26 398.04 302.18 398.04 302.18 489.89 330.02 489.89 332.64 489.89 332.64 492.79 448.31 492.79 448.29 437.89"
          />
          <polygon
            id="B1F6H08"
            class="cls-2"
            points="448.31 496.35 302.18 496.35 302.18 548.44 300.67 548.44 300.67 578.05 448.31 578.05 448.31 496.35"
          />
          <polygon
            id="B1F6H09"
            class="cls-2"
            points="448.31 580.63 302.18 580.63 302.18 633.05 302.14 662.43 448.31 662.43 448.31 580.63"
          />
          <polygon
            id="B1F6H10"
            class="cls-2"
            points="448.31 664.54 302.18 664.54 302.18 716.66 302.14 746.55 448.31 746.55 448.31 664.54"
          />
          <polygon
            id="B1F6H11"
            class="cls-2"
            points="448.31 748.34 302.18 748.34 302.18 801 300.62 801 300.62 830.87 448.71 830.87 448.31 748.34"
          />
          <polygon
            id="B1F6H12"
            class="cls-2"
            points="466.96 800.53 448.56 800.53 448.56 833.26 293.6 833.26 293.6 918.55 292.02 918.55 292.02 948.21 344.34 948.21 344.34 946.64 466.96 946.64 466.96 800.53"
          />
          <rect id="B1F6H13" class="cls-2" x="470.1" y="800.53" width="63.98" height="146.13" />
          <rect id="B1F6H14" class="cls-2" x="537.79" y="800.63" width="64.07" height="146.03" />
          <polygon
            id="B1F6H15"
            class="cls-2"
            points="604.92 800.32 685.87 800.32 685.87 946.33 633.77 946.33 633.77 947.99 604.36 947.99 604.11 903.39 604.72 903.39 604.92 800.32"
          />
          <polygon
            id="B1F6H16"
            class="cls-2"
            points="689.58 800.33 770.42 800.33 770.42 946.17 718.11 946.17 718.11 947.78 688.37 947.78 688.37 903.23 689.58 903.23 689.58 800.33"
          />
          <polygon
            id="B1F6H21"
            class="cls-2"
            points="638.97 773.28 638.97 757.73 588.69 757.73 588.69 770.1 527.32 770.1 527.32 728.42 607.74 728.42 607.74 680.57 657.01 680.57 656.82 678.83 699.79 678.83 699.98 773.28 638.97 773.28"
          />
          <polygon
            id="B1F6H22"
            class="cls-2"
            points="474.19 716.96 474.19 634.55 583.27 634.55 583.27 633.12 613.14 633.12 613.14 680.57 604.05 680.57 604.05 724.81 527.18 724.81 527.18 716.78 474.19 716.96"
          />
          <polygon
            id="B1F6H23"
            class="cls-2"
            points="474.22 587.25 474.22 571.04 490.03 571.04 490.03 526.67 600.65 526.67 600.65 588.45 613.11 588.45 613.11 633.12 583.32 633.12 583.53 631.16 474.43 631.16 474.22 587.25"
          />
          <polygon
            id="B1F6H24"
            class="cls-2"
            points="474.31 523.24 598.43 523.53 598.43 461.61 474.31 461.32 474.31 523.24"
          />
          <polygon
            id="B1F6H25"
            class="cls-2"
            points="474.22 457.91 597.72 457.91 598.09 398.24 481.49 398.24 481.49 421.23 474.25 421.23 474.22 457.91"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-1-level-6-mini-map ref="miniMap" @planSelected="selectPlan($event)">
        </building-1-level-6-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building1Level6MiniMap from './Building1Level6MiniMap.vue';

export default {
  name: 'Building1Level6',
  components: {
    FloorPlanFloorPlate,
    Building1Level6MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
