<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-2/level-6/B2L6-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B2F6H01"
            class="cls-2"
            points="675.48 747.88 547.31 747.88 547.17 814.27 675.43 814.82 675.48 747.88"
          />
          <rect id="B2F6H02" class="cls-2" x="547.32" y="817.57" width="128.16" height="63.11" />
          <polygon
            id="B2F6H03"
            class="cls-2"
            points="675.48 926.71 595.11 926.71 595.11 884.12 547.3 884.12 547.3 918.92 547.31 998.88 547.18 1044.58 547.18 1050.7 591.55 1050.7 686.83 1050.8 686.83 964.92 677.45 964.92 677.45 943.95 675.48 943.95 675.48 926.71"
          />
          <polygon
            id="B2F6H04"
            class="cls-2"
            points="701.94 926.11 849.74 926.11 849.74 1031.46 849.96 1062.51 847.53 1062.51 784.22 1062.51 784.21 1050.65 690.39 1050.47 690.47 943.75 701.97 943.92 701.94 926.11"
          />
          <polygon
            id="B2F6H05"
            class="cls-2"
            points="701.81 854.14 849.97 854.14 850.02 865.29 875.28 865.29 875.28 915.79 849.97 915.79 849.97 922.49 717.39 922.49 717.39 889.04 701.81 889.04 701.81 854.14"
          />
          <polygon
            id="B2F6H06"
            class="cls-2"
            points="701.81 753.68 821.11 753.68 821.11 752.61 851.41 752.61 851.5 795.75 849.99 795.73 849.99 850.79 701.81 850.79 701.81 753.68"
          />
          <polygon
            id="B2F6H07"
            class="cls-2"
            points="702.06 695.47 709.4 695.47 709.4 671.73 806.67 671.73 806.67 669.68 851.99 669.68 851.99 697.01 849.95 697.01 849.95 750.21 702.06 750.21 702.06 695.47"
          />
          <polygon
            id="B2F6H08"
            class="cls-2"
            points="701.76 650.25 709.24 650.25 709.24 665.11 849.97 665.11 849.97 614.24 851.63 614.24 851.78 583.96 806.02 583.96 806.05 584.81 701.76 584.81 701.76 650.25"
          />
          <polygon
            id="B2F6H09"
            class="cls-2"
            points="701.69 581.53 849.9 581.53 849.9 528.89 850.17 528.84 849.91 498.65 806.34 498.5 805.97 499.79 701.71 499.79 701.69 581.53"
          />
          <polygon
            id="B2F6H10"
            class="cls-2"
            points="701.81 496.07 849.91 496.07 849.87 413.17 806.83 412.75 806.77 413.55 701.81 413.55 701.81 496.07"
          />
          <polygon
            id="B2F6H11"
            class="cls-2"
            points="701.97 410.71 849.92 410.64 849.9 327.76 806.65 327.41 806.65 328.45 701.97 328.87 701.97 410.71"
          />
          <polygon
            id="B2F6H12"
            class="cls-2"
            points="701.89 325.39 849.88 325.17 849.88 272.43 851.77 272.4 851.51 241.41 806.59 241.41 806.31 243.32 704.87 243.32 705.15 287.72 701.89 287.72 701.89 325.39"
          />
          <polygon
            id="B2F6H13"
            class="cls-2"
            points="701.74 272.49 701.38 239.56 806.38 239.56 806.38 241.38 851.51 241.41 851.5 165.65 806.38 165.65 806.57 167.2 668.38 167.21 668.15 151.38 616.6 151.38 616.6 272.49 701.74 272.49"
          />
          <polygon
            id="B2F6H20"
            class="cls-2"
            points="348.27 299.94 348.27 408.98 344.16 409.41 344.15 410.21 343.61 410.22 343.61 448.02 391.27 448.02 391.27 428.1 391.27 427.34 446.71 427.44 446.71 409.42 488.36 409.42 488.95 320.91 444.74 320.91 444.74 299.74 408.44 299.56 348.27 299.94"
          />
          <polygon
            id="B2F6H21"
            class="cls-2"
            points="491.97 299.65 491.97 393.93 490.92 393.93 490.92 409.35 489.33 409.35 489.33 425.17 526.31 425.17 526.31 442.61 564.14 442.61 564.14 440.7 671.61 440.7 671.61 393.1 659.66 393.1 659.66 357.03 622.01 357.03 622.01 315.88 609.37 315.88 609.37 299.82 491.97 299.65"
          />
          <polygon
            id="B2F6H22"
            class="cls-2"
            points="674.97 445.31 564.25 445.31 564.25 442.98 526.42 442.98 526.42 488.69 546.96 488.69 546.96 540.8 674.97 540.8 674.97 445.31"
          />
          <polygon
            id="B2F6H23"
            class="cls-2"
            points="675.25 544.2 567.17 544.2 564.54 544.2 564.54 543.21 537.76 543.21 537.76 541.41 526.43 541.41 526.43 596.81 547.25 596.81 547.25 665.17 668.04 665.17 668.04 646.35 672.01 646.35 675.25 646.35 675.25 544.2"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-2-level-6-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-2-level-6-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building2Level6MiniMap from './Building2Level6MiniMap.vue';

export default {
  name: 'Building2Level6',
  components: {
    FloorPlanFloorPlate,
    Building2Level6MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
