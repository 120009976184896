<template>
  <div class="relative floor-plan-floor-plate flex flex-row-reverse text-white">
    <div class="floor-plan-floor-plate_minimap">
      <slot name="mini-map"></slot>
    </div>
    <!-- Map -->
    <div id="map">
      <slot name="map"></slot>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
export default {
  name: 'FloorPlanFloorPlate',
  props: ['plans', 'floorplateSvgId', 'buildingKey', 'levelKey'],
  emits: ['miniMapHighlightPlans'],
  mounted() {
    this.resetPlanHighlighted();
  },
  beforeUnmount() {
    this.isPlanSelected = false;
  },
  methods: {
    getAllHomeElements: function() {
      return document.querySelectorAll(`#${this.floorplateSvgId} > *`);
    },
    resetPlanHighlighted: function() {
      const allHomes = this.getAllHomeElements();
      gsap.set(allHomes, { opacity: 0 });
    },
    selectPlan(event) {
      // prevent tapping multiple times causing multiple animations
      if (this.isPlanSelected || event.target.nodeName == 'svg') {
        return;
      }
      this.isPlanSelected = true;

      const planName = event.target.id;

      this.resetPlanHighlighted();

      if (!planName || _.startsWith(planName, 'floorplate_')) {
        return;
      }

      const currentPlan = _.first(_.filter(this.plans, (value, key) => key == planName));
      let allHomesWithHomeText = _.map(this.plans, (value, key) => {
        value.key = key;
        return value;
      });

      allHomesWithHomeText = _.filter(allHomesWithHomeText, (value, key) => {
        return value.homeText == currentPlan.homeText;
      });

      const homes = _.map(allHomesWithHomeText, value => {
        return document.getElementById(this.floorplateSvgId).getElementById(value.key);
      });

      // Disable others clickable while one is animated
      gsap.to(homes, {
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
          this.$router.push(`${_.kebabCase(this.levelKey)}/${planName}`);
        }
      });

      const planKeys = _.map(allHomesWithHomeText, value => value.key);
      this.$emit('miniMapHighlightPlans', planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
.floor-plan-floor-plate {
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &::selection {
    -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none;
    background: none;
  }

  &_minimap {
    @apply absolute;
    bottom: 3.9vw;
    left: 7.18vw;
    width: 11.15vw; //214px
  }

  #map {
    position: relative;
    width: 64.0625vw;
    height: 56.25vw;
  }
}
</style>
