<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-1/level-5/B1L5-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B1F5H01"
            class="cls-2"
            points="474.6 256.89 474.6 201.69 601.03 201.69 601.03 258.81 611.72 258.81 611.95 259.73 611.95 311.33 474.6 311.33 474.6 256.89"
          />
          <polygon
            id="B1F5H02"
            class="cls-2"
            points="474.6 138.04 474.77 118.58 463.28 118.58 463.28 33.86 519.83 33.86 600.81 33.86 600.81 84.12 600.94 164.1 600.94 198.08 552.11 198.27 551.93 155.94 474.6 155.94 474.6 138.04"
          />
          <polygon
            id="B1F5H03"
            class="cls-2"
            points="448.29 156.86 302.18 156.86 302.18 22.18 367 22.18 367 33.86 459.74 33.86 459.74 136.09 448.29 136.09 448.29 156.86"
          />
          <polygon
            id="B1F5H04"
            class="cls-2"
            points="277.21 167.26 302.18 167.26 302.18 159.74 448.29 159.74 448.29 180.15 435.68 180.15 435.68 223.58 302.18 223.58 302.18 216.87 277.18 216.79 277.21 167.26"
          />
          <polygon
            id="B1F5H05"
            class="cls-2"
            points="448.29 249.58 448.29 227.04 302.18 227.04 302.18 279.26 300.32 279.26 300.32 309.67 448.29 309.18 448.29 249.58"
          />
          <polygon
            id="B1F5H06"
            class="cls-2"
            points="448.36 333.52 448.36 311.9 302.18 311.9 302.18 363.22 302.18 389.23 347.68 389.23 347.68 391.92 444.3 391.92 444.3 368.18 448.36 368.18 448.36 333.52"
          />
          <polygon
            id="B1F5H07"
            class="cls-2"
            points="448.29 437.89 448.29 408.93 444.26 408.93 444.26 398.04 302.18 398.04 302.18 489.89 330.02 489.89 332.64 489.89 332.64 492.79 448.31 492.79 448.29 437.89"
          />
          <polygon
            id="B1F5H08"
            class="cls-2"
            points="448.31 496.35 302.18 496.35 302.18 548.44 300.67 548.44 300.67 578.05 448.31 578.05 448.31 496.35"
          />
          <polygon
            id="B1F5H09"
            class="cls-2"
            points="448.31 580.63 302.18 580.63 302.18 633.05 274.05 632.72 274.05 662.38 302.14 662.43 448.31 662.43 448.31 580.63"
          />
          <polygon
            id="B1F5H10"
            class="cls-2"
            points="448.31 664.54 302.18 664.54 302.18 716.66 274.07 716.99 274.07 746.65 302.14 746.55 448.31 746.55 448.31 664.54"
          />
          <polygon
            id="B1F5H11"
            class="cls-2"
            points="448.31 748.34 302.18 748.34 302.18 801 300.62 801 300.62 830.87 448.71 830.87 448.31 748.34"
          />
          <polygon
            id="B1F5H12"
            class="cls-2"
            points="466.96 800.53 448.56 800.53 448.56 833.26 293.6 833.26 293.6 918.55 292.02 918.55 292.02 948.21 344.34 948.21 344.34 946.64 466.96 946.64 466.96 800.53"
          />
          <rect id="B1F5H13" class="cls-2" x="470.57" y="800.53" width="63.98" height="146.13" />
          <rect id="B1F5H14" class="cls-2" x="538.01" y="800.63" width="64.07" height="146.03" />
          <polygon
            id="B1F5H15"
            class="cls-2"
            points="605.23 800.63 686.18 800.63 686.18 946.64 634.08 946.64 634.08 948.3 604.67 948.3 604.42 903.7 605.03 903.7 605.23 800.63"
          />
          <polygon
            id="B1F5H16"
            class="cls-2"
            points="689.91 800.8 770.75 800.8 770.75 946.64 718.44 946.64 718.44 948.25 688.7 948.25 688.7 903.7 689.91 903.7 689.91 800.8"
          />
          <polygon
            id="B1F5H21"
            class="cls-2"
            points="639.19 773.28 639.19 757.73 588.92 757.73 588.92 770.1 527.54 770.1 527.54 728.42 607.96 728.42 607.96 680.57 657.23 680.57 657.04 678.83 700.01 678.83 700.21 773.28 639.19 773.28"
          />
          <polygon
            id="B1F5H22"
            class="cls-2"
            points="474.55 716.96 474.55 634.55 583.62 634.55 583.62 633.12 613.49 633.12 613.49 680.57 604.4 680.57 604.4 724.81 527.54 724.81 527.54 716.78 474.55 716.96"
          />
          <polygon
            id="B1F5H23"
            class="cls-2"
            points="474.6 587.25 474.6 571.04 490.41 571.04 490.41 526.67 601.03 526.67 601.03 588.45 613.49 588.45 613.49 633.12 583.7 633.12 583.91 631.16 474.81 631.16 474.6 587.25"
          />
          <rect id="B1F5H24" class="cls-2" x="474.59" y="461.61" width="123.83" height="61.92" />
          <polygon
            id="B1F5H25"
            class="cls-2"
            points="474.23 457.91 598.06 457.91 598.43 398.24 481.83 398.24 481.83 421.23 474.6 421.23 474.23 457.91"
          />
        </svg>
        <!-- <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B1F5H01"
            class="cls-2"
            points="474.6 256.89 474.6 201.69 601.03 201.69 601.03 258.81 611.72 258.81 611.95 259.73 611.95 311.33 474.6 311.33 474.6 256.89"
          />
          <polygon
            id="B1F5H02"
            class="cls-2"
            points="474.6 138.04 474.77 118.58 463.28 118.58 463.28 33.86 519.83 33.86 600.81 33.86 600.81 84.12 600.94 164.1 600.94 198.08 552.11 198.27 551.93 155.94 474.6 155.94 474.6 138.04"
          />
          <polygon
            id="B1F5H03"
            class="cls-2"
            points="448.29 156.86 302.18 156.86 302.18 22.18 367 22.18 367 33.86 459.74 33.86 459.74 136.09 448.29 136.09 448.29 156.86"
          />
          <polygon
            id="B1F5H04"
            class="cls-2"
            points="277.21 167.26 302.18 167.26 302.18 159.74 448.29 159.74 448.29 180.15 435.68 180.15 435.68 223.58 302.18 223.58 302.18 216.87 277.18 216.79 277.21 167.26"
          />
          <polygon
            id="B1F5H05"
            class="cls-2"
            points="448.29 249.58 448.29 227.04 302.18 227.04 302.18 279.26 300.32 279.26 300.32 309.67 448.29 309.18 448.29 249.58"
          />
          <polygon
            id="B1F5H06"
            class="cls-2"
            points="448.36 333.52 448.36 311.9 302.18 311.9 302.18 363.22 302.18 389.23 347.68 389.23 347.68 391.92 444.3 391.92 444.3 368.18 448.36 368.18 448.36 333.52"
          />
          <polygon
            id="B1F5H07"
            class="cls-2"
            points="448.29 437.89 448.29 408.93 444.26 408.93 444.26 398.04 302.18 398.04 302.18 489.89 330.02 489.89 332.64 489.89 332.64 492.79 448.31 492.79 448.29 437.89"
          />
          <polygon
            id="B1F5H08"
            class="cls-2"
            points="448.31 496.35 302.18 496.35 302.18 548.44 300.67 548.44 300.67 578.05 448.31 578.05 448.31 496.35"
          />
          <polygon
            id="B1F5H09"
            class="cls-2"
            points="448.31 580.63 302.18 580.63 302.18 633.05 274.05 632.72 274.05 662.38 302.14 662.43 448.31 662.43 448.31 580.63"
          />
          <polygon
            id="B1F5H10"
            class="cls-2"
            points="448.31 664.54 302.18 664.54 302.18 716.66 274.07 716.99 274.07 746.65 302.14 746.55 448.31 746.55 448.31 664.54"
          />
          <polygon
            id="B1F5H11"
            class="cls-2"
            points="448.31 748.34 302.18 748.34 302.18 801 300.62 801 300.62 830.87 448.71 830.87 448.31 748.34"
          />
          <polygon
            id="B1F5H12"
            class="cls-2"
            points="466.96 800.53 448.56 800.53 448.56 833.26 293.6 833.26 293.6 918.55 292.02 918.55 292.02 948.21 344.34 948.21 344.34 946.64 466.96 946.64 466.96 800.53"
          />
          <polygon id="B1F5H13" class="cls-2" x="470.57" y="800.53" width="63.98" height="146.13" />
          <polygon id="B1F5H14" class="cls-2" x="538.01" y="800.63" width="64.07" height="146.03" />
          <polygon
            id="B1F5H15"
            class="cls-2"
            points="605.23 800.63 686.18 800.63 686.18 946.64 634.08 946.64 634.08 948.3 604.67 948.3 604.42 903.7 605.03 903.7 605.23 800.63"
          />
          <polygon
            id="B1F5H16"
            class="cls-2"
            points="689.91 800.8 770.75 800.8 770.75 946.64 718.44 946.64 718.44 948.25 688.7 948.25 688.7 903.7 689.91 903.7 689.91 800.8"
          />
          <polygon
            id="B1F5H21"
            class="cls-2"
            points="639.19 773.28 639.19 757.73 588.92 757.73 588.92 770.1 527.54 770.1 527.54 728.42 607.96 728.42 607.96 680.57 657.23 680.57 657.04 678.83 700.01 678.83 700.21 773.28 639.19 773.28"
          />
          <polygon
            id="B1F5H22"
            class="cls-2"
            points="474.55 716.96 474.55 634.55 583.62 634.55 583.62 633.12 613.49 633.12 613.49 680.57 604.4 680.57 604.4 724.81 527.54 724.81 527.54 716.78 474.55 716.96"
          />
          <polygon
            id="B1F5H23"
            class="cls-2"
            points="474.6 587.25 474.6 571.04 490.41 571.04 490.41 526.67 601.03 526.67 601.03 588.45 613.49 588.45 613.49 633.12 583.7 633.12 583.91 631.16 474.81 631.16 474.6 587.25"
          />
          <polygon id="B1F5H24" class="cls-2" x="474.59" y="461.61" width="123.83" height="61.92" />
          <polygon
            id="B1F5H25"
            class="cls-2"
            points="474.23 457.91 598.06 457.91 598.43 398.24 481.83 398.24 481.83 421.23 474.6 421.23 474.23 457.91"
          />
        </svg> -->
      </template>
      <template v-slot:mini-map>
        <building-1-level-5-mini-map ref="miniMap" @planSelected="selectPlan($event)">
        </building-1-level-5-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building1Level5MiniMap from './Building1Level5MiniMap.vue';

export default {
  name: 'Building1Level5',
  components: {
    FloorPlanFloorPlate,
    Building1Level5MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
