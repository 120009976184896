<template>
  <div class="floor-plan flex">
    <div class="floor-plan_nav">
      <div class="floor-plan_nav_info">
        <router-link :to="previousURL">
          <h2>{{ levelText }}</h2>
        </router-link>
        <h3>{{ homeText }}<br />{{ planText }}</h3>
      </div>
      <!-- Menu -->
    </div>
    <img :src="planImage" />

    <!-- Mini Map -->
    <div id="minimap">
      <component
        :is="getMiniMapComponentName()"
        :current-selected-plan="planKey"
        :plans="plans"
      ></component>
      <router-link router-link :to="previousURL" class="minimap_btn-back">
        <button class="btn btn--sm">
          Back to Floorplate
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Building1Level1MiniMap from './FloorPlanFloorPlates/Building1Level1MiniMap.vue';
import Building1Level2MiniMap from './FloorPlanFloorPlates/Building1Level2MiniMap.vue';
import Building1Level3MiniMap from './FloorPlanFloorPlates/Building1Level3MiniMap.vue';
import Building1Level4MiniMap from './FloorPlanFloorPlates/Building1Level4MiniMap.vue';
import Building1Level5MiniMap from './FloorPlanFloorPlates/Building1Level5MiniMap.vue';
import Building1Level6MiniMap from './FloorPlanFloorPlates/Building1Level6MiniMap.vue';
import Building2Level1MiniMap from './FloorPlanFloorPlates/Building2Level1MiniMap.vue';
import Building2Level2MiniMap from './FloorPlanFloorPlates/Building2Level2MiniMap.vue';
import Building2Level3MiniMap from './FloorPlanFloorPlates/Building2Level3MiniMap.vue';
import Building2Level4MiniMap from './FloorPlanFloorPlates/Building2Level4MiniMap.vue';
import Building2Level5MiniMap from './FloorPlanFloorPlates/Building2Level5MiniMap.vue';
import Building2Level6MiniMap from './FloorPlanFloorPlates/Building2Level6MiniMap.vue';

export default {
  name: 'FloorPlan',
  components: {
    Building1Level1MiniMap,
    Building1Level2MiniMap,
    Building1Level3MiniMap,
    Building1Level4MiniMap,
    Building1Level5MiniMap,
    Building1Level6MiniMap,
    Building2Level1MiniMap,
    Building2Level2MiniMap,
    Building2Level3MiniMap,
    Building2Level4MiniMap,
    Building2Level5MiniMap,
    Building2Level6MiniMap
  },
  props: [
    'planText',
    'homeText',
    'planImage',
    'levelText',
    'buildingKey',
    'levelKey',
    'planKey',
    'plans'
  ],
  data() {
    return { previousURL: '/floor-plans/' + this.buildingKey + '/' + this.levelKey };
  },
  methods: {
    getMiniMapComponentName: function() {
      const building = _.capitalize(_.camelCase(this.buildingKey));
      const level = _.capitalize(_.camelCase(this.levelKey));

      return `${building}${level}MiniMap`;
    }
  }
};
</script>

<style scoped lang="scss">
.floor-plan {
  &_nav {
    @apply flex flex-col;
    width: 24.32vw; // 467px out of 1920px
    padding-left: 5.07vw;

    &_info {
      @apply text-primary relative z-30;
      margin-top: calc(296/1920) * 100vw;
      margin-left: calc(40.65/1920) * 100vw;
      h2 {
        @apply leading-none font-bold;
        font-size: 1.3vw; //text-xl
        margin-bottom: 0.83vw; //mb-4
      }
      h3 {
        @apply leading-normal font-medium;
        font-size: 1.04vw; //text-lg
        margin-bottom: 0.83vw; //mb-4
      }
    }
  }
  img {
    width: 64.0625vw; // 1230px out of 1920px
  }
}

#minimap {
  @apply absolute;
  bottom: 3.9vw;
  left: 7.18vw;
  width: 11.15vw; //214px
  .minimap_btn-back {
    @apply absolute left-0 bottom-0 z-10;
  }
}
</style>
