<template>
  <div>
    <floor-plan-floor-plate
      ref="floorPlanFloorPlate"
      :plans="plans"
      @miniMapHighlightPlans="miniMapHighlightPlans"
      :floorplateSvgId="floorplateSvgId"
      :buildingKey="buildingKey"
      :levelKey="levelKey"
    >
      <template v-slot:map>
        <img
          class="relative w-full h-full"
          src="@/assets/images/floor-plate/building-2/level-4/B2L4-floorplate.svg"
        />
        <svg
          :id="`${floorplateSvgId}`"
          class="absolute left-0 top-0 w-full h-full"
          @click="selectPlan($event)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1230 1080"
        >
          <polygon
            id="B2F4H01"
            class="cls-2"
            points="675.48 747.88 547.31 747.88 547.17 814.27 675.43 814.82 675.48 747.88"
          />
          <rect id="B2F4H02" class="cls-2" x="547.32" y="817.57" width="128.16" height="63.11" />
          <polygon
            id="B2F4H03"
            class="cls-2"
            points="675.48 926.71 595.11 926.71 595.11 884.12 547.3 884.12 547.3 918.92 547.31 998.88 547.18 1044.58 547.18 1050.7 591.55 1050.7 686.83 1050.8 686.83 964.92 677.45 964.92 677.45 943.95 675.48 943.95 675.48 926.71"
          />
          <polygon
            id="B2F4H04"
            class="cls-2"
            points="702.45 926.11 850.25 926.11 850.25 1031.46 850.46 1062.51 848.04 1062.51 784.73 1062.51 784.72 1050.65 690.9 1050.47 690.97 943.75 702.48 943.92 702.45 926.11"
          />
          <polygon
            id="B2F4H05"
            class="cls-2"
            points="702.19 854.3 850.36 854.3 850.4 865.45 875.66 865.45 875.66 915.94 850.36 915.94 850.36 922.65 717.78 922.65 717.78 889.2 702.19 889.2 702.19 854.3"
          />
          <polygon
            id="B2F4H06"
            class="cls-2"
            points="702 753.68 821.3 753.68 821.3 752.61 851.61 752.61 851.69 795.75 850.18 795.73 850.18 850.79 702 850.79 702 753.68"
          />
          <polygon
            id="B2F4H07"
            class="cls-2"
            points="702.06 695.47 709.4 695.47 709.4 671.73 806.67 671.73 806.67 669.68 851.99 669.68 851.99 697.01 849.95 697.01 849.95 750.21 702.06 750.21 702.06 695.47"
          />
          <polygon
            id="B2F4H08"
            class="cls-2"
            points="702.15 650.25 709.63 650.25 709.63 665.11 850.35 665.11 850.35 614.24 852.02 614.24 852.17 583.96 806.41 583.96 806.43 584.81 702.15 584.81 702.15 650.25"
          />
          <polygon
            id="B2F4H09"
            class="cls-2"
            points="702.26 581.53 850.47 581.53 850.47 528.89 852.06 528.84 852.42 498.56 806.91 498.5 806.54 499.79 702.28 499.79 702.26 581.53"
          />
          <polygon
            id="B2F4H10"
            class="cls-2"
            points="702.27 496.07 850.35 496.07 850.35 442.45 850.31 412.74 806.83 412.75 806.77 413.55 702.27 413.55 702.27 496.07"
          />
          <polygon
            id="B2F4H11"
            class="cls-2"
            points="702.39 410.29 850.33 410.29 850.12 357.41 850.31 327.41 806.65 327.41 806.65 328.45 702.39 328.45 702.39 410.29"
          />
          <polygon
            id="B2F4H12"
            class="cls-2"
            points="702.17 324.83 850.16 324.62 850.16 271.88 852.04 271.85 852.22 241.85 806.86 241.85 806.86 243.04 705.42 243.04 705.42 287.52 702.17 287.52 702.17 324.83"
          />
          <polygon
            id="B2F4H13"
            class="cls-2"
            points="701.74 272.49 701.74 239.64 858.99 239.22 858.99 152.89 860.69 153.28 860.69 122.79 807.4 122.79 807.4 124.38 683.26 124.38 683.26 272.49 701.74 272.49"
          />
          <polygon
            id="B2F4H14"
            class="cls-2"
            points="679.94 272.25 679.94 154.89 680.01 152.28 680.73 152.28 680.73 126.93 670.58 126.93 670.58 110.09 636.19 110.09 636.19 152.28 629.6 152.28 629.6 124.32 562.13 124.32 562.13 272.25 679.94 272.25"
          />
          <polygon
            id="B2F4H15"
            class="cls-2"
            points="558.24 272.24 558.65 167.78 559.69 167.78 559.69 122.63 529.66 122.63 529.66 124.31 476.42 124.32 476.42 272.21 558.24 272.24"
          />
          <polygon
            id="B2F4H16"
            class="cls-2"
            points="472.44 272.41 472.85 167.96 473.89 167.96 473.89 124.34 473.03 124.34 473.03 122.81 444.57 122.81 444.57 124.49 390.88 124.49 390.88 272.09 472.44 272.41"
          />
          <polygon
            id="B2F4H17"
            class="cls-2"
            points="387.14 272.1 387.39 168.1 388.44 168.1 388.44 124.62 387.39 124.3 387.39 122.42 358.94 122.42 358.94 124.26 305.41 124.58 305.41 272.1 387.14 272.1"
          />
          <polygon
            id="B2F4H18"
            class="cls-2"
            points="301.68 272.05 301.68 124.12 156.07 124.12 156.07 190.26 194.32 190.26 194.32 196.53 168.27 196.53 168.27 283.85 280.25 283.85 280.25 272.26 301.68 272.05"
          />
          <polygon
            id="B2F4H19"
            class="cls-2"
            points="283.88 287.5 168.04 287.5 167.97 401.79 166.42 401.76 166.48 428.68 227.67 428.68 227.64 427.08 344.06 427.08 344.06 378.76 301.95 378.76 301.95 299.28 283.75 299.28 283.88 287.5"
          />
          <polygon
            id="B2F4H20"
            class="cls-2"
            points="348.27 299.94 348.27 408.98 347.54 409.05 347.54 427.83 345.95 427.83 345.95 447.61 391.96 447.61 392.16 436.35 390.38 436.35 390.38 427.05 447.35 426.95 447.35 409.05 488.95 408.62 488.95 320.91 444.74 320.91 444.74 299.74 408.44 299.56 348.27 299.94"
          />
          <polygon
            id="B2F4H21"
            class="cls-2"
            points="492.32 299.38 492.32 393.66 491.28 393.66 491.28 409.07 489.69 409.07 489.69 424.89 526.67 424.89 526.67 442.34 564.5 442.34 564.5 440.43 671.97 440.43 671.97 392.83 660.02 392.83 660.02 356.75 622.37 356.75 622.37 315.6 609.73 315.6 609.73 299.54 492.32 299.38"
          />
          <polygon
            id="B2F4H22"
            class="cls-2"
            points="675.61 444.67 564.89 444.67 564.89 442.34 527.06 442.34 527.06 488.05 547.6 488.05 547.6 540.16 675.61 540.16 675.61 444.67"
          />
          <polygon
            id="B2F4H23"
            class="cls-2"
            points="675.49 544.2 567.41 544.2 564.78 544.2 564.78 543.21 538 543.21 538 541.41 526.95 541.41 526.95 596.46 538.07 596.46 538.07 595.23 547.49 595.23 547.49 665.17 668.28 665.17 668.28 646.35 672.24 646.35 675.49 646.35 675.49 544.2"
          />
        </svg>
      </template>
      <template v-slot:mini-map>
        <building-2-level-4-mini-map
          ref="miniMap"
          @planSelected="selectPlan($event)"
        ></building-2-level-4-mini-map>
      </template>
    </floor-plan-floor-plate>
  </div>
</template>

<script>
import FloorPlanFloorPlate from '@/components/FloorPlanFloorPlate.vue';
import Building2Level4MiniMap from './Building2Level4MiniMap.vue';

export default {
  name: 'Building2Level4',
  components: {
    FloorPlanFloorPlate,
    Building2Level4MiniMap
  },
  data() {
    return {
      isPlanSelected: false
    };
  },
  props: ['plans', 'buildingKey', 'levelKey'],
  beforeMount() {
    this.floorplateSvgId = `floorplate_${_.camelCase(this.buildingKey)}_${_.camelCase(
      this.levelKey
    )}`;
  },
  mounted() {
    this.isPlanSelected = false;
  },
  methods: {
    selectPlan: function($event) {
      this.$refs.floorPlanFloorPlate.selectPlan($event);
    },
    miniMapHighlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>

<style scoped lang="scss">
polygon,
rect,
path {
  @apply cursor-pointer;
  fill: #f9e346;
  mix-blend-mode: multiply;
  opacity: 0;
}
</style>
