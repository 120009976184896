<template>
  <div class="relative">
    <mini-map
      ref="miniMap"
      :current-selected-plan="currentSelectedPlan"
      @planSelected="planSelected"
      :plans="plans"
    >
      <template v-slot:keyplate>
        <img id="keyplate" src="@/assets/images/floor-plate/building-2/level-5/B2L5-keyplate.svg" />
      </template>
      <template v-slot:highlights>
        <svg id="highlights" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285.32 388.02">
          <g class="cls-1">
            <g id="Keyplate_Highlights" data-name="Keyplate Highlight">
              <rect id="B2F5H01" class="cls-2" x="149.56" y="257.53" width="51.06" height="26.34" />
              <rect id="B2F5H02" class="cls-2" x="149.56" y="283.86" width="51.06" height="26.34" />
              <polygon
                id="B2F5H03"
                class="cls-2"
                points="149.76 310.17 160.98 310.17 160.98 310.87 169.93 310.87 169.93 328.05 200.84 328.05 200.84 336.19 201.79 336.19 201.79 343.37 206.74 343.37 206.74 378.63 149.56 378.71 149.56 358.05 149.76 358.26 149.76 310.17"
              />
              <polygon
                id="B2F5H04"
                class="cls-2"
                points="212.84 327.95 271.87 327.95 271.87 372.31 271.8 385.38 245.39 385.1 245.39 378.71 224.13 378.85 206.77 379.01 206.77 336.57 212.84 336.57 212.84 327.95"
              />
              <polygon
                id="B2F5H05"
                class="cls-2"
                points="212.9 298.45 271.94 298.45 271.94 303.73 282.08 303.73 282.08 323.99 271.94 323.99 271.94 327.49 218.17 327.49 218.17 312.81 212.9 312.81 212.9 298.45"
              />
              <polygon
                id="B2F5H06"
                class="cls-2"
                points="232.62 258.27 260.23 257.88 271.8 257.7 271.88 275.98 271.88 298.8 212.83 298.8 212.83 269.6 213.21 257.7 232.62 258.27"
              />
              <polygon
                id="B2F5H07"
                class="cls-2"
                points="214.58 224.39 254.23 224.82 271.87 224.82 271.67 235.92 271.67 258.32 212.85 257.89 212.81 235.77 214.45 235.76 214.58 224.39"
              />
              <polygon
                id="B2F5H08"
                class="cls-2"
                points="212.84 189.78 260.7 189.78 271.88 190.09 271.88 202.08 271.88 224.56 254.69 224.56 254.27 224.13 214.59 224.13 214.59 216.16 212.84 216.16 212.84 189.78"
              />
              <polygon
                id="B2F5H09"
                class="cls-2"
                points="212.83 155.87 260.66 155.87 271.88 156.19 271.87 168.31 271.87 190.65 260.7 190.65 212.83 190.65 212.83 155.87"
              />
              <polygon
                id="B2F5H10"
                class="cls-2"
                points="212.81 120.81 254.34 121.09 283.41 121.41 283.41 133.55 271.9 133.55 271.9 155.91 254.34 155.59 212.81 155.59 212.81 120.81"
              />
              <polygon
                id="B2F5H11"
                class="cls-2"
                points="212.81 86.19 254.34 86.91 271.29 86.51 283.41 86.91 283.41 99.05 271.9 98.64 271.9 120.81 212.81 120.81 212.81 86.19"
              />
              <polygon
                id="B2F5H12"
                class="cls-2"
                points="212.81 52.09 271.9 52.09 271.9 86.51 212.94 86.91 212.81 52.09"
              />
              <polygon
                id="B2F5H13"
                class="cls-2"
                points="176.71 63.7 176.71 15.68 198.59 15.68 198.59 22.07 254.33 22.07 254.42 16.93 271.9 16.48 271.9 52.05 212.25 52.05 212.25 63.7 176.71 63.7"
              />
              <polygon
                id="B2F5H20"
                class="cls-2"
                points="67.75 75.93 108.42 75.93 108.42 83.21 126.28 83.21 126.6 119.42 108.73 119.42 108.73 126.76 85.76 126.76 85.69 134.39 68.53 134.41 68.02 119.2 67.75 75.93"
              />
              <polygon
                id="B2F5H21"
                class="cls-2"
                points="195.69 98.71 180.6 98.71 180.6 81.96 175.42 81.96 175.42 76.7 126.77 76.7 126.77 125.8 142.08 125.8 142.08 133.65 200.63 133.65 200.63 113.38 195.69 113.38 195.69 98.71"
              />
              <polygon
                id="B2F5H22"
                class="cls-2"
                points="141.9 150.42 149.57 150.42 149.57 173.34 200.46 173.34 200.46 132.82 141.91 132.82 141.9 150.42"
              />
              <polygon
                id="B2F5H23"
                class="cls-2"
                points="198.07 223.8 149.57 223.8 149.57 194.77 141.9 194.77 141.9 173.62 156.43 173.62 200.46 173.62 200.46 216.14 198.09 216.14 198.07 223.8"
              />
            </g>
          </g>
        </svg>
      </template>
    </mini-map>
  </div>
</template>

<script>
import MiniMap from '../MiniMap.vue';

export default {
  name: 'Building2Level5MiniMap',
  props: ['currentSelectedPlan', 'plans'],
  emits: ['planSelected'],
  components: {
    MiniMap
  },
  methods: {
    planSelected: function(event) {
      this.$emit('planSelected', event);
    },
    highlightPlans: function(planKeys) {
      this.$refs.miniMap.highlightPlans(planKeys);
    }
  }
};
</script>
