<template>
  <router-link class="floor-plan-menu_logo inline-block" to="/hero">
    <img src="./../assets/images/logo-viktor-yellow.svg" alt="Viktor logo" />
  </router-link>
  <div class="floor-plan-menu text-white">
    <ul
      v-for="(building, buildingKey) in floorPlanData"
      :key="buildingKey"
      v-show="
        !$route.fullPath.includes('/B') || $route.fullPath.startsWith(`/floor-plans/${buildingKey}`)
      "
    >
      <li>
        <router-link
          :to="`/floor-plans/${buildingKey}`"
          :class="{ 'router-link-active': subIsActive(`/floor-plans/${buildingKey}`) }"
        >
          <h2>{{ building.name }}</h2>
        </router-link>
      </li>
      <ul
        v-if="
          $route.fullPath.startsWith(`/floor-plans/${buildingKey}`) &&
            !$route.fullPath.includes('/B')
        "
      >
        <li v-for="(level, levelKey) in building.levels" :key="levelKey">
          <router-link :to="`/floor-plans/${buildingKey}/${levelKey}`">
            <h3>{{ level.name }}</h3>
          </router-link>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script>
import { FloorPlanData } from '../router/FloorPlanData';

export default {
  name: 'FloorPlanMenu',
  data() {
    return {
      floorPlanData: FloorPlanData
    };
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    }
  }
};
</script>

<style scoped lang="scss">
img {
  width: 14.16vw;
}
.floor-plan-menu {
  margin: 2.34vw 0 0 2.11vw;
  ul {
    @apply leading-none;
    font-size: 1.3vw;
  }
  > ul:first-of-type {
    margin-bottom: 0.52vw; //mb-2.5
  }
  h2 {
    @apply leading-none font-bold inline-block whitespace-nowrap;
    font-size: 1.3vw; //text-xl
    margin-bottom: 0.52vw; //mb-2.5
  }
  ul {
    @apply leading-none;
    font-size: 1.04vw;
  }
  h3 {
    @apply leading-none font-medium inline-block whitespace-nowrap;
    font-size: 1.04vw; //text-lg
    margin-bottom: 0.83vw; //mb-4
  }
  .router-link-active {
    h2 {
      @apply text-primary;
    }
    h3 {
      @apply text-primary font-bold;
    }
  }
}
</style>
